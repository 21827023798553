import React  from 'react';
import Navigation from "./Navigation";

const UserMenu = (({ $self, webType, currentUser }) =>{
  webType = webType || 'web';
  let user = $self.getMenu(`${webType}.user`);
  const UserNavigation = () => (<Navigation vertical={false} className={'user-menu'} menu={user} currentUser={currentUser} />);

  return (<><UserNavigation /></>)
})

export default UserMenu;
