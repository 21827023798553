class storage {
	public static async getItem(name: string) {
		try{
			return await window.localStorage.getItem(name)
		}catch(e){
			return null;
		}
	}

	public static async setItem(name: string, value: string) {
		try{
			return await window.localStorage.setItem(name, value)
		}catch(e){}
	}

	public static async removeItem(name: string) {
		try{
			await window.localStorage.removeItem(name)
		}catch(e){}
	}
}

export default storage;