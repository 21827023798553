import React from 'react';
import menu from './helpers/menu';
import forms from './helpers/forms';
import resources from './locales';
import resourcesGlobal from './locales/global';
import ClientModule from "@gqlapp/module-client-react";
import FooterContainer from './components/FooterYoutube';
import { defaultRoute, LinkRoute, map} from "./helpers/config";
import Route from "@xem/router/classes/Route";

import Detail from './containers/Detail';
import List from './containers/List';
import Locked from "./containers/LockedContainer";
import Link from "./containers/LinkContainer";
import Youtube from "./containers/YoutubeContainer";
import withOnCreate from "./helpers/forms/create";
import resolvers, { defaults } from '@xem/link-common/resolvers';

/**
 *
 * @param props
 * @param component
 * @param options
 * @param pathProps
 */
function getProps(props: any, component: any, options: any, pathProps: any = null){
  let getInitialProps = component?.getInitialProps;
  let newProps = {...props, ...options, component, getInitialProps}
  if(pathProps){
    Object.assign(newProps, { path: map(pathProps) })
  }
  return newProps
}

/**
 *
 */
export default new ClientModule(forms, menu, {
  resolver: [{ resolvers: resolvers(null), defaults}],
  route:{
    LinkDetail      : <Route {...getProps(LinkRoute, Detail, {}, { path: 'detail', id: ':id' })} />,
    LinkList        : <Route {...getProps(LinkRoute, List, { webType: 'list' }, { path: 'list' })} />,
    LinkPage        : <Route {...getProps(LinkRoute, List, { webType: 'list' }, { path: 'listpage', page : ':page' })} />,
    TaxonomyLinks   : <Route {...getProps(LinkRoute, List, { webType: 'list' }, { path: 'taxonomyView', id: ':id' })} runtimejs/>,

    TypeIsLocked    : <Route {...getProps(defaultRoute, Locked, { classNameOnPage: 'locked theme-mobile', webType: 'locked', style: { '--button-bar-height': 0 }})} runtimejs />,
    NodeLink        : <Route {...getProps(defaultRoute, Link,   { classNameOnPage: 'link theme-mobile', webType: 'linkview', style: { '--button-bar-height': 0 }})} />,
    Youtube         : <Route {...getProps(defaultRoute, Youtube,{ classNameOnPage: 'youtube', webType: 'youtube' })} />
  },
  popupComponents:[{ positions: ['cpanel', 'list'], component: withOnCreate }],
  blocks: [{ position: 'youtube.bottom', component: FooterContainer }],
  localization: [
    { ns: 'link', resources, more: ['common'] },
    { ns: 'common', resources: resourcesGlobal },
  ]
});
