import Base from "@xem/entity/classes/Base";
import isArray from "@gqlapp/base/utils/isArray";


export class CustomPage extends Base {
  public data: any;
  public blocks: any[];
  public settings: any;
  status: string = 'active';

  public body: string
  /**
   * instanse
   */
  public static instanse(data?:any) {
    return new this().save(data || {});
  }

  /**
   * save
   */
  public save(_value: any): this {
    super.save(_value);
    let self = this;

    let { data, body, status, blocks }: any= _value || {}
    let { settings }: any= data || {};

    if(status === 0){
      this.status = 'inactive';
    }
    else if(status === -1){
      this.status = 'blocked';
    }

    self.blocks = blocks;
    self.settings = settings;

    self.body = body;
    return self;
  }
}

export default CustomPage;