import { onError } from "@apollo/client/link/error";
import { from } from "@apollo/client/link/core/from";
import { setContext } from "@apollo/client/link/context";
import { InMemoryCache } from 'apollo-cache-inmemory';
import mergeObject from '@gqlapp/base/utils/merge/mergeObject';
import filterByKeys from '@gqlapp/base/utils/filterByKeys';
import { ApolloClient } from "apollo-client";
import { BatchHttpLink } from 'apollo-link-batch-http/lib';
import https from "https";
import fetch from 'isomorphic-unfetch';
import { getItem } from "./clientStorage";


interface CreateApolloClientOptions {
  ctx?: any;
  headers?: any;
  apiUrl?: string;
  remoteip?: string;
  initialState?: any,
  createLink?: Array<(getApolloClient: () => ApolloClient<any>) => any>;
  createNetLink?: (apiLink: any, getApolloClient: () => ApolloClient<any>) => any;
  connectionParams?: any[];
  clientResolvers?: { defaults: { [key: string]: any }; resolvers: any };
}

export function createApolloClient(
  {
    apiUrl,
    headers,
    remoteip,
    initialState,
    createNetLink,
    createLink,
    clientResolvers
  }: CreateApolloClientOptions
) {
  // error({ apiUrl });
  const cache = new InMemoryCache({
    resultCaching: true
  });

  const getApolloClient = (): ApolloClient<any> => client;

  let fetchOptions = null;
  //@ts-ignore
  if (typeof https !== "undefined" && isHttps(apiUrl)) {
    //@ts-ignore
    fetchOptions = { agent: new https.Agent({ rejectUnauthorized: false }) };
  }

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (networkError) console.error(`[Network error]: ${networkError}, Path: ${apiUrl}`)
  })


  // @ts-ignore
  const headerQuery = setContext(async (request, { headers: hds }) => {
    try {
      let data = await getItem('data');

      // console.error('############',{ headers })
      headers = mergeObject(hds, headers);
      headers = filterByKeys(
        { ...headers, data, 'x-forwarded-for': remoteip},
        ['platform', 'data', 'x-real-ip', 'cookie', 'user-agent', 'referer', 'x-forwarded-host', 'x-forwarded-ip', 'x-forwarded-for'],
        { isHttps: isHttps(apiUrl), uri: apiUrl }
      );

      return { headers };
    } catch (e) {}
    return {}
  });


  const apiLink =
    /*** Group graphql content */
    new BatchHttpLink({
      batchMax: 4,
      uri: apiUrl,
      credentials: 'include',
      fetch,
      fetchOptions
    });


  /** api Link */
  const overrideApi = createNetLink
    ? createNetLink(apiLink, getApolloClient): apiLink;


  const allLinks = [
    ...(createLink ? createLink.map((create: any) => create(apiUrl, getApolloClient)) : []),
    errorLink,
    headerQuery,
    /** api Link */
    overrideApi
  ];

  const clientParams: any = {
    ssrMode: true,
    ssrForceFetchDelay: 100,
    link: from(allLinks),
    cache,
    resolvers: (clientResolvers || ({} as any)).resolvers
  };

  const client = new ApolloClient(clientParams);

  if (initialState) {
    cache.restore(initialState);
  }

  return client;
}

export default createApolloClient;
