import React from 'react';

// @ts-ignore
export const mapMenu = ({ id, name, className, parent, ...rest }) => (props, callback)=>{
  return  callback({
    ...rest,
    id: id || `${name}`,
    name: (typeof name !== 'undefined') ? <div style={{ minWidth: '60px', lineHeight: '10px', fontSize: '10px', padding: '4px 5px 0' }}>{`${name}`.t()}</div>: null,
    className: className || ['p-0 border-0'],
    parent : parent || [{targetId: 0}]
  });
}


export const UserMap = (props)=> mapMenu({...props,
  perms: ['isMember']
});


export const GuestMap = (props)=> mapMenu({...props,
  perms: ['isAnonymous']
});

