import { ColorsProps } from '../shared/types'



export const low = (color: ColorsProps) => {
	return color.value.toLowerCase()
}

export const high = (color: ColorsProps) => {
	return color.value.toUpperCase()
}

export const formatInputValues = (value: number, min: number, max: number) => {
	return isNaN(value) ? min : value < min ? min : value > max ? max : value
}

export const round = (val: number) => {
	return Math.round(val)
}
