import withProps from "recompose/withProps";
import compose from "recompose/compose";
import mapInput from "@gqlapp/base/utils/mapInput";
import {graphql} from "react-apollo";
import DOMAINS_QUERY from "@xem/domain-common/graphql/domainsQuery.graphql";

/**
 *
 * @param input
 */
export const withDomains = (input: any) => compose(
  withProps((props: any) => mapInput(input, props)),
  withProps(({ domains:{ variables } }: { domains: any } )=>({ ...variables })),
  graphql(DOMAINS_QUERY, {
    options: ({ page, pageSize, orderBy, direction, filter, fetchPolicy }: any) =>{
      return ({
        variables: { page, pageSize, orderBy, direction, filter },
        fetchPolicy: fetchPolicy || null,
      })
    },
    props({

            /**
             *
             */
            ownProps: { domains, entitiesReset },
            /**
             *
             */
            data: { loading, res, refetch, error, updateQuery, fetchMore, subscribeToMore }
          }: any) {

      domains.refetch = refetch;
      domains.fetchMore = fetchMore;
      domains.subscribeToMore = subscribeToMore;
      domains.updateQuery = updateQuery;

      if (!loading && res) {
        domains.update(res, entitiesReset);
      }

      return {
        loading,
        errors: error ? error.graphQLErrors : null
      };
    }
  })
);

export default withDomains;
