import React from 'react';

import compose from "recompose/compose";
import PageLayout from '@xem/entity-client-react/components/PageLayout';
import Head from "@xem/entity-client-react/components/head/Head";
import User from "@xem/user-common/classes/User";
import Route from "@xem/router-common/classes/Route";

export const withRoute = (Component: any) => {
  return (({ currentUser, graphqlRoute, ...props}: any) => {
    // console.warn('########','route', '#########', graphqlRoute)
    Object.assign(props, {
      currentUser: User.instanse(currentUser),
      route: Route.instanse(graphqlRoute)
    })

    const webType = props?.webType || 'web';

    const $self: any = props?.$self;

    const components = $self?.getPopup([webType]) || [];
    const withRenderProcess = (Component: any) => ((props: any)=>
      React.createElement((({ children, onGlobalRenders }) => {
        return (<>
          {(onGlobalRenders && Object.keys(onGlobalRenders).length ) && Object.keys(onGlobalRenders)
            .map(key => onGlobalRenders[key])
            .map((C, key) => <C key={key} />)}
          {children}
        </>)
      }), props, React.createElement(Component, props))
    );

    /**
     * Load client withPreProps
     */
    const Render = compose(...($self.preload || []), ...components, withRenderProcess)
    ((props)=>{
      return React.createElement(Head, props, React.createElement(PageLayout, props, React.createElement(Component, props)))
    })


    // @ts-ignore
    return React.createElement(Render, props);
  });
};

export default withRoute;
