import {withFormik} from "formik";
import compose from "recompose/compose";
import withOnFilter from "./withOnFilter";

export default  compose(
  withFormik({
    mapPropsToValues: ({ entity }: any)=> ({
      onSelect: 0,
      name: entity?.name,
      note: entity?.note,
      nodes: entity?.nodes || []
    }),
    handleSubmit: function (){},
    enableReinitialize: true,
    displayName: 'FilterForm',
  }),
  withOnFilter
)