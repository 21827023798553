/**
 *
 * @param text
 * @param values
 * @returns {*}
 */
export default function replace(text, values){
  const pattern = new RegExp(
    Object.keys(values)
      .map(value => `(${value})`)
      .join('|'),
    'g'
  );
  const splitText = text.split(pattern);
  const matches = text.match(pattern) || [];

  if (splitText.length <= 1) {
    return text;
  }

  return splitText.reduce((arr, element, key) => {
    if (!element) {
      return arr;
    }
    if (matches.includes(element)) {

      // @ts-ignore
      if (values[element]) {
        // @ts-ignore
        element = values[element](key);
      }
      return [...arr, element];
    }
    return [...arr, element];
  }, []);
};
