import React from "react";
import dynamic from "next/dynamic";
import renderCallback from "@xem/entity/classes/renderCallback";
import compose from "recompose/compose";

const Form = dynamic(() => import('./Modal').then((c) => c.default));

const DataForm = ((props) => (
	<>{!__SERVER__ && (!!props?.isOpen) && <Form {...props} />}</>));

let ref = React.createRef();
export default compose(
	renderCallback(ref, 'cropping', DataForm),
	renderCallback(ref, 'imageUpload', DataForm)
);