import ClientModule from '@gqlapp/module-client-react';
import { mapMenu } from "./Utils";
import React from "react";
import UserAdd from "../icons/Add.svg";
import UserBlock from "../icons/Block.svg";
const UserMap = (props)=> mapMenu({...props,
	perms: ['isAdminDomain', 'managerDomain', 'isAdmin'],
});


const UserMenus = [
	{
		id: 'user_add',
		name: 'User Add',
		weight: 1,
		sx: { width: '60px !important' },
		content: (<UserAdd width={'60px'} height={20} />),
		routeTypes: ['UserList'],
		onClick:(e, { onActions, action }) =>{
			e.preventDefault();
			(onActions && onActions.UserAdd) && onActions.UserAdd({
				refetch: () => {
					const callback = action.callback;
					if(typeof callback == 'function'){
						callback();
					}
				}
			})
		}
	},
	{
		id: 'user_block',
		name: 'User Blocked',
		weight: 2000,
		sx: { width: '60px !important' },
		content: (<UserBlock width={'60px'} height={20} />),
		routeTypes: ['UserList'],
		onClick:(e, { onActions, action }) =>{
			e.preventDefault();
		}
	},
].map(UserMap)

export default new ClientModule({
	menus:[{ positions: ['bottom.mobile'], items: UserMenus }]
})
