import { map } from '../helpers/config';
import loadable from 'next/dynamic';
import withProps from "recompose/withProps";
import withFormFilter from '../helpers/Filter';
import ListCompose from '../helpers/ListCompose'
import withUserCount from "@xem/user-common/classes/withUserCount"
import withUserSearch from "@xem/user-common/classes/withUserSearch";
import withUserEmail from "@xem/user-common/classes/withUserEmail";

/**
 * Load component
 * @type {React.ComponentType<Omit<Omit<{readonly sx?: *, readonly datasource?: *, readonly columns?: *, readonly values?: *, readonly links?: *, readonly RendersNone?: *, readonly pageProps?: *}, "sx">, "datasource" | "columns" | "RendersNone" | "config">>}
 */
const EntitiesView = loadable(
  ()=>import("../components/EntitiesView").then(
    (c) => c.default)
);

export default ListCompose(
  {},
  [
    withUserCount('userCount'),
    withUserSearch('userSearch'),
    withUserEmail('userByEmail'),
    withProps(({ query }) => ({
      pageProps: {
        path: map({ path: 'list' }),
        other: map({ path: 'listpage', page: ':page' }), query }
    })),
    withProps(({ entities, setFieldValue })=>({
      refetch: () =>{
        try{ entities.refetch() }catch (e) {}
        try{ setFieldValue('page', 1) }catch (e) {}
      }
    })),
    withFormFilter,
  ]
)(EntitiesView);
