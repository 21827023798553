import {withFormik} from "formik";
import Box from "@mui/material/Box";
import Row from "@gqlapp/base/Row";
import Col from "@gqlapp/base/Col";
import RenderField from "@gqlapp/base/RenderField";
import Search from "./icons/control/Search.svg";
import Button from "reactstrap/lib/Button";
import Filter from "./icons/control/Filter.svg";
import New from "./icons/New.svg";
import React from "react";
import Field from "@gqlapp/forms-client-react/FieldAdapter";
import Radio from '@mui/material/Radio';
import Notice from './icons/control/Notice.svg';

export const getFilterByKey = (key) =>{
  let orderBy = 'CHANGED';
  let direction = 'DESC';

  /**
   * Từ a-z
   * và z-a
   */
  if(['az', 'za'].includes(key)){
    orderBy = 'NAME';
  }

  /**
   * default
   * Latest mới nhất
   */


  /**
   * Chiến dịch xem nhiều nhất
   * Chiến dịch xem ít nhất
   */
  if(['least', 'most'].includes(key)){
    orderBy = 'VIEWS';
  }

  if(['az', 'least'].includes(key)){
    direction = 'ASC';
  }

  return { direction, orderBy };
}

export const btnProps = {
  outline: true,
  className: 'rounded-0',
  color: 'secondary',
};

const MuiStyles= ({ onDel, onHide }) => ({
  '&':{
    zIndex: 1,
    position: 'absolute',
    top: '0px',
    left: 0,
    right: 0,
    paddingTop: '0',
    backgroundColor: '#f2f2f2'
  },
  '& .control-form':{
    padding: '7px 10px',
    backgroundColor: '#fff',
    '& .icon':{
      '&.delete':{
        color: (!!onDel )? '#C21C21': '#000000'
      },
      '&.hide':{
        color: (!!onHide )? '#C21C21': '#000000'
      }

    },
    '& .MuiInputBase-adornedStart':{
      paddingLeft: 0
    },

    '& .MuiFormControl-root':{
      '&.keyword':{
        '& .MuiOutlinedInput-notchedOutline':{
          border: 'none'
        },
        '& .MuiInputBase-root':{
          backgroundColor: '#f2f2f2',
          borderRadius: '36px',
          '& .MuiInputBase-input':{
            padding: '6.5px 10px 6.5px 0',
          }
        },

        marginTop: 0,
        marginBottom: 0,
        paddingRight: '5px'
      }
    },
    '& .btn': {
      '&.icon':{
        padding: '0 0 0 10px'
      }
    }
  }
});


let timeout = null;
let timeoutKeyword = null;
export const FormFilter =  withFormik({
  validate: ((values, { setValues })=>{
    clearTimeout(timeout);
    timeout = setTimeout( () => {
      if(!(values.onSelect)){
        Object.assign(values, { ids: [] });
      }
      setValues(values)
    }, 100);
  }),
  mapPropsToValues: ({ values }) => (values),
  handleSubmit: async function(values, { props: { entities, onActions, onUpdates, onDeletes }}) {
    let { ids, onSelect } = values;
    let type = null;
    switch (onSelect){
      case 1:
        type = 'delete'
        break;
      case 2:
        type = 'hide'
        break;
    }
    let confirm = onActions?.confirm || (()=>()=>{})
    confirm({
      label: <><Notice /></>,
      children: <>
        <Box sx={{ width: '100%'}} className={'d-flex justify-content-center'}>
          <Box>
            <span>{[`taxonomy:popup.${type}.link`, `taxonomy:popup.${type}.links`]
              .oddeven(ids.length).t({ value: ids.length })}</span>
          </Box>
        </Box>
      </>,

      btnCancel: `taxonomy:links.actions.cancel`.t(),
      btnConfirm: [`taxonomy:form.${type}.btn.taxonomy`, `taxonomy:form.${type}.btn.taxonomies`]
        .oddeven(ids.length)
        .t({ value: '' }),

      handleSubmit: ({}, { props:{ onClose } })=>{
        let { ids, onSelect } = values || {}

        let value;

        if(onSelect === 1){
          value = onDeletes({ ids });
        }
        else if(onSelect === 2) {
          value = onUpdates({ids, input: { status: 'inactive' }});
        }

        if(value){
          value.then(()=>{
            entities && entities.refetch && entities.refetch()
            onClose && onClose();
          });
        }
      }
    })()
  },
  enableReinitialize: true,
  displayName: 'EntitiesDeleteForm', // helps with React DevTools
})((
  { setFieldValue, onActions, onKeywordChange, onSelectChange,
    refetch, values: { defaultKey, keyword, onSelect }
  }) =>{


  let styles = MuiStyles({ onDel: onSelect === 1, onHide: onSelect === 2 });

  defaultKey = defaultKey || 'latest';

  // debug({ defaultKey })

  const map = (({ title, key, ...rest})=>{
    return {
      ...rest,
      handleClick:()=> {
        onSelectChange(key);
      },
      title: () => (<Row none>
        <Col xs={'auto'}><Radio checked={(key === defaultKey)} /></Col>
        <Col className={'d-flex align-items-center'}>{title}</Col>
      </Row>)
    }
  });

  const viewMenus = [
    { key: `latest`,      title: 'taxonomy:filter.latest'.t() },
    { key: `most`,        title: 'taxonomy:filter.most'.t()},
  ].map(value=> map(value));

  const titleMenus = [
    { key: `az`,          title: `taxonomy:filter.az`.t()},
    { key: `za`,          title: 'taxonomy:filter.za'.t() }
  ].map(value=> map(value));


  const managerMenus = [
    { key: `assigned`,          title: `taxonomy:filter.assigned`.t()}
  ].map(value=> map(value));

  const onClick = () =>{
    (onActions && onActions.Toolbars) && onActions.Toolbars(
      {
        enableClose: true,
        items: [ viewMenus, titleMenus, managerMenus ]
          .map((items)=>({
          items , type: 'group',
          wrapper: ({ children })=><Box className={'btn-group-vertical'} sx={{ '&':{ width: '100%', '&> .btn': { padding:0}} }}>{children}</Box>,
        })),
        headerProps:{ style: { padding: '7px 0' } },
        title: 'taxonomy:filter.label'.t() })
  }


  const onCreate = () =>{
    (!!(onActions && onActions.TaxonomyCreate)) && onActions.TaxonomyCreate({ refetch, title: 'taxonomy:label.create'.t() })()
  }


  return (<>
    <Box sx={styles}>
      <Row className={'control-form'} none>
        <Col>
          <Field
            {...{
              className: 'keyword',
              placeholder: `search.keywords`.t(),
              component: RenderField,
              name: 'keyword',
              value: keyword,
              onChange: (value) => {
                onKeywordChange(value);
                setFieldValue('keyword', value)
              },
              startAdornment: <div style={{ width: '36px', height: '36px'}}>
                <Search />
              </div>
            }}
          />
        </Col>
        <Col xs={'auto'} className={'d-flex align-items-center'}>
          <Button onClick={onCreate} className={'icon'} color={'default'}><New /></Button>
        </Col>
        <Col xs={'auto'} className={'d-flex align-items-center'}>
          <Button onClick={onClick} className={'icon'} color={'default'}><Filter /></Button>
        </Col>
      </Row>
    </Box>
  </>)
})
// mapPropsToValues: ({ values, cookie }) => ({ ...values, defaultKey: cookie?.filter?.key }),

const FormWrapper =  withFormik({
  mapPropsToValues: ({ entities, cookie }) =>
    ({ ids: [], count: 0, all: false, items: entities.items, page: 1, onSelect: 0, defaultKey: cookie?.filter?.key }),
  enableReinitialize: true,
  displayName: 'EntitiesForm', // helps with React DevTools
})(({ children, ...props}) =>{
  let { onActions, entities, pageProps, setFieldValue, loadbar, values: { items, page } } = props;

  const innerRef = ()=>{

    if(!!(onActions['Filter'])) {
      onActions['Filter']({ ...props, isOpen: true })
    }
  }


  function getMore(items, page, reset = false) {
    entities.page = page;
    if(reset){ entities.update(null, true); }
    return entities.fetchMore(entities.variables, loadbar).then(async (res)=>{
      entities.add(res);
      setFieldValue('page', page);
    });
  }

  /**
   * handle Page change
   * @param page
   * @returns {Promise<void>}
   */
  let handlePageChange = async (page) => {
    if (page !== entities.page) {
      let items = entities.items || [];
      await getMore(items, page);
    }
  }


  /**
   *
   * @param keyword
   */
  const onKeywordChange = (keyword)=>{
    keyword = keyword || ``;
    entities.page = 1;
    entities.filter = { search: [{ keyword }] };

    clearTimeout(timeoutKeyword);
    timeoutKeyword = setTimeout( async () => {
      setFieldValue('keyword', keyword);
      await getMore([], 1, true);
    }, 500);
  }


  /**
   * onSelectChange
   * @param key
   * @returns {Promise<void>}
   */
  const onSelectChange = async (key)=>{

    setFieldValue('defaultKey', key);

    let { orderBy, direction } = getFilterByKey(key);

    /**
     * End direction
     */

    entities.page = 1;
    entities.orderBy = orderBy;
    entities.direction = direction;
    setCookie('taxonomy_list_sort', key)
    await getMore([], 1, true);
  }

  pageProps = {
    ...entities.variables,
    ...pageProps,
    page,
    pageCount: entities.pageCount,
    collectionSize: entities.count,
    handlePageChange
  }

  Object.assign(props, { onKeywordChange, onSelectChange, parentFieldValue: setFieldValue, pageProps });

  return <>
    <Box ref={innerRef} sx={{ paddingTop: '0px', backgroundColor: '#f2f2f2', minHeight: 'calc(100%)' }}>
      {typeof children === 'function' && children(props)}
      {typeof children !== 'function' && children}
    </Box>
  </>
});

const withFormFilter = (Component) => props => <FormWrapper {...props}>{(rest)=><Component {...rest} />}</FormWrapper>
export default withFormFilter;
