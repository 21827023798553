import React from 'react';
import Button from "reactstrap/lib/Button";
import {Facebook, Linkedin, Twitter} from "react-social-sharing";

import Qrcode from "@xem/icon/links/qrcode.svg";
import QrCodeWeb from "@xem/icon/links/web/qrcode.svg";
import Shared from "@xem/icon/links/shared.svg";
import Copy from "@xem/icon/links/copy.svg";
import {CopyToClipboard} from "react-copy-to-clipboard";


export const ShareBtnMobile = ({ className, share, full, toggleQR, alert }) => <>
	<div className={'border-top d-flex justify-content-evenly'}>
		<div>
			<Button
				onClick={()=>{
					try{ navigator.share(share); }catch (e){ alert(e, 'danger'); }
				}}
				className={className}
				color={'default d-flex align-items-center justify-content-center'}>
				<Shared />
				<span className={'text-secondary'}>&nbsp;&nbsp;{'share'.t()}</span>
			</Button>
		</div>
		{ (!!full) && <div>
			<Button className={className} onClick={toggleQR}  color={'default d-flex align-items-center justify-content-center'}>
				<Qrcode />
				<span className={'text-secondary'}>&nbsp;&nbsp;{'qrcode'.t()}</span>
			</Button>
		</div>}
		<div>
			<CopyToClipboard text={full} onCopy={() =>alert({ message: 'copied'.t() })}>
				<Button className={className} color={'default d-flex align-items-center justify-content-center'}>
					<Copy />
					<span className={'text-secondary'}>&nbsp;&nbsp;{'copy'.t()}</span>
				</Button>
			</CopyToClipboard>
		</div>
	</div>
</>

const ShareBtn = ({ full, toggleQR, className, style }) => <>
	<div className={'d-none d-lg-block'}>{'share'.t()}:</div>
	<Facebook solid small {...{ style, className, link: full }} />
	<Linkedin solid small {...{ style, className, link: full }} />
	<Twitter solid small {...{ style, className, link: full }} />
	{ (!!full) && <Button onClick={toggleQR}  color={'default'}>
		<span className={'d-none d-lg-block'}>
			{/*<span>{'qrcode'.t()}&nbsp;:&nbsp;</span>*/}
			<QrCodeWeb />
		</span>
		<span className={'d-block d-lg-none'}><QrCodeWeb value={full} size={20} /></span>
	</Button>}
</>

export default ShareBtn;
