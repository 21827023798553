import React from 'react';
import * as PropTypes from "prop-types";
const Pen = ({...props}) => (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill={"currentColor"} {...props}>
    <g transform="translate(-11 -367)">
        <rect width="40" height="40" transform="translate(11 367)" fill="#fff"/>
        <path d="M4.5,23.5v5h5L24.244,13.752l-5-5ZM28.11,9.886a1.328,1.328,0,0,0,0-1.88l-3.12-3.12a1.328,1.328,0,0,0-1.88,0l-2.44,2.44,5,5,2.44-2.44Z" transform="translate(14.5 370.504)"/>
    </g>
</svg>);


Pen.propTypes = {
    width: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    height: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    className: PropTypes.node
}


Pen.defaultProps = {
    width: 40,
    height: 40
}

export default Pen;
