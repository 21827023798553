import withProps from "recompose/withProps";

export default withProps({
  sx: ({
    '&':{
      zIndex: 1,
      position: 'absolute',
      top: '0px',
      left: 0,
      right: 0,
      paddingTop: '0',
      backgroundColor: '#f2f2f2'
    },
    '& .control-form':{
      padding: '7px 10px',
      backgroundColor: '#fff',
      '& .icon':{
        '&.delete, &.hide':{
          color: '#000000',
          '&.active': {
            color: '#C21C21'
          }
        }
      },
      '& .MuiInputBase-adornedStart':{
        paddingLeft: 0
      },

      '& .btn': {
        '&.icon':{
          padding: '0 0 0 10px'
        }
      },

      '& .MuiFormControl-root':{
        '&.keyword':{
          '& .MuiOutlinedInput-notchedOutline':{ border: 'none' },
          '& .MuiInputBase-root':{
            backgroundColor: '#f2f2f2',
            borderRadius: '36px',
            '& .MuiInputBase-input':{
              padding: '6.5px 10px 6.5px 0',
            }
          },
          '& .startAdornment':{
            '& > div':{
              paddingLeft: '2px',
              paddingTop: '2px',
              '& svg':{
                color: '#f2f2f2 !important'
              },
              '&':{
                width: '36px !important',
                height: '36px !important'
              }
            }
          },
          marginTop: 0,
          marginBottom: 0,
          paddingRight: '5px'
        }
      }
    }
  })
})