import ClientModule from '@gqlapp/module-client-react';
import { mapMenu } from "./utils";
import React from "react";
import DomainAdd from "../icons/DomainAdd.svg";
const UserMap = (props)=> mapMenu({...props,
  perms: ['isAdminDomain', 'managerDomain', 'isAdmin'],
});


const UserMenus = [
  {
    id: 'domain_add',
    name: 'Domain Add',
    weight: 1,
    sx: { width: '60px !important' },
    content: (<DomainAdd width={'60px'} height={20} />),
    routeTypes: ['DomainList'],
    onClick:(e, { onActions, action }) =>{
      e.preventDefault();
      (onActions && onActions.domainAdd) && onActions.domainAdd({
        refetch: () => {
          const callback = action.callback;
          if(typeof callback == 'function'){
            callback();
          }
        }
      })
    }
  },
].map(UserMap)

export default new ClientModule({
  menus:[{ positions: ['bottom.mobile'], items: UserMenus }]
})
