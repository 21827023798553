import React  from 'react';
import clsx from "clsx";
import AppBar from '@mui/material/AppBar';
import Logo from "../icons/Logo.svg";

import Link from "@xem/look/Link";
import HeaderMenu from '../utils/HeaderMenu'
import UserMenu from '../utils/UserMenu'
import Container from 'reactstrap/lib/Container';
import Row from '@gqlapp/base/Row';
import Col from '@gqlapp/base/Col';


const HeadSide = (props) => {
  let { className, webType } = props;
  let classes = ['MuiPaper-elevation', 'head-top', 'appBar', 'shadow', 'bg-white'];
  let style;

  const Toggle = () => {
    try{ props?.siteAction?.callback();}catch (e) {}
  };

  const uid = props?.currentUser?.id || 0;
  if(uid || webType ==='textsocial'){
    className= clsx(className, classes, ['sticky', 'd-none','d-md-block','bg-white']);
  }else{
    style={ maxWidth: '1200px', margin: '0 auto' };
    className= clsx(className, classes, ['d-none','d-md-block']);
  }

  return <AppBar
    color={'default'}
    position="relative"
    className={className}
  >
    <Container fluid>
      <div data-header className={'header'}>
        <Row className={clsx( 'px-0 zIndex')} style={style}>
          <Col className={'d-flex align-items-center'}>
          <span className={'text-warning'}><Link to={'/'}>
            <Logo className={'logo text-warning'} height={'60px'}/>
          </Link></span>
            <div className={'pl-md-2 pl-lg-7'}><HeaderMenu {...props} /></div>
          </Col>
          <Col xs={'auto'} className={'d-flex align-items-center'}>
            <UserMenu {...props} />
          </Col>
        </Row>
      </div>
    </Container>
  </AppBar>
}

export default HeadSide;
