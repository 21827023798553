import React from 'react';
import * as PropTypes from "prop-types";
const IconArrow = ({...props}) => {
    return (<>
        <svg viewBox="0 0 10 6" xmlns="http://www.w3.org/2000/svg" fill={'currentColor'} {...props}>
            <path
              d="M4.61233 5.84148L0.161298 1.43482C0.110167 1.38434 0.0696021 1.32438 0.0419244 1.25838C0.0142467 1.19237 0 1.12161 0 1.05015C0 0.978683 0.0142467 0.907923 0.0419244 0.841915C0.0696021 0.775908 0.110167 0.715949 0.161298 0.665473L0.680218 0.15883C0.783198 0.0571235 0.922761 0 1.06827 0C1.21378 0 1.35334 0.0571235 1.45632 0.15883L5 3.64903L8.54368 0.15883C8.64666 0.0571235 8.78622 0 8.93173 0C9.07724 0 9.2168 0.0571235 9.31978 0.15883L9.8387 0.672228C9.88983 0.722704 9.9304 0.782663 9.95808 0.848671C9.98575 0.914678 10 0.985439 10 1.0569C10 1.12836 9.98575 1.19912 9.95808 1.26513C9.9304 1.33114 9.88983 1.3911 9.8387 1.44157L5.38843 5.84148C5.28535 5.943 5.14583 6 5.00038 6C4.85493 6 4.71541 5.943 4.61233 5.84148Z"
              fill={'currentColor'} />
        </svg>
    </>)
};


IconArrow.propTypes = {
    width: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    height: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    className: PropTypes.node
}


IconArrow.defaultProps = {
    width: 10,
    height: 6
}

export default IconArrow;
