import React from 'react';
import Row from '../Row';
import ScrollStyle from "./Scroll.style";
import clsx from "clsx";
import Swipeable from "../Swipeable/Swipeable";
import Box from '@mui/material/Box';

export const Scrollable = React.forwardRef(
    function Scroll({ Wrap, resizeDisable, scrollToEnd, setWidth = true, classes, className, children, scrollStart, scrollEnd, ...props }, ref){

        const containerRef = React.useRef(null)
        const scrollRef = React.useRef(null)
        const contentRef = React.useRef(null)

        const scrollStartRef = React.useRef(null)
        const scrollEndRef = React.useRef(null);

        /**
         * Bind content width
         * @param parent
         * @param nodes
         */
        function init(){
            let parent = contentRef.current;
            let nodes = parent?.childNodes || []

            try{
                let parentWidth = 0
                nodes.forEach((node)=>{

                    let width = node.clientWidth;
                    /** Thêm vào nếu kiểm tra được triều rộng của node ...*/
                    if(!resizeDisable && width && setWidth){
                        parentWidth += width;
                        node.style.setProperty('width', `${width}px`)
                    }
                })

                /** Đặt chiểu rộng wrapper */
                if(!resizeDisable && parentWidth){
                    parent.style.setProperty('width', `${parentWidth}px`)
                    contentRef.current.classList.remove('init');
                }

            }catch (e) {
                console.warn(e)
            }
        }


        const bindScroll  = () =>{
            const node = scrollRef.current;
            let hasScrollX = false;
            let isScrollMinX = false, isScrollMaxX = false;

            try{
                scrollStartRef.current?.style.setProperty('display', 'none')
                scrollEndRef.current?.style.setProperty('display', 'none')
            }catch (e) {}


            try{
                /** */
                const overflowXStyle = window.getComputedStyle(node).overflowX;
                let hasScrollableXContent = node.scrollWidth > node.clientWidth;
                hasScrollX = (hasScrollableXContent && (overflowXStyle.indexOf('hidden') === -1));
            }catch (e) {}

            try{
                isScrollMinX 		= node.scrollLeft <= 0;
                isScrollMaxX 		= node.scrollLeft + node.clientWidth >= node.scrollWidth;
            }catch (e) {}


            if(hasScrollX){
                try{
                    scrollStartRef.current?.style.setProperty('display', !!isScrollMinX ? 'none': 'block')
                    scrollEndRef.current?.style.setProperty('display', !!isScrollMaxX ? 'none': 'block')
                }catch (e) {

                }
            }
        }

        function innerContentRef(node){
            (node && (contentRef.current = node));
            if(contentRef.current){
                setTimeout(init, 300);
                setWidth && contentRef.current.classList.add('init');
            }
        }

        function innerRef(node){
            (node && (scrollRef.current = node));
            node && node.addEventListener('scroll', bindScroll)
            setTimeout(bindScroll, 350);
        }


        const swipeProps = {
            onSwipeStart: ({ set }) => set((state, {})=>{
                state.el && state.el.classList.add('blockClick');
                return state;
            }),
            onSwiped: ({ set }) => set((state, {})=>{
                state.el && state.el.classList.remove('blockClick');
                return state;
            }),
            onSwiping: ({ dir, deltaX, event, set }) => set((state, {})=>{
                let el = state?.el;
                let scrollX = state?.scrollX;
                let scrollY = state?.scrollY;
                let scrollMaxX = state?.scrollMaxX;

                const isTouch = "touches" in event;

                /** Kiểm tra Scoll X có thể hoạt động không */
                if( el && !isTouch && ['left', 'right'].includes(`${dir}`.toLowerCase())) {
                    scrollX -= deltaX;
                    if(scrollX > scrollMaxX){
                        scrollX = scrollMaxX;
                    }
                    else if(scrollX < 0){
                        scrollX = 0;
                    }

                    el.scrollTo(scrollX, scrollY)
                }
                return state;
            })
        }

        Wrap = Wrap || Row;

        const next = () =>{
            let scrollWidth = scrollRef.current.scrollWidth;
            let clientWidth = scrollRef.current.clientWidth;
            let left = scrollRef.current.scrollLeft

            if(left + clientWidth <= scrollWidth - clientWidth){
                left += clientWidth;
            }else{
                left = scrollWidth - clientWidth;
            }

            scrollRef.current && scrollRef.current.scrollTo({ top: 0, left, behavior: "smooth" })
        }


        const prev = () =>{
            let clientWidth = scrollRef.current.clientWidth;
            let left = scrollRef.current.scrollLeft
            if(left - clientWidth > 0){
                left -= clientWidth;
            }else{
                left = 0;
            }

            scrollRef.current && scrollRef.current.scrollTo({ top: 0, left, behavior: "smooth" })
        }

        return (<>
                <Box sx={classes.root} ref={containerRef} className={'hidden'}>
                    <Swipeable data-scroll {...{ swipeProps, innerRef }}>
                        <Wrap {...props} ref={innerContentRef} className={clsx(className, 'flex-nowrap', 'menu')}>{children}</Wrap>
                    </Swipeable>
                    {(!!scrollStart) && <>
                        <div
                            ref={scrollStartRef}
                            data-arrow-start={true}
                            style={{ display: 'none'}}
                            onClick={prev}
                        >{scrollStart}</div>
                    </>}
                    {(!!scrollEnd) && <>
                        <div
                            ref={scrollEndRef}
                            data-arrow-end={true}
                            style={{ display: 'none'}}
                            onClick={next}
                        >{scrollEnd}</div>
                    </>}
                </Box>
            </>
        );
    }
);

export default ScrollStyle(Scrollable);
