import React from 'react';
import { default as i18next } from 'i18next';
import { I18nextProvider } from 'react-i18next';

import settings from '@xem/config/i18n';
import { getCookie } from "@gqlapp/cookie-client-react";
import ClientModule from '@gqlapp/module-client-react';
// @ts-ignore
import commonI18n from '@xem/i18n-common-react';

const I18nProvider = ({ i18n, children }: any) => {
  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
};


interface Props { req: any; i18n?: any }

class RootComponent extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    if (this.props.req) {
      const lang:string = getCookie(settings.cookie) || settings.init.lng;
      i18next.changeLanguage(lang);
    }
  }

  public render() {
    // @ts-ignore
    return <I18nProvider i18n={i18next}>{this.props.children}</I18nProvider>;
  }
}

export default (!settings.enabled)
  ? undefined: new ClientModule(commonI18n, {
    appContext: { i18n: true },
    rootComponentFactory: [req => <RootComponent req={req} />]
  });
