import React from 'react';
import loadable from 'next/dynamic';

const LoginContainer = loadable(
  ()=>import('./Login').then(
    (c) => c.default)
);

export default LoginContainer;

