import loadable from 'next/dynamic';

import withProps from "recompose/withProps";
import onSave from "@xem/entity/classes/onSave";
import withCustomPageCreate from "@xem/custom-page-common/classes/withCustomPageCreate";
import withCustomPageUpdate from "@xem/custom-page-common/classes/withCustomPageUpdate";
import CustomPagesCompose from "../helpers/pages/CustomPagesCompose";
import withPopupCreate from "../components/form/action/create";
import withPopupConfig from "../components/form/action/config";
import withRenderProcess from '@xem/entity/classes/withRenderProcess';
import blocks from "../components/form";

const ListContainer = loadable(
  ()=>import('../components/IntroPagesView').then(
    (c) => c.default)
);


export default CustomPagesCompose({}, [
	withCustomPageCreate,
	withCustomPageUpdate,
	blocks,
	withProps(({ pageCreate, pageUpdate, entity, query }) => ({
		onSave	: onSave(pageCreate, pageUpdate,{ entity })
	})),
	withPopupConfig,
	withPopupCreate,
	withRenderProcess()
])(ListContainer);