import ClientModule from "@gqlapp/module-client-react";

import compose from "recompose/compose";
import advanced from "./advanced";
import custom from "./custom";
import search from "./search";
import Display from "./display";
import Protected from "./protected";

import Redirect from "./redirect";
import STORE from "./redirect/store";
import campaign from "./campaign";
import Seo from './seo';

const popupComponents = [
	{ component: compose(search, advanced, custom, Display, Redirect, STORE, Protected, campaign, Seo) }
]

export default new ClientModule({ popupComponents });