import React  from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import Row from '../Row';
import Col from '../Col';
import Map from './map';

const ItemCell = ({ children, ItemWrap, ...props }) => {
  ItemWrap = ItemWrap || (({ children, className }) => { return <div {...{className, children }} />;});
  return (<ItemWrap {...props}>{children}</ItemWrap>)
}

/**
 *
 * @param columns
 * @param datasource
 * @param ItemWrap
 * @param itemProps
 * @returns {*[]}
 */
const renderBody = ({ columns, datasource, ItemWrap, itemProps  }) =>(<>
  <Map items={datasource}>{({ hide, className, classColName, ...entry })=>(<>
    { (!hide) && <Col {...{ className, ...itemProps}}>
      {renderData({ columns, entry, classColName, ItemWrap })}
    </Col>}
  </>)}
  </Map>
</>);

/**
 *
 * @param columns
 * @param entry
 * @param classColName
 * @param ItemWrap
 * @returns {JSX.Element}
 */
const renderData = ({columns = [], entry, classColName, ItemWrap}) =>(<>
  <Map items={columns}>{({ dataIndex, className, style, render })=>(<>
    <ItemCell {...{ ItemWrap, className: clsx(classColName, className, dataIndex), style }}>
      {render ? render(entry[dataIndex], entry) : entry[dataIndex]}
    </ItemCell>
  </>)}
  </Map>
</>);


/**
 *
 * @param hover
 * @param datasource
 * @param columns
 * @param className
 * @param ItemWrap
 * @param itemProps
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Item = ({ hover, datasource, columns, className, ItemWrap, itemProps, ...props }) => (
  <Row {...{...props, className }}>
    {(!!datasource.length) && <>{renderBody({ hover, columns, datasource, ItemWrap, itemProps})}</>}
  </Row>
);

Item.propTypes = {
  none: PropTypes.node,
  datasource: PropTypes.array,
  columns: PropTypes.array,
  hover: PropTypes.bool,
  theadProps: PropTypes.any
};

Item.defaultProps = {
  hover: false
};

export default Item;
