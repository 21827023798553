import USERS_QUERY from '@xem/user-common/graphql/UsersSearch.graphql'
import { withContentCallback } from '@xem/entity/classes/preloadContents';
export default function userSearch(name: string) {
	return withContentCallback(((res: any)=> {
		let data = [];
		if (res?.edges?.length) {
			res?.edges.map((value: any) => data.push(value));
		}
		return data;
	}), name, USERS_QUERY)
}
