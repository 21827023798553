import compose from 'recompose/compose';
import withProps from 'recompose/withProps';
import withLoading from '@xem/utils/withLoading';
import Entities from '@xem/store-common/classes/Products';
import withProducts, { PRODUCTS_QUERY } from '@xem/store-common/classes/withProducts';
import withProductsDeleting from '@xem/store-common/classes/withProductsDeleting';
import onDeletes from "./utils/onDeletes";
import { getFilterByKey } from "./Filter";
import MediaTools from "./MediaTools";

/**
 *
 * @param input
 * @param withPropsInput
 * @param withPropsBefore
 */
export const ModulesCompose = (input?: any, withPropsInput?: any[], withPropsBefore?: any[]) => {
	return (Route: any) => {

		const getEntities = function getPath({ query, cookie, status = 'active'}: any) {
			let id = parseInt(query?.id);

			let entities = Entities.instanse({ pageSize: 16, orderBy: 'CHANGED', protected: true });

			let orderBy, direction;
			let filter: any = {};

			(cookie) && ({orderBy, direction, status} = getFilterByKey(cookie));

			(!!id) && (Object.assign(filter, { tids: [id] }));
			(!!status) && (Object.assign(filter, {status}));

			(!!orderBy) && (entities.orderBy = orderBy);
			(!!direction) && (entities.direction = direction);

			return entities;
		}


		/**
		 * WithRouteLink
		 */
		const WithRouteLink = compose(
			MediaTools,
			withProps(input),
			withProductsDeleting,
			withProps(({ productsDelete, query, cookie, ...props}) => ({
				onDeletes: onDeletes(productsDelete, props),
				entities: getEntities({ query, cookie: cookie?.filter?.key})
			})),
			withProps(({ entities, action })=>{
				const refetch = () =>{
					entities && entities.refetch && entities.refetch()
				}

				action.callback = refetch

				return ({ refetch })
			}),
			...(withPropsBefore || []),
			withProducts(({ entities }) => ({ products: entities })),
			...(withPropsInput || []),
			withLoading
		)(Route);



		const setCache = async ({ apolloClient, query, variables }) =>{
			/** Preload taxonomy **/
			let result = await apolloClient.query({ query, variables, fetchPolicy: 'no-cache' });
			/** set cache taxonomy **/
			await apolloClient.writeQuery({ query, variables, data: result?.data });
		}

		const bindCache = async (apolloClient, query, obj)=> {
			let entities = getEntities(obj)
			let variables = entities.variables;

			/** set link cache*/
			await setCache({ query, variables, apolloClient })
		}


		// @ts-ignore
		WithRouteLink.getInitialProps = async ({ apolloClient}) => {
			let ctx = {};
			await bindCache(apolloClient, PRODUCTS_QUERY, {})
			return ({...ctx})
		}

		return WithRouteLink;
	};
};

export default ModulesCompose;
