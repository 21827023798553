import React from 'react';
import Typed from 'typed.js';

import compose from 'recompose/compose';
import withProps from 'recompose/withProps';

import Entities from "@xem/link-common/classes/Links";
import withLinkCreate from "@xem/link-common/classes/withLinkCreate";
import withLinks from "@xem/link-common/classes/withLinks";
import HomeView from '../components/HomeView';
import withLoading from "@xem/utils/withLoading";
import withResizeProps from "@xem/look/withResizeProps";
import { entityProps, onCreate } from "@xem/link-client-react/helpers/utils";

let timeout;

const Home = compose(
	withProps(({ status }) =>{
		let [state, setStated ] = React.useState(false);
		const typing = () =>{
			try{
				new Typed('.xemlink-typed', {
					strings: ['', 'Https://Xem.Link/Kiếm-Tiền-Online', 'Https://Xem.Link/Ăn-gì-hôm-nay', 'Https://Xem.Link/Làm-gì-hôm-nay', 'Https://Xem.Link/Đi-Đâu-hôm-nay'],
					typeSpeed: 80,
					backSpeed: 0,
					backDelay: 1000,
					cursorChar: '_',
					smartBackspace: true, // this is a default
					loop: true
				});
			}catch (e) {}
		}

		if(!state){
			setStated(true);
			clearTimeout(timeout);
			timeout = setTimeout(typing, 1000);
		}

		return ({
			entities: Entities.instanse({
				pageSize: 5, orderBy: 'ID', filter: { status, bySession: true }
			})
		})
	}),
	entityProps,
	withLinkCreate,
	withResizeProps,
	withLinks(({ entities })=>({ links: entities, view_limit: true })),
	/**
	 *
	 */
	withProps(({ loading, linkCreate, entities, ...props }) =>{
		return ({
			onSubmit: onCreate(linkCreate, props),
			refetch: () =>{
				try{ entities.refetch() }catch (e) {}
			}
		});
	}),

	/**
	 *
	 */
	withLoading
)(HomeView);


export default Home;
