
import React from 'react';


// @ts-ignore
export const menuMap = ({ name, path, parent, value, itemProps, className, ...rest }: any) => ({ query: { id } = {}, ...self }: any = {}, callback: any)=>{
	return  callback({
		...rest,
		itemProps:{
			className: 'btn btn-link btn-block',
			...itemProps
		},
		id: `user-${name}`,
		// @ts-ignore
		name: `user:label.${name}`.t({}),
		// @ts-ignore
		title: `user:label.${name}`.t({}),
		className: ['m-0 p-0'],
		parent : parent || [{targetId: 0}]
	});
}

export const UserMap = (props: any)=> menuMap({...props,
	itemProps:{
		className: 'p-2 m-0 d-flex align-items-center',
	}
});


export const mapMenu = ({ id, name, className, parent, ...rest }) => (props, callback)=>{
	// @ts-ignore
	return  callback({
		...rest,
		id: id || `${name}`,
		// @ts-ignore
		name: (typeof name !== 'undefined') ? <div style={{ minWidth: '60px', lineHeight: '10px', fontSize: '10px', padding: '4px 5px 0' }}>{`${name}`.t()}</div>: null,
		className: className || ['p-0 border-0'],
		parent : parent || [{targetId: 0}]
	});
}

export const GuestMap = (props)=> mapMenu({...props,
	perms: ['isAnonymous']
});
