import React from 'react';
import Base from '@gqlapp/base/PageLayout';

const ref: any = { modules: null };

export const onAppCreate = async (modules: any) => (ref.modules = modules);

const PageLayout = (props: any) => {
  let { className, webType, classToggle, ...other } = props || {};
  className = className || null;
  webType = webType || 'web';

  try {
    $$('.main-overlay').onclick = function (e) {
      e.preventDefault();
      try {
        props?.siteAction?.callback();
      } catch (e) {
        error(e);
      }
    };
  } catch (e) {}

  const getBlock = (layout: any, {}: any) => {
    try {
      return (<>{ref.modules.getBlock(layout, { ...props, webType })}</>);
    } catch (e) {}
    return null;
  };

  return (<>
    <Base {...{ ...other, viewTag: 'section', className, webType, getBlock }} />
    <div ref={props?.element} />
  </>);
};

PageLayout.propTypes = {};

// @ts-ignore
export default PageLayout;
