import { compose, withProps } from 'recompose';
import withLoading from '@xem/utils/withLoading';
import WEBRISKS_QUERY from '@xem/webrisk-common/graphql/webrisksQuery.graphql'
import Entities from '@xem/webrisk-common/classes/Webrisks';
// @ts-ignore
import withWebrisks from '@xem/webrisk-common/classes/withWebrisks';
// @ts-ignore
import withWebrisksDeleting from '@xem/webrisk-common/classes/withWebrisksDeleting';
import onDeletes from "./utils/onDeletes";
import { getFilterByKey } from "./Filter";
import MediaTools from "./MediaTools";

/**
 *
 * @param input
 * @param withPropsInput
 * @param withPropsBefore
 */
export const WebrisksCompose = (input?: any, withPropsInput?: any[], withPropsBefore?: any[]) => {
	return (Route: any) => {

		const getEntities = function getPath({ query, cookie, status = 'active'}: any) {
			let page = query?.page || 0, id = parseInt(query?.id);

			let entities = Entities.instanse({ pageSize: 16, orderBy: 'CHANGED', protected: true });

			let orderBy, direction;
			let filter: any = {};

			(cookie) && ({orderBy, direction, status} = getFilterByKey(cookie));

			(!!id) && (Object.assign(filter, { tids: [id] }));
			(!!status) && (Object.assign(filter, {status}));

			(!!orderBy) && (entities.orderBy = orderBy);
			(!!direction) && (entities.direction = direction);

			return entities;
		}


		/**
		 * WithRouteLink
		 */
		const WithRouteLink = compose(
			MediaTools,
			withProps(input),
			withWebrisksDeleting,
			withProps(({ webrisksDelete, query, cookie, ...props}) => ({
				onDeletes: onDeletes(webrisksDelete, props),
				entities: getEntities({ query, cookie: cookie?.filter?.key})
			})),
			withProps(({ entities, action })=>{
				const refetch = () =>{
					entities && entities.refetch && entities.refetch()
				}

				action.callback = refetch

				return ({ refetch })
			}),
			...(withPropsBefore || []),
			withWebrisks(({ entities }) => ({ webrisks: entities })),
			...(withPropsInput || []),
			withLoading
		)(Route);


		// @ts-ignore
		WithRouteLink.getInitialProps = async ({req, res, path, query, apolloClient}) => {
			let ctx = {}, variables = {};
			let cookie = getCookie('webrisk_list_sort', { req, res });

			let entities = getEntities({ path, query, cookie })
			variables = entities.variables;

			/** Preload taxonomy **/
			let result = await apolloClient.query({ query: WEBRISKS_QUERY, fetchPolicy: 'no-cache', variables });

			/** set cache taxonomy **/
			await apolloClient.writeQuery({ query: WEBRISKS_QUERY, data: result?.data, variables });


			return ({...ctx})
		}

		return WithRouteLink;
	};
};

export default WebrisksCompose;
