import React from "react";

import clsx from 'clsx';
import Box from "@mui/material/Box";
import Button from 'reactstrap/lib/Button';

import Scroll from "@gqlapp/base/Scroll";
import Map from "@gqlapp/base/Item/map";

import SlideLeft from '@xem/entity-client-react/helpers/icons/SlideLeft.svg';
import SlideRight from '@xem/entity-client-react/helpers/icons/SlideRight.svg';

const Styles = () => ({
  '&':{
    height: '50px',
    position: 'sticky',
    width: '100%',
    zIndex: 998,
    bottom: '0px',
    left: 0,
    right: 0,
    paddingTop: '0',
    backgroundColor: '#ffffff',
    '[data-arrow-end], [data-arrow-start]': {
      boxShadow: '0px 0px 3px rgba(176, 176, 176, 0.5)',
      position: 'absolute',
      top: 0
    },
    '[data-arrow-start]': {
      left: 0
    },
    '[data-arrow-end]': {
      right: 0
    },
    '& .item-wrap':{
      display: 'flex',
      justifyContent: 'center',
    },

    'button':{
      padding: 0,
      '.item':{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
      },
      '.icon, .name': {
        width: '100%'
      },
      '.icon':{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      '.name': {
        minWidth: '60px',
        lineHeight: '10px',
        fontSize: '10px',
        padding: '6px 5px 0px',
        whiteSpace: 'nowrap'
      }
    }
  },
})

export function FormFilter(props){
  let sx = Styles();
  const BaseForm = (function Menu({ navigation }){
    return (<Box sx={sx}>
      <Scroll
        none
        className={'justify-content-evenly'}
        style={{ minWidth: '100%'}}
        scrollStart={<SlideLeft />}
        scrollEnd={<SlideRight />}>
        <Map items={(navigation)}>
          {(props) =>{
            let { title, id, onClick, className} =  props
            let { content, name, url } =  props

            let link = { id, title, url, className: clsx(className, 'animation text-secondary', 'border-0')};

            if (typeof onClick === 'function') {
              link = { ...link, color: 'default', onClick };
            }

            return (<>
                <Box
                  style={{ height: 'var(--page-footer-height)'}}
                  className={'col col-auto item-wrap'}
                >
                  <Button {...link}>
                    <div className={'item'}>
                      <div>
                        {!!content && (<><div className={'icon'}>{content}</div></>)}
                        {name && <div className={'name'}>{name}</div>}
                      </div>
                    </div>
                  </Button>
                </Box>
              </>
            );
          }}
        </Map>
      </Scroll>
    </Box>)
  })

  return <BaseForm {...props} />

}

function FormWrapper({ children, ...props}){
  let { onActions } = props;

  React.useEffect(function (){
    setTimeout(function (){
      (onActions['Filter']) && onActions['Filter']({ ...props, isOpen: true })
    }, 10)
  }, [])

  return <>
    <>
      {typeof children === 'function' && children(props)}
      {typeof children !== 'function' && children}
    </>
  </>
}

export default function (Component){
  function children(props){
    return <Component {...props} />
  }

  return function (props){
    return <FormWrapper {...props}>{children}</FormWrapper>
  }
}