import ClientModule from '@gqlapp/module-client-react';
import { mapMenu } from "../utils";
import React from "react";

import List from "../icons/List.svg";
import Add from "../icons/add.svg";
import Faq from "../icons/Faq.svg";
import {map} from "../config";

const UserMenus = [

  {
    id: 'custom_page_list',
    name: 'page:page.menu.list',
    weight: 2,
    content: (<List width={'60px'} height={'20px'} />),
    url: map({ path: 'list'})
  },
  {
    id: 'custom_page_add',
    name: 'page:page.menu.add',
    weight: 3,
    content: (<Add width={'60px'} height={'20px'} />),
    onClick:(e, { onActions }) =>{
      onActions && onActions['customPageCreate'] && onActions['customPageCreate']()
    }
  },
  {
    id: 'custom_page_faq',
    name: 'page:page.menu.faq',
    weight: 1003,
    sx: { width: '80px !important' },
    content: (<Faq width={'60px'} height={'20px'} />),
    onClick:(e, { onActions }) =>{

    }
  },
]

  .map((props) =>
  mapMenu({
    ...props,
    sx: { width: '80px !important' },
    routeTypes: ['CustomPageIntro', 'CustomPageList'],
    perms: ['isAdminPage', 'managerPage', 'isAdmin', 'isMember']
  })
)

export default new ClientModule({
  menus:[{ positions: ['bottom.mobile'], items: UserMenus }]
})
