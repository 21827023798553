import { graphql } from 'react-apollo';
import compose from "recompose/compose";
import withProps from "recompose/withProps";
import mapInput from '@gqlapp/base/utils/mapInput';
import QR_TEXT_QUERY from '../graphql/QrTextQuery.graphql';

export const withQrText = (input: any) => (Component: any) =>
  compose(
    withProps((props: any) => mapInput(input, props)),
    graphql(QR_TEXT_QUERY, {
      options: ({ textProps: input }: any) => ({ variables: { input } }),
      props({ data: { loading, text, refetch, error } }: any) {
        return { loading, svgText: text || { metrics: {} }, qrRefetch: refetch, errors: error ? error.graphQLErrors : null };
      }
    })
  )(Component);

export default withQrText;
