import compose from 'recompose/compose';
import withProps from 'recompose/withProps';
import withLoading from '@xem/utils/withLoading';
import USERS_QUERY from '@xem/user-common/graphql/UsersQuery.graphql'
import Entities from '@xem/user-common/classes/Users';
import withUsers from '@xem/user-common/classes/withUsers';
import withUsersDeleting from '@xem/user-common/classes/withUsersDeleting';
import withUsersUpdate from '@xem/user-common/classes/withUsersUpdate';
import onDeletes from "./utils/onDeletes";
import onUpdates from "./utils/onUpdates";
import { getFilterByKey } from "./Filter";
import MediaTools from "./MediaTools";
import 'firebase/auth';

/**
 *
 * @param input
 * @param withPropsInput
 * @param withPropsBefore
 */
export const UsersCompose = (input?: any, withPropsInput?: any[], withPropsBefore?: any[]) => {
	return (Route: any) => {

		const getEntities = function getPath({ query, cookie, status = 'active'}: any) {
			let id = parseInt(query?.id);

			let entities = Entities.instanse({ pageSize: 16, orderBy: 'CREATED', protected: true});

			let orderBy, direction;
			let filter: any = {};

			(cookie) && ({orderBy, direction, status} = getFilterByKey(cookie));

			(!!id) && (Object.assign(filter, { tids: [id] }));
			(!!status) && (Object.assign(filter, {status}));

			(!!orderBy) && (entities.orderBy = orderBy);
			(!!direction) && (entities.direction = direction);

			return entities;
		}


		/**
		 * WithRouteLink
		 */
		const WithRouteLink = compose(
			MediaTools,
			withProps(input),
			withUsersDeleting,
			withUsersUpdate,
			withProps(({ usersDelete, usersUpdate, query, cookie, ...props}) => ({
				onDeletes: onDeletes(usersDelete, props),
				onUpdates: onUpdates(usersUpdate, props),
				entities: getEntities({ query, cookie: cookie?.filter?.key})
			})),
			...(withPropsBefore || []),
			withUsers(({ entities }) => ({ users: entities, entitiesReset: true })),
			...(withPropsInput || []),
			withLoading
		)(Route);


		// @ts-ignore
		WithRouteLink.getInitialProps = async ({req, res, path, query, apolloClient}) => {
			let ctx = {}, variables = {};
			let cookie = getCookie('user_list_sort', { req, res });

			if(cookie){
				Object.assign(ctx, { cookie:{ filter: { key: cookie } } })
			}

			let entities = getEntities({ path, query, cookie })
			variables = entities.variables;

			/** Preload taxonomy **/
			let result = await apolloClient.query({ query: USERS_QUERY, fetchPolicy: 'no-cache', variables });

			/** set cache taxonomy **/
			await apolloClient.writeQuery({ query: USERS_QUERY, variables, data: result?.data });

			return ({...ctx})
		}

		return WithRouteLink;
	};
};

export default UsersCompose;
