import { withApollo } from "react-apollo";
import compose from "recompose/compose";
import withProps from "recompose/withProps";
import loadingProcess from "@gqlapp/base/utils/loading/loading";

/**
 *
 * @param client
 * @param loadbar
 * @param query
 * @constructor
 */
function QueryLoading(client: any, loadbar: any, query: any){
	return async (variables: any, fetchPolicy?: any)=>{
		return await loadingProcess(loadbar, async ()=>new Promise(async resolve => {
			client.query({ query, variables, fetchPolicy: fetchPolicy || 'cache-first'})
				.then(({ data: { res }})=> resolve(res))
				.catch(()=> resolve({}))
		}));
	}
}

export function withContentCallback(callback: any, func: string, QUERY: any, loadbar?: any, fetchPolicy?: any){
	return compose(withApollo, withProps(({ client, loadbar: base })=>{
		let props = {}
		props[func] = async function (vars: any){
			let res = await QueryLoading(client, loadbar || base, QUERY)(vars, fetchPolicy);
			return callback(res)
		}
		return props;
	}))
}

export default function preloadContents(action: string, QUERY: any, fetchPolicy?: any){
	return compose(withApollo, withProps(({ client, onActions, loadbar })=>{
		onActions[action] = async function (vars: any, first?: any, last?: any){

			(first) && await first();
			let res = await QueryLoading(client, loadbar , QUERY)(vars, fetchPolicy);
			(last) && await last(res);
			return res;
		}

		return { onActions }
	}))
}