import loadable from 'next/dynamic';
import { map } from '../helpers/config';
import withProps from "recompose/withProps";
import withRenderTools from "@xem/look/Renders/Tools/withTools";
import withRenderProcess from '@xem/entity/classes/withRenderProcess';
import withFormFilter from '../helpers/links/Filter';
import withOnFilter from '../helpers/links/withOnFilter';
import LinksCompose from "../helpers/links/LinksCompose";

const ListComponent = loadable(
  ()=>import("../components/list/LinksView").then(
    (c) => c.default)
);


export default LinksCompose({},[
  withProps(({ query }) => ({
    pageProps: {
      query, path: map({ path: 'list' }),
      other: map({ path: 'listpage', page: ':page' })
    }
  })),
  withProps(({ entities, setFieldValue })=>({
    refetch: () =>{
      try{ entities.refetch() }catch (e) {}
      try{ setFieldValue('page', 1) }catch (e) {}
    }
  })),
  withFormFilter,
],[
  withRenderTools,  withOnFilter,
  withRenderProcess({ entityType: 'link' })
])(ListComponent);
