import React from 'react';
import withProps from 'recompose/withProps';
import compose from 'recompose/compose';
import Icon from '@gqlapp/base/Icon';
import Scroll from '@gqlapp/base/Scroll';
import Menus from '@gqlapp/core-common/Menus';
import clsx from 'clsx';
import Link from '@xem/look/Link';

import Container from "reactstrap/lib/Container";
import Map from "@gqlapp/base/Item/map";
import processMenu from "../../helpers/utils/processMenu";
import SlideLeft from '../../helpers/icons/SlideLeft.svg';
import SlideRight from '../../helpers/icons/SlideRight.svg';
import BottomSideStyles from "./BottomSide.styles";
import Box from '@mui/material/Box';
import Button from 'reactstrap/lib/Button';


const menuProcess = withProps(({ $self, webType, layout, ...props })=>{
  let navigation = [];
  const submenu = [];

  const menus = new Menus({ ...props, settings: layout });

  try {
    $self.getMenu(`bottom.mobile`).map((value) => {
      if (typeof value !== 'function') {
        processMenu(menus, { ...value, $self }, props);
      } else {
        /** gọi tới callback **/
        value(props, (res) => processMenu(menus, { ...res, $self }, props ));
      }
    });
  } catch (e) {}


  /** add to navigation */
  menus.tree.map(({ isActive, children, ...rest }, index) => {
    if (isActive && children.length) {
      (children || []).map((menu) => submenu.push(menu));
    }
    navigation.push(Object.assign(rest, { isActive, children }));
  });

  return { navigation };
});

const BottomWrapperLayout = compose(BottomSideStyles, menuProcess)
(({ bottomStyle, navigation, className, style, ...props }) => {
	navigation = navigation || [];
	bottomStyle = bottomStyle || {};
  return (<>
    <section
      id={'bottom-toolbar'}
      className={clsx( {'d-block d-md-none': !props?.mobile?.show }, 'd-flex align-items-center text-center text-secondary', 'shadow-large', className)}
      style={{ ...style, position: 'sticky', bottom: '0', width: '100%', zIndex: 999, '--text-secondary': '#212121' }}
    >
      <Container fluid className={'px-0'}>
      <Box sx={(bottomStyle || {})} style={{ width: '100%', height: '100%' }}>
        <Scroll
          none
          className={'justify-content-evenly'}
          style={{ minWidth: '100%'}}
          scrollStart={<SlideLeft />}
          scrollEnd={<SlideRight />}>
          <Map items={(navigation)}>
            {({
                sx, title, id, icon, onClick, className,
                classIconName, content,
                tag, tags, name, url, render }) =>{

              let link = {
                id, title, style, url,
                className: clsx(className, 'animation text-secondary', 'border-0')
              };

              if (typeof onClick === 'function') {
                link = { ...link, color: 'default', onClick };
              }

              const Tags = typeof onClick !== 'function' || tags === 'link' ? Link : Button;

              return (<>
                  <Box
                    sx={sx}
                    style={{ height: 'var(--page-footer-height)'}}
                    className={'col col-auto d-flex justify-content-center'}
                  >
                    {(render && typeof render === 'function') && render(link)}
                    {(render && typeof render !== 'function') && render}
                    {((!render)) && <Tags {...link}>
                      <div className={'d-flex align-items-center'}  style={{ width: '100%',  height: '100%'}}>
                        <div>
                          {icon && (<div style={{ width: '100%'}} className={clsx( classIconName)}><Icon tag={tag}>{icon}</Icon></div>)}
                          {!!content && (<><div style={{ width: '100%'}}  className={clsx('w-100', classIconName)}>{content}</div></>)}
                          {name && <div style={{ width: '100%'}}  className={'button-text text-nowrap text-h6'}>{name}</div>}
                        </div>
                      </div>
                    </Tags>}
                  </Box>
                </>
              );
            }}
          </Map>
        </Scroll>
      </Box>
      </Container>
    </section>
  </>)
});

export default BottomWrapperLayout;
