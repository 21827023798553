import React from 'react';
import loadable from 'next/dynamic';

const RegisterContainer = loadable(
  ()=>import('./Register').then(
    (c) => c.default)
);

export default RegisterContainer;

