import {graphql} from "react-apollo";
import TAXONOMY_DELETE from "../graphql/TaxonomyDelete.graphql";
import loading from "@gqlapp/base/utils/loading";

export const withTaxonomyDeleting = (Component: any) =>
  graphql(TAXONOMY_DELETE, {
    props: ({ mutate, ownProps:{ loadbar } }: any) => ({
      taxonomyDelete: async (id: number) => loading(loadbar, async ()=> mutate({ variables: { id } }))
    })
  })(Component);

export default withTaxonomyDeleting;
