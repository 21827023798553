import React from "react";
import compose from "recompose/compose";
import withOnFilter from "./withOnFilter";
import withStore from "./withStore";
import withProductCreate from "./withProductCreate";
import withTools from "@xem/look/Renders/Tools/withTools";
export const withListProcess = compose(
	withTools,
	withOnFilter,
	withStore,
	withProductCreate
)


export const withRenderProcess = (Component) => ((props)=>
	React.createElement((({ children, onRenders }) => <>
		{ (!!(onRenders && Object.keys(onRenders).length)) && Object.keys(onRenders)
			.map(key => onRenders[key])
			.map((C, key) => <C key={key} />)}
		{children}
	</>), props, React.createElement(Component, props)));

export default compose(withListProcess, withRenderProcess)
