import loadable from 'next/dynamic';
import withProps from "recompose/withProps";
import withRenderProcess from '@xem/entity/classes/withRenderProcess';
import GenCompose from "../helpers/gen/GenCompose";
import FilterWrapper from "../helpers/gen/FilterWrapper";
import popup from '../helpers/popup';
import Form from "../helpers/gen/Form";

const CreateContainer = loadable(
	()=>import('../components/Gen/View').then(
		(c) => c.default)
);

export default GenCompose({},
	[
		withProps(({ entity })=> ({ refetch: func(entity.refetch) })),
		Form, popup, withRenderProcess(), FilterWrapper
	],[]
)(CreateContainer);
