import React from 'react';
import loadable from 'next/dynamic';
import ProductsCompose from "../helpers/ProductsCompose";
import withProps from "recompose/withProps";
import { map } from '../helpers/config';
import withFormFilter from '../helpers/Filter';

const ListContainer = loadable(
  ()=>import('./List').then(
    (c) => c.default)
);

export default ProductsCompose(
	{},
	[
		withProps(({ query }) => ({
			pageProps: { path: map({ path: 'list' }), other: map({ path: 'listpage', page: ':page' }), query },
		})),
		withFormFilter,
		withProps(({ entities, store })=>{
			return ({
				refetch: () =>{
					try{ entities.refetch() }catch (e) {}
				}
			})
		})
	]
)(ListContainer);
