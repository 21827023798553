import compose from "recompose/compose";
import withProps from "recompose/withProps";
import mapInput from "@gqlapp/base/utils/mapInput";
import {graphql} from "react-apollo";
import CUSTOM_PAGES_QUERY from "../graphql/CustomPagesQuery.graphql";

export const withCustomPages = (input: any) => compose(
    withProps((props: any) => mapInput(input, props)),
    withProps(({ customPages: { variables } }: any) => ({ ...variables })),
    graphql(CUSTOM_PAGES_QUERY, {
        options: ({ fetchPolicy, page, pageSize, orderBy, direction, filter }: any) => ({
            variables: { page, pageSize, orderBy, direction, filter },
            fetchPolicy: fetchPolicy || 'cache-and-network',
        }),
        props({
                  ownProps: { customPages },
                  data: { loading, res, updateQuery },
                  data: { fetchMore, subscribeToMore, refetch, error },
              }: any) {

            customPages.refetch = refetch;
            customPages.fetchMore = fetchMore;
            customPages.subscribeToMore = subscribeToMore;
            customPages.updateQuery = updateQuery;

            if (!loading && res) {
                customPages.update(res);
            }

            return { loading, errors: error ? error.graphQLErrors : null }
        }
    })
);

export { CUSTOM_PAGES_QUERY }
export default withCustomPages;