import React from 'react';
import * as PropTypes from 'prop-types';
import Nav from 'reactstrap/lib/Nav';
import withProps from 'recompose/withProps';
import MenuItem from "@gqlapp/base/Menu/MenuItem";
import Menus from "@gqlapp/core-common/Menus";
import clsx from "clsx";
import Link from "@xem/look/Link";
import processMenu from "../../helpers/utils/processMenu";
import Map from "@gqlapp/base/Item/map";


const Navigation = ({ vertical, className, head, navigation, level }) =>  {
  return (<Nav className={className} vertical={vertical}>
    <Map items={navigation}>
      {({ itemProps, onClick, className, title, url, icon, children, isActive })=>{
        return <>
          <MenuItem {...itemProps}>
            <Link to={url} title={title} onClick={onClick} className={clsx(className, 'animation d-flex align-items-center nav-link d-inline', { 'show': isActive }, { 'active': isActive })}>
              { (icon) && <>{icon}</>}
              { title && <span>{`${title}`.t()}</span> }
            </Link>
            {(!!children && !!children.length) && <>
              <div className="collapse">
                <Navigation
                  {...{
                    head,
                    navigation: children,
                    level: level + 1
                  }}
                />
              </div>
            </>}
          </MenuItem>
        </>
      }}
    </Map>
  </Nav>)
};

Navigation.propTypes = {
  head: PropTypes.node,
  vertical    : PropTypes.bool,
  navigation: PropTypes.array,
  className: PropTypes.node,
  level: PropTypes.number
};

Navigation.defaultProps = {
  vertical    : true,
  className : '',
  level     : 1
};

const menuProcess = withProps(({ menu, layout, ...props})=>{
  let navigation = [];
  const menus = new Menus({...props, settings: layout});
  try{
    menu.map((value) => {
      if (typeof value !== 'function') {
        processMenu(menus, value, props);
      } else {
        value(props, (res) => processMenu(menus, res, props));
      }
    })
  }catch (e) {}

  const submenu = [];
  menus.tree.map(({ isActive, children, ...link}, index) => {
    children = children || [];
    if(isActive && children.length){
      children.map((menu)=> submenu.push(menu));
    }
    Object.assign(link, { isActive, children })
    navigation.push(link);
  });
  return { navigation }
});

export default menuProcess(React.memo(Navigation));
