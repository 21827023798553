import React from 'react';
import loadable from 'next/dynamic';
import WebrisksCompose from "../helpers/WebrisksCompose";
import withProps from "recompose/withProps";
import { map } from '../helpers/config';
import withFormFilter from '../helpers/Filter';

const ListContainer = loadable(
  ()=>import('./List').then(
    (c) => c.default)
);

export default WebrisksCompose(
	{},
	[
		withProps(({ query }) => ({
			pageProps: { path: map({ path: 'list' }), other: map({ path: 'listpage', page: ':page' }), query },
		})),
		withFormFilter,
		withProps(({ entities, setFieldValue })=>({
			refetch: () =>{
				try{ entities.refetch() }catch (e) {}
				try{ setFieldValue('page', 1) }catch (e) {}
			}
		}))
	]
)(ListContainer);
