import saveStatus from '@gqlapp/base/utils/saveStatus';

type saveProps = {
  entity: any;
  errorMsg?: any;
  msgCallback?: ()=> any;
}

/**
 * onSave
 * @param actionCreate
 * @param actionUpdate
 * @param props
 */
export const onSave  = (actionCreate: any, actionUpdate: any, props?: saveProps) => async (input: any, rest?: any) => {
  let values: any, { errorMsg, entity, msgCallback } = props || {};

  if(input.id && actionUpdate){
    let id = input.id;
    delete input.id;
    values = await actionUpdate({ id, input });
  }
  else if(actionUpdate && entity.id){
    values = await actionUpdate({ ...rest, id: entity.id, input });
  }else{
    values = await actionCreate({ ...rest, input });
  }

  return saveStatus({ values, msgCallback, errorMsg: errorMsg || 'save.errorMsg'.t() });
};


export default onSave;
