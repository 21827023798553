import { graphql } from "react-apollo";
import QUERY from "../../graphql/formapi/FormApiSubmit.graphql";
import loading from "@gqlapp/base/utils/loading";
import map from "@gqlapp/base/utils/map";

export default graphql(QUERY, {
  props: ({ mutate, ownProps: { loadbar } }: any) => ({
    formSubmit: async (variables: any) =>
      loading(loadbar, async () =>
        map(
          map(await mutate({ variables }), ({ data }: any) => data),
          ({ res }: any) => res
        )
      )
  })
});
