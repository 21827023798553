import { graphql } from 'react-apollo';
import compose from "recompose/compose";
import withProps from "recompose/withProps";
import mapInput from "@gqlapp/base/utils/mapInput";
import statsChartMore from "./Stats/statsChartMore";
import STATS_CHART_QUERY from "../graphql/StatsChart.graphql";


/**
 * withStatsChart
 * @param input
 */
export const withStatsChart = (input: any) => (Component: any) =>
	compose(
		withProps((props: any) => mapInput(input, props)),
		graphql(STATS_CHART_QUERY, {
			skip: ({ skip }: any) => !!skip,
			options: ({ id, to, day, dateType, chartProps }: any) =>{
				return  ({ variables: { id, to, day, dateType, ...chartProps } });
			},
			props({ data: { loading, charts: contents, error, fetchMore }, ownProps, ownProps: { charts } }: any) {
				if (!loading && charts) {
					charts.update(contents);
				}

				return {
					loading, errors: error ? error.graphQLErrors : null,
					statsChartMore: statsChartMore(charts, fetchMore, ownProps)
				};
			}
		})
	)(Component);

export default withStatsChart;
