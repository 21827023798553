import React from 'react';
import withProps from 'recompose/withProps';
import Button from 'reactstrap/lib/Button';
import {shareImg} from "@xem/qr-common";
import {outerHtml} from "@xem/qr-common/utils/util";

export const withTools = withProps((props: any) => {

	return {
		shareFile: (type: string, filename: string) => {
			return new Promise(() => {
				shareImg({ filename, value: 'QR', content: outerHtml(0), width: 1500, height: 1500, type }).then(() => {} )
			});
		},
		openTools: ({ menus, ...val}) => {
			const onClick = (() =>{
				try {
					props?.onActions['Toolbars']({...val, enableClose: true, items: menus, headerProps:{ style: { padding: '7px 0' } } })
				} catch (e) {
					debug('withTools', e);
				}
			})
			return  <Button color={'default'} className={'my-0 p-0'} onClick={onClick}>
				<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M30 0H0V30H30V0Z" fill={'none'}/>
					<path d="M14 22.5C14 22.1022 14.1581 21.7207 14.4395 21.4393C14.7208 21.158 15.1022 21 15.5 21C15.8978 21 16.2792 21.158 16.5605 21.4393C16.8419 21.7207 17 22.1022 17 22.5C17 22.8978 16.8419 23.2793 16.5605 23.5607C16.2792 23.842 15.8978 24 15.5 24C15.1022 24 14.7208 23.842 14.4395 23.5607C14.1581 23.2793 14 22.8978 14 22.5ZM14 15.5C14 15.1022 14.1581 14.7207 14.4395 14.4393C14.7208 14.158 15.1022 14 15.5 14C15.8978 14 16.2792 14.158 16.5605 14.4393C16.8419 14.7207 17 15.1022 17 15.5C17 15.8978 16.8419 16.2793 16.5605 16.5607C16.2792 16.842 15.8978 17 15.5 17C15.1022 17 14.7208 16.842 14.4395 16.5607C14.1581 16.2793 14 15.8978 14 15.5ZM14 8.5C14 8.10218 14.1581 7.72065 14.4395 7.43935C14.7208 7.15804 15.1022 7 15.5 7C15.8978 7 16.2792 7.15804 16.5605 7.43935C16.8419 7.72065 17 8.10218 17 8.5C17 8.89782 16.8419 9.27935 16.5605 9.56065C16.2792 9.84196 15.8978 10 15.5 10C15.1022 10 14.7208 9.84196 14.4395 9.56065C14.1581 9.27935 14 8.89782 14 8.5Z" fill="#212121"/>
				</svg>
			</Button>
		}
	};
});

export default withTools;
