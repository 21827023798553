PLATFORM      = (typeof document !== 'undefined') ? 'web': ((typeof navigator !== 'undefined' && navigator.product === 'ReactNative')? 'mobile': 'server');
__SERVER__    = (typeof window === "undefined");
__window__    = typeof window !== "undefined" ? window : null;
__document__  = typeof document !== "undefined" ? document : null;
__LOGGER_NAME__ = typeof window !== 'undefined' ? 'frontend' : 'backend';
__PORT__        = null;
__SERVER_PORT__ = process.env.SERVER_PORT || process.env.PORT || 3000;
__WEBSITE_URL__ = process.env.WEBSITE_URL || '/graphql';
__API_URL__     = process.env.API_URL || '/graphql';
__API_SERVER__	= process.env.API_SERVER || '/api/graphql';
__DEV__         = process.env.NODE_ENV !== 'production';
__TEST_STRING__ = null;
__TEST__        = process.env.__TEST__ || false;
__SSR__         = process.env.__SSR__ || true;
