import { graphql } from "react-apollo";
import DOMAIN_BLOCK from "../graphql/domainUpdate.graphql";

/**
 *
 * @param Component
 */
export const withDomainBlock = graphql(DOMAIN_BLOCK, {
  props: ({ mutate }) => ({
    domainBlock: async (id: number) => mutate({ variables: { id, input: { status: "blocked" } } })
  })
});

export default withDomainBlock;
