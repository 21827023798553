/* tslint:disable */
import Base from '@xem/entity/classes/Base';


export class Link extends Base{
	status: boolean = false;
	public save(values: any) {
		super.save(values);
		const { status }: any = values || {};
		this.status = !!status;
		return this;
	}
}
export default Link;
