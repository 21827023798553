import React from 'react';
import withProps from "recompose/withProps";
import Notice from "../icons/control/Notice.svg";
import Box from "@mui/material/Box";
export default withProps(({ onActions })=>{

	let confirm = onActions?.confirm || (()=>()=>debug('confirm'));

	function filterConfirm(ids, type, handleSubmit){
		confirm({
			handleSubmit,
			label: <><Notice /></>,
			btnCancel: `link:links.actions.cancel`.t(),
			btnConfirm: [`link:form.${type}.btn.link`, `link:form.${type}.btn.links`]
				.oddeven(ids.length).t({ value: ids.length }),
			children: <>
				<Box sx={{ width: '100%'}} className={'d-flex justify-content-center'}>
					<Box>
            <span>{[`link:popup.${type}.link`, `link:popup.${type}.links`]
							.oddeven(ids.length).t({ value: ids.length })}</span>
					</Box>
				</Box>
			</>
		})()
	}

	return { filterConfirm }
})