import React from "react";
import compose from "recompose/compose";

import blocks from "./blocks";
import Tool from "./settings/tools";
import AppTool from "./settings/app";
import Icon from "./settings/Icon";
import Radius from "./settings/Radius";
import Shadow from "./settings/Shadow";
import Offset from "./settings/Offset";
import Submit from "./settings/Submit";
import Image from "./settings/Image";
import FormSettings from "./settings/Form";
import AddonsSettings from "./settings/addons";
import onChangeAlt from './settings/alt';

export const withRenderProcess = (Component: any) => ((props: any)=>{
	return React.createElement((({ children, dataRenders }) => <>
		{ (!!(dataRenders && Object.keys(dataRenders).length)) && Object.keys(dataRenders)
			.map(key => dataRenders[key])
			.map((C, key) => <C key={key}  />)}
		{children}
	</>), props, React.createElement(Component, props))
});

/** Hàng này không thể xoá */
export default compose(
	blocks, Offset, Shadow, Radius, AppTool, Tool, Icon, onChangeAlt,
	Submit, Image, FormSettings, AddonsSettings, withRenderProcess
);
