import TAXONOMY_TAGS from '../graphql/TaxonomyTags.graphql';
import removeTypename from '@gqlapp/base/utils/removeTypename';

/**
 * searchTags
 * @param t
 * @param subscribeToMore
 * @returns {function(*=): Promise<any>}
 */
export const searchTags = (subscribeToMore: any) => ({ data, keys }: { data: any[]; keys: string }) =>
  new Promise(async resolve => {
    data = data || [];
    try {
      await subscribeToMore({
        document: TAXONOMY_TAGS,
        variables: { filter: { search: { keyword: keys.trim() } } },
        updateQuery: async (_: any, { subscriptionData: { data: contents } }: any) => {
          try {
            const { tags: { edges }} = removeTypename(contents);
            if (edges.length) {
              edges.map(({ name: label, id: value }: any) => data.push({ label, value }));
            }
            resolve(data);
          } catch (e) {
            resolve(data);
          }
        }
      });
    } catch (e) {
      resolve(data);
    }
  });

export default searchTags;
