import React from 'react';
import menu from './helpers/menu';
import Popup from './helpers/popup';
import resources from './locales';
import ClientModule from "@gqlapp/module-client-react";
import { DomainRoute, map } from "./helpers/config";
import Route from "@xem/router/classes/Route";
import List from './containers/ListContainer';

// @ts-ignore
let getInitialProps = List?.getInitialProps;

export default new ClientModule(Popup, menu, {
  route: {
    DomainList : <Route
      runtimejs
      {...DomainRoute}
      {...{
        component: List,
        webType: 'cpanel',
        path: map({ path: 'list' }),
        getInitialProps
      }}
    />
  },
  localization: [{ ns: 'domain', resources, more: ['common'] }]
});
