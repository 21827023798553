import compose from 'recompose/compose';
import withProps from 'recompose/withProps';
import withLoading from '@xem/utils/withLoading';
import Entities from '@xem/gen-common/classes/Gens';
import withGens, { GENS_QUERY } from '@xem/gen-common/classes/withGens';
import withGensDeleting from '@xem/gen-common/classes/withGensDeleting';
import onDeletes from "../utils/onDeletes";
import { getFilterByKey } from "./Utils";
import setCache from '@xem/entity/classes/setCache';
/**
 *
 * @param input
 * @param withPropsInput
 * @param withPropsBefore
 */
export default (input?: any, withPropsInput?: any[], withPropsBefore?: any[]) => {
	return (Route: any) => {

		function getEntities({ query, cookie, status = 'active'}: any) {
			let id = parseInt(query?.id);

			let entities = Entities.instanse({ pageSize: 16, orderBy: 'CHANGED', protected: true });

			let orderBy: string, direction: string, filter: any = {};

			(cookie) && ({orderBy, direction, status} = getFilterByKey(cookie));

			(!!id) && (Object.assign(filter, { tids: [id] }));
			(!!status) && (Object.assign(filter, {status}));

			(!!orderBy) && (entities.orderBy = orderBy);
			(!!direction) && (entities.direction = direction);

			return entities;
		}


		/**
		 * WithRouteLink
		 */
		const WithRouteLink = compose(

			withProps(input),
			withGensDeleting,
			withProps(({ gensDelete, query, cookie, ...props}) => ({
				onDeletes: onDeletes(gensDelete, props),
				entities: getEntities({ query, cookie: cookie?.filter?.key})
			})),
			...(withPropsBefore || []),
			withGens(({ entities }) => ({ gens: entities })),
			...(withPropsInput || []),
			withLoading
		)(Route);

		const bindCache = async (apolloClient: any, query: any, obj: any)=> {
			let entities = getEntities(obj)
			let variables = entities.variables;

			/** set cache*/
			await setCache({ query, variables, apolloClient })
		}




		// @ts-ignore
		WithRouteLink.getInitialProps = async ({ apolloClient}) => {
			let ctx = {};
			await bindCache(apolloClient, GENS_QUERY, { ...ctx})
			return ({...ctx})
		}

		return WithRouteLink;
	}
}
