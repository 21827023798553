import { graphql } from "react-apollo";
import DOMAIN_BLOCK from "../graphql/domainUpdate.graphql";

/**
 *
 * @param Component
 */
export const withDomainNonePublic = graphql(DOMAIN_BLOCK, {
  props: ({ mutate }) => ({
    domainSetNonePublic: async (id: number) => mutate({ variables: { id, input: { public: false } } })
  })
});

export default withDomainNonePublic;
