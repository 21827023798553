import React from 'react';
import withProps from 'recompose/withProps';
import Popup from "@gqlapp/base/Modal/Popup";
import {createPortal} from "react-dom";
import { FormFilter } from './Filter';
const Modal = ({ isOpen, append, ...props })=>{
  /**
   * append element
   */
  if(typeof append !== 'object'){
    if(typeof append === 'string'){
      append = $$(append);
    }else{
      append = $$('body > #__next > #switcher');
    }
  }

  return <>{isOpen && append && createPortal(React.createElement(FormFilter, props), append)}</>
}
const DataConfirm = (({ isOpen, ...props }) => {
  return (<>{!__SERVER__ && isOpen && <Modal {...{ ...props, isOpen }} />}</>)
});

export const withOnFilter = withProps((props) => {
  let ref = React.createRef();
  let onRenders = props?.onRenders || {}
  let onActions = props?.onActions || {}

  let Render = (rest) => (
    <Popup
      {...props}
      {...rest}
      ref={ref}
      component={DataConfirm}
    />
  );

  let Action = (val) => {
    // debug({ val })
    try { ref.current.open(val); } catch (e) {}
  }

  Object.assign(onActions, {
    Filter: Action
  })

  Object.assign(onRenders, {
    Filter: Render
  })

  return {
    onRenders,
    onActions
  }
});

export default withOnFilter;
