import React from 'react';
import ClientModule from '@gqlapp/module-client-react';
import Row from "@gqlapp/base/Row";
import Col from "@gqlapp/base/Col";
import IconPage from '../icons/Page.svg'

const OtherMenus = [
  {
    id: 'other',
    weight: 1,
    type: 'group',
    // @ts-ignore
    label: 'Other',
    wrapper: (props)=><Row {...props} medium />,
  }
];

[
  { id: 3000,
    icon: <IconPage width={'50px'} height={'48px'} />,
    title: 'BIO page', type: 'item',
    handleClick: (e, props)=>{
      historyPush('/pages.html');
    }
  }
]
  .map(({ weight, ...value }, index)=>({
    ...value,
    weight: weight || index,
    type: 'item',
    parent: [{ targetId: 'other' }],
    wrapper: (props)=><Col {...props} xs={6} />,
  }))
  .map(value=>OtherMenus.push(value))

export default new ClientModule({
  menus: [
    { positions: ['menu.mobile'], items: OtherMenus }
  ]
});
