import React from "react";
import dynamic from "next/dynamic";
import compose from "recompose/compose";
import renderCallback from "@xem/entity/classes/renderCallbackR";

const Form = dynamic(() => import('./Form').then((c) => c.default));

const DataForm = ((props) => (
	<>{!__SERVER__ && (!!props?.isOpen) && <><Form {...props} /></>}</>));

let refCreate = React.createRef();
let refUpdate = React.createRef();
export default compose(
	renderCallback(refCreate, 'gen.create', DataForm, { title: `gen:form.gen.create`.t() }),
	renderCallback(refUpdate, 'gen.update', DataForm, { title: `gen:form.gen.update`.t() })
)

