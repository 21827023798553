/**
 * Get Value
 * @param opts
 * @param val
 */
export const opt = (opts = [], val) => {
  const $opts = [];
  // tslint:disable-next-line:no-shadowed-variable
  opts.map(opt => $opts.push(opt));
  if (typeof val !== 'object') {
    val = $opts.filter(({ value }) => val === value).shift();
  }
  val = val || $opts.shift();
  return val;
};

export default opt;