import saveStatus from '@gqlapp/base/utils/saveStatus';

/**
 * Update multiple elements
 * @param Save
 */
export const onUpdates = (Save: any, {}: any) => async (variables: any) => {
  let values = await Save(variables);
  // @ts-ignore
  return saveStatus({ values, errorMsg: 'link:update.errorMsg'.t()});
};

export default onUpdates;
