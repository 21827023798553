import { withFormik } from "formik";
import withProps from "recompose/withProps";
import compose from "recompose/compose";
import Box from "@mui/material/Box";
import React from "react";
import Row from "@gqlapp/base/Row";
import Col from "@gqlapp/base/Col";
import Radio from "@mui/material/Radio";
import Style from './Filter.Styles';

export const btnProps = {
  outline: true,
  className: 'rounded-0',
  color: 'secondary',
}


function getFilterByKey(key: string){
  let orderBy = 'CREATED';
  let direction = 'DESC';
  let status = 'active';


  return { status, direction, orderBy };
}


let timeoutKeyword = null;

export default compose(
  Style,
  withFormik({
    mapPropsToValues: function mapPropsToValues({ values }: any){
      return ({ ...values, onSelect: 0 })
    },
    handleSubmit: function (){},
    enableReinitialize: true,
    displayName: 'FilterForm',
  }),
  // withProps(({ entities, getMore })=>debug({
  //   entities
  // })),

  withProps(({ entities, setFieldValue, loadbar })=>({
    getMore: function getMore(page: number & string, reset: boolean = false) {
      entities.page = page;
      if(reset){ entities.update(null, true); }
      return entities
        .fetchMore(entities.variables, loadbar)
        .then((res: any)=>entities.add(res))
        .then(()=>setFieldValue('page', page));
    }
  })),
  withProps(({ entities, getMore })=>({
    handlePageChange: async function handlePageChange (page: number & string) {
      if (page !== entities.page) {
        await getMore(page);
      }
    }
  })),

  withProps(({ entities, getMore, setFieldValue })=>({
    onSelectChange: async function onSelectChange(key: string){
      setFieldValue('defaultKey', key);
      let { orderBy, direction, status } = getFilterByKey(key);
      entities.page = 1;
      entities.orderBy = orderBy;
      entities.direction = direction;
      entities.filter = { status };

      setCookie('gen_list_sort', key)
      await getMore(1, true);
    }
  })),
  withProps(({ entities, getMore, setFieldValue, ...props })=>({
    onKeywordChange: async function (keyword: string){
      keyword = keyword || ``;
      entities.page = 1;
      entities.filter = { search: [{ keyword, type: 'all' }] }
      clearTimeout(timeoutKeyword);
      timeoutKeyword = setTimeout( async () => {
        setFieldValue('keyword', keyword);
        await getMore(1, true);
      }, 500);
    }
  })),
  withProps(({ values })=>{
    let defaultKey = values?.defaultKey || 'latest';

    const map = (({ title, key, ...rest})=>{
      return {
        ...rest,
        handleClick:()=> {
          // onSelectChange(key);
        },
        title: () => (<Row none>
          <Col xs={'auto'}><Radio checked={(key === defaultKey)} /></Col>
          <Col className={'d-flex align-items-center'}>{title}</Col>
        </Row>)
      }
    });

    const viewMenus = [
      { key: `viewsLatest`,      title: 'link:views.latest'.t() },
      { key: `viewsMost`,        title: 'link:views.most'.t()},
      { key: `viewsQrcode`,           title: 'link:views.qrcode'.t()},
      // { key: `viewsLeast`,       title: `link:views.least`.t()},
    ].map(value=> map(value));

    const titleMenus = [
      { key: `az`,          title: `link:views.az`.t()},
      { key: `za`,          title: 'link:views.za'.t() }
    ].map(value=> map(value));

    const shortedMenus = [
      { key: `latest`,       title: 'link:shorted.latest'.t() },
      { key: `longest`,      title: 'link:shorted.longest'.t() },
      { key: `unpublished`,      title: 'link:filter.actions.unpublished'.t() },
    ].map(value=> map(value));
    return { viewMenus, titleMenus, shortedMenus }
  }),
  withProps(({ values: { onSelect }, onActions, viewMenus, titleMenus, shortedMenus, setFieldValue })=>({
    onClickType : function onClickType(type: string){
      return function (){
        if(([0, 2].includes(onSelect)) && (type === 'delete')){
          setFieldValue('onSelect', 1)
        }
        else if(([0, 1].includes(onSelect)) && (type === 'hide')){
          setFieldValue('onSelect', 2)
        }else{
          setFieldValue('onSelect', 0)
        }
      }
    },
    onClick: function onClick(){
      (onActions && onActions['Toolbars']) && onActions['Toolbars'](
        {
          enableClose: true,
          items: [ viewMenus, titleMenus, shortedMenus ].map(function (items){
            return ({
              items , type: 'group',
              wrapper: ({ children })=><Box className={'btn-group-vertical'} sx={{ '&':{ width: '100%', '&> .btn': { padding:0}} }}>{children}</Box>,
            })
          }),
          headerProps:{ style: { padding: '7px 0' } },
          title: 'label.links.filter'.t() })
    }
  }))
);
