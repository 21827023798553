const svgHead = "<?xml version=\"1.0\" encoding=\"utf-8\"?>\n " +
  "<!DOCTYPE svg PUBLIC \"-//W3C//DTD SVG 20010904//EN\" \"http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd\">\n"

const MIME = { "jpg": "image/jpeg", "png": "image/png" };

function cloneSvg({ content, width, height }){
    width  = width || '1000px';
    height = height || '1000px';

    const wrap = document.createElement('div');
    wrap.innerHTML = content;

    const $svg = wrap.firstChild
    const $clone = $svg.cloneNode(true);

    $clone.setAttribute('width', width);
    $clone.setAttribute('height', height);
    const svgData = new XMLSerializer().serializeToString($clone);
    return { svgData }
}

export function saveSvg({ filename, value, content, width, height }) {
    const { svgData } = cloneSvg({ content, width, height })
    let htmlContent = [svgHead + svgData]

    let bl = new Blob(htmlContent, {type: "image/svg+xml"})
    let a = document.createElement("a")
    filename = filename || `QRcode_${value}`
    filename = `${filename}.svg`.replace('/','')

    a.href = URL.createObjectURL(bl)
    a.download = filename
    a.hidden = true
    a.click()
}


export function saveImg({ filename, value, content, width, height, type }) {
    if (!MIME[type]) throw "Error image type";

    const { svgData } = cloneSvg({ content, width, height })

    // Finish creating downloadable data
    filename = filename || `QRcode_${value}`
    filename = `${filename}.${type}`.replace('/','')

    let canvas = document.createElement('canvas');

    // View will be scaled to the requested size.
    // var size = data.requestedSize;
    canvas.setAttribute('width', width);
    canvas.setAttribute('height', height);

    let ctx = canvas.getContext('2d');
    let img = document.createElement('img');
    img.setAttribute('src', 'data:image/svg+xml;base64,' + btoa(svgData));

    return new Promise(resolve => {
        img.onload = () => {
            ctx.fillStyle = 'white';
            if (type === 'jpg') ctx.fillRect(0, 0, width, height);
            ctx.drawImage(img, 0, 0, width, height);
            // `download` attr is not well supported
            // Will result in a download popup for chrome and the
            // image opening in a new tab for others.

            let a = document.createElement('a');
            let data = canvas.toDataURL(MIME[type], 0.8);
            a.setAttribute('href', data)
            a.setAttribute('target', 'download')
            a.setAttribute('download', filename);
            a.click();

            resolve(data)
        };
    })
}


export function saveEps(value, content, width, height) {
    return saveImg(value, content, width, height, "eps");
}



export function shareImg({ filename, value, content, width, height, type }) {
    if (!MIME[type]) throw "Error image type";

    const { svgData } = cloneSvg({ content, width, height })

    // Finish creating downloadable data
    filename = filename || `QRcode_${value}`
    filename = `${filename}.${type}`.replace('/','')

    let canvas = document.createElement('canvas');

    // View will be scaled to the requested size.
    // var size = data.requestedSize;
    canvas.setAttribute('width', width);
    canvas.setAttribute('height', height);

    let ctx = canvas.getContext('2d');
    let img = document.createElement('img');
    img.setAttribute('src', 'data:image/svg+xml;base64,' + btoa(svgData));

    return new Promise((resolve, reject) => {
        img.onload = async () => {
            ctx.fillStyle = 'white';
            if (type === 'jpg') ctx.fillRect(0, 0, width, height);
            ctx.drawImage(img, 0, 0, width, height);
            // `download` attr is not well supported
            // Will result in a download popup for chrome and the
            // image opening in a new tab for others.
            try{
                let data = canvas.toDataURL(MIME[type], 0.8);
                const blob = await (await fetch(data)).blob();
                const file = new File([blob], filename, { type: blob.type });

                await navigator.share({
                    title: filename,
                    files: [file],
                })
                resolve(`qr:share.done`.t())
            }catch (e) {
                // reject(e)
            }

        };
    })
}


export function getImg(value, content, width, height, type, scale = 75) {
    if (!MIME[type]) throw "Error image type";

    const { svgData } = cloneSvg({ content, width, height })

    // Finish creating downloadable data
    let filename = "QRcode_" + value + "." + type;

    let canvas = document.createElement('canvas');

    // View will be scaled to the requested size.
    // var size = data.requestedSize;
    canvas.setAttribute('width', width);
    canvas.setAttribute('height', height);

    let ctx = canvas.getContext('2d');
    let img = document.createElement('img');
    img.setAttribute('src', 'data:image/svg+xml;base64,' + btoa(svgData));

    return new Promise(resolve => {
        img.onload = () => {
            ctx.fillStyle = 'white';
            if (type === 'jpg') ctx.fillRect(0, 0, width, height);
            ctx.drawImage(img, scale, scale, width - scale * 2, height - scale * 2);

            // `download` attr is not well supported
            // Will result in a download popup for chrome and the
            // image opening in a new tab for others.

            let a = document.createElement('a');
            let data = canvas.toDataURL(MIME[type], 0.8);
            resolve(data)
        };
    })
}




const formatToMimeMap = new Map()
  .set('ico', 'image/vnd.microsoft.icon')
  .set('jpg', 'image/jpeg')
  .set('svg', 'image/svg+xml')
  .set('tif', 'image/tiff');

const formatToMime = (format) => {
    format = format.toLowerCase();
    return formatToMimeMap.get(format) || `image/${format}`;
};

const serializeSVG = (svg) => {
    const wrap = document.createElement('div');
    wrap.innerHTML = svg;
    const $svg = wrap.firstChild
    const $clone = $svg.cloneNode(true);
    return new XMLSerializer().serializeToString($clone);
};

const encodeSvgToUrl = (svg) => {
    return 'data:image/svg+xml;base64,' + btoa(svg);
}

// svg ~> image ~> canvas
const svgToCanvas = async (svg, img, strip) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (ctx === null) {
        throw new Error('context of canvas should not be null');
    }

    // encoded svg to url
    const url = encodeSvgToUrl(svg);
    // write data url to image
    img.setAttribute('src', url);

    // wait for the image to load (or fail)
    await new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = reject;
    });

    const box = strip ? svg.getBBox() : svg.getBoundingClientRect();

    canvas.width = box.width;
    canvas.height = box.height;

    // draw sub-image into canvas
    ctx.drawImage(img, box.x || 0, box.y || 0, box.width, box.height, 0, 0,
      box.width, box.height);

    return canvas;
};

// img is optional
const svgToUrl = async (svg, type, img) => {
    if (type === formatToMime('svg')) {
        return encodeSvgToUrl(svg);
    }

    const canvas = await svgToCanvas(svg, img || document.createElement('img'));

    const url = canvas.toDataURL(type);

    /* HTMLCanvasElement.toDataURL():
		 * If the height or width of the canvas is 0 or larger than
		 * the maximum canvas size,  the time "data:," is returned.
		 */
    if (url === 'data:,') {
        throw new Error('Either one dimension if SVG is zero or SVG is too big to fit into canvas');
    }

    /* HTMLCanvasElement.toDataURL():
		 * If the requested type is not image/png, but the returned value
		 * starts with data:image/png, then the requested type is not supported.
		 */
    const png = formatToMime('png');
    if (type !== png && url.startsWith(`data:${png}`)) {
        throw new Error(`Type \u201c${type}\u201d is not supported`);
    }

    return url;
};

// img is optional
export const svgToBlob = async (svg, type, img) => {
    const blob = new Blob([svg], { type: "image/svg+xml" });
    const url = URL.createObjectURL(blob);
    const image = document.createElement("img");
    image.addEventListener("load", () => URL.revokeObjectURL(url), {
        once: true
    });

    image.src = url;

    console.log(image.src);
    console.log(url); // Check the URL in the browser, including blob:,
// // it should display fine.
//
//     ctx.drawImage(image, 0, 0, 100, 100); // I don't get why it doesn't
// // ctx.restore();
}

