
class SaveStatus {
  static instanse(values: any): SaveStatus {
    return new this(values);
  }

  _errors = [];

  constructor({ values, ...props }: any) {
    const { errors, violations } = values || {};
    if (errors && errors.length) {
      throw this.throwError(errors, props);
    }

    if (violations && violations.length) {
      throw this.throwError(violations, props);
    }
    return values;
  }

  toString() {
    return this._errors.join('\n');
  }

  throwError(values: any, { errorMsg, msgCallback }) {
    // debug('throwError', values)
    return values.reduce(
      (res: { [x: string]: any; toString: () => string; }, error: { message: any; field: string | number; }) => {
        let message: string;
        try {
          message = typeof error === 'string' ? error : error.message;
        } catch (e) {}

        if(msgCallback && message){
          message = msgCallback(message);
        }else{
          message = `${message}`.t();
        }

        this._errors.push(message);
        if (error && error.field) {
          res[error.field] = message;
          res.toString = () => this.toString();
          return res;
        }

        return { errorMsg: message, toString: () => this.toString() };
      },
      { errorMsg }
    );
  }
}

export const saveStatus = (props: any): SaveStatus => SaveStatus.instanse(props);
export default saveStatus;
