import Action from '@xem/entity/classes/Action';

const domain = {
  list: '/domains.html',
  listpage: '/domains/:page/page.html',
};

export const map = ({ id, page, path }: any) => `${domain[path]}`.replace(/:id/, id).replace(/:page/, page);


const perms = ['isMember'];
const styleConfigs = { style: { '--button-bar-height': '50px' } };
import settings from '@xem/config';

export const defaultRoute = {
  ...settings.route,
  action: Action.instanse(),
  actionFilter: Action.instanse()
};


export const DomainRoute = {
  ...defaultRoute,
  ...styleConfigs,
  runtimejs: true,
  perms,
  exact: true,
  webType: 'domain',
  mobile: { show: true },
  classNameOnPage: 'cpanel theme-mobile'
};

export default domain;
