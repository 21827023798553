const onSubmit = function onSubmit(onSave: any, entity: any, refetch: any){
	return function onSubmit({ qr_default, ...qr }, { onClose }) {
		onSave({id: entity?.id, qr, qr_default})
			.then(() => {
				refetch && refetch();
				onClose && onClose()
			})
			.catch(errorCatch);
	}
}
export default onSubmit;