import CustomPage from './CustomPage';
import Entities from '@gqlapp/core-common/Entities';
import more from '@xem/entity/classes/fetchMore'
export class CustomPages extends Entities {
  public _fetchMore: any = null;
  public ownDomain: boolean = false;
  public notOwnDomain: boolean = false;

  constructor(props: any = {}) {
    super(props);
    this.interface = CustomPage;
  }

  set fetchMore(value: any){
    this._fetchMore = value;
  }

  get fetchMore(){
    return more(this._fetchMore);
  }

  get filter() {
    if(this.ownDomain){
      this._filter = { ...this._filter, ownDomain: this.ownDomain }
    }
    if(this.notOwnDomain){
      this._filter = { ...this._filter, notOwnDomain: this.notOwnDomain }
    }
    return this._filter || null;
  }

  set filter(value) {
    this._filter = value;
  }

  /**
   * instanse
   */
  public static instanse(props: any = {}) {
    return new this(props);
  }
}

export default CustomPages;