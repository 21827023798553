import { graphql } from 'react-apollo';
import compose from "recompose/compose";
import withProps from "recompose/withProps";
import mapInput from "@gqlapp/base/utils/mapInput";
import statsChannelMore from "./Stats/statsChannelMore";
import STATS_CHANNEL_QUERY from "../graphql/StatsChannel.graphql";

/**
 *
 * @param input
 */
export const withStatsChannel = (input?: any) => (Component: any) =>
	compose(
		withProps((props: any) => mapInput(input, props)),
		graphql(STATS_CHANNEL_QUERY, {
			skip: ({ skip }: any) => !!skip,
			options: ({ id, dateType, channelProps }: any) => ({ variables: { id: id || null, dateType, ...channelProps } }),
			props({ data: { loading, res, error, fetchMore }, ownProps, ownProps: { stats } }: any) {
				// stats.fetchChannels = fetchMore;
				if (!loading) {
					stats.channels = res;
				}
				return { loading, statsChannelMore: statsChannelMore(stats, fetchMore, ownProps), errors: error ? error.graphQLErrors : null };
			}
		})
	)(Component);

export default withStatsChannel;
