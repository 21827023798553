import React  from 'react';
import clsx from 'clsx';
import View from '@gqlapp/base/View';
import Base from 'reactstrap/lib/Container';
import CreateForm from './Create';
import Home from './Home';
import HomeLinks from './HomeLinks';
import HomeSection from './HomeSection';
import Link from "@xem/look/Link";

const Container = ({ className, style, ...props}) =>
	<Base {...props} className={clsx(className)} fluid style={{ ...style, maxWidth: '1220px', margin: '0 auto' }} />

const HomeView = ({ links, ...props }) => {

	return (<>
		<View id={'home-main'}>
			<div style={{ backgroundColor: '#3578E5'}} className={'pb-3'}>
				<Container>
					<>
						<div className={'text-center'}>
							<svg id={'logo-top'} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="579.724" viewBox="0 0 579.724 98">
								<defs>
									<filter id="xem-link" x="0" y="10.281" width="280.755" height="87.719" filterUnits="userSpaceOnUse">
										<feOffset dy="3" input="SourceAlpha"/>
										<feGaussianBlur stdDeviation="3" result="blur"/>
										<feFlood floodOpacity="0.161"/>
										<feComposite operator="in" in2="blur"/>
										<feComposite in="SourceGraphic"/>
									</filter>
									<filter id="link-link" x="300.061" y="0" width="279.662" height="97.997" filterUnits="userSpaceOnUse">
										<feOffset dy="3" input="SourceAlpha"/>
										<feGaussianBlur stdDeviation="3" result="blur-2"/>
										<feFlood floodOpacity="0.161"/>
										<feComposite operator="in" in2="blur-2"/>
										<feComposite in="SourceGraphic"/>
									</filter>
									<filter id="Ellipse_34-link" x="270.412" y="50.801" width="39.903" height="39.903" filterUnits="userSpaceOnUse">
										<feOffset dy="3" input="SourceAlpha"/>
										<feGaussianBlur stdDeviation="3" result="blur-3"/>
										<feFlood floodOpacity="0.161"/>
										<feComposite operator="in" in2="blur-3"/>
										<feComposite in="SourceGraphic"/>
									</filter>
								</defs>
								<g id="logo_xem_link" data-name="logo xem link" transform="translate(15.383 -49.418)">
									<g transform="matrix(1, 0, 0, 1, -15.38, 49.42)" filter="url(#xem-link)">
										<path id="xem-2" data-name="xem" d="M51.573,84.291,81.315,54.5H60.107L41.039,73.6,21.972,54.5H.617L30.361,84.293.618,113.942H21.973l19.064-19.1,19.063,19.1H81.313ZM91.692,54.5v59.444h55.615l13.3-13.3h-50.98V90.366h32.343l12.09-12.09H109.626V67.8h39.9l13.2-13.3Zm171.678,0H243.1L217.453,89.952,191.673,54.5H171.484v59.444h17.934V75.959l22.165,30.629H223.17l22.268-30.629v37.984l17.934,10.277V54.5Z" transform="translate(8.38 -38.22)" fill="#fff" fillRule="evenodd"/>
									</g>
									<g transform="matrix(1, 0, 0, 1, -15.38, 49.42)" filter="url(#link-link)">
										<path id="link-2" data-name="link" d="M429.545,96.841V40.421L411.611,50.783v59.356h55.615l13.3-13.3Zm73.85-56.421L485.461,50.783v59.258l17.934.1V40.418Zm86.745,0L572.206,50.811V86.22L529.043,50.7H516.49v59.341l17.934.1V75.484l42.114,34.657h13.6V40.418ZM652,50.7,621.069,79.917V50.7H603.135v59.341l17.934.1v-9.577L640.2,82.49,653.411,95.7v24.717l17.934-10.176V93.117L650.61,72.383,673.273,50.7Z" transform="translate(-102.55 -34.42)" fill="#fff" fillRule="evenodd"/>
									</g>
									<g transform="matrix(1, 0, 0, 1, -15.38, 49.42)" filter="url(#Ellipse_34-link)">
										<circle id="Ellipse_34-2" data-name="Ellipse 34" cx="10.951" cy="10.951" r="10.951" transform="translate(279.41 56.8)" fill="#fff"/>
									</g>
								</g>
							</svg>
						</div>
						<div className={'text-center pb-2'}>
							<svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 0 402 25">
								<text transform="translate(0 21)" fill="#fff" fontSize="21" fontWeight="700"><tspan x="0" y="0">Thấu hiểu khách hàng qua từng đường link</tspan></text>
							</svg>
						</div>
						<Home />
						<div className={'w-100 home-face pb-1 pb-md-3'}>
							<CreateForm {...{ ...props, className: 'shorten-input m-0 rounded-1 rounded-md-2'}} />
						</div>
					</>
				</Container>
			</div>

			<div className={'bg-white py-1 py-md-0'} style={{'--text-dark': '#686868', '--link-color': '#686868'}}>
				<Container style={{ margin: 'auto', height: '36px' }} className={'d-flex align-items-center justify-content-center'}>
				<span className={'text-dark text-center text-h5 text-md-h4'}>
					{'site.note'.t().r({
						'@break': (key) => <br key={key} className={'d-md-none'} />,
						'@rule': (key)=><Link key={key} to={'/terms-of-service.html'} ><strong>{'site.terms-of-service'.t()}</strong></Link>,
						'@policy' : (key)=><Link key={key} to={'/policy.html'}><strong>{'site.policy'.t()}</strong></Link>
					})}
				</span>
				</Container>
			</div>
			{
				(!!(links || []).length) && <Container className={'px-0 px-md-2'}>
					<HomeLinks {...{...props, links }} />
				</Container>
			}

			{/*<HomeSection />*/}
		</View>
	</>);
}

export default HomeView;
