import compose from "recompose/compose";
import withRenderTools from "@xem/look/Renders/Tools/withTools";
import withDomainAdd from "./withDomainAdd";
import withDomainRootAdd from "./withDomainRootAdd";
import withDomainVerifyBrand from "./withDomainVerifyBrand";
import withDomainVerifyRoot from "./withDomainVerifyRoot";
import withDomainRedirect from "./withDomainRedirect";
import withDomainSSL from "./withDomainSSL";
import withOnFilter from "./withOnFilter";

/**
 *
 * @type {ComponentEnhancer<unknown, unknown>}
 */
export default compose(
	withRenderTools,
	withDomainAdd,
	withDomainRootAdd,
	withDomainVerifyBrand,
	withDomainVerifyRoot,
	withDomainRedirect,
	withDomainSSL,
	withOnFilter
)