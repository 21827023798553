// @ts-ignore
import Charts from "./Charts";
import loading from "@gqlapp/base/utils/loading";

/**
 *
 * @param charts
 * @param fetchMore
 * @param loadbar
 */
export const statsChartMore = (charts: Charts, fetchMore: any, { loadbar }: any) => async (variables: any, bar?: any) =>
  new Promise(async (resolve, reject) => loading((bar || loadbar), async ()=>{
    try{
      await fetchMore({
        variables,
        updateQuery: async (_: any, { fetchMoreResult: { charts: res } }: any) => {
          try { charts.update(res); } catch (e) {error(e)}
          resolve(res);
        }
      });
    }catch (e) {
      error(e);
      reject({});
    }
  }));

export default statsChartMore;
