import loadable from 'next/dynamic';
import CustomPagesCompose from "../helpers/pages/CustomPagesCompose";
import withProps from "recompose/withProps";
import onSave from "@xem/entity/classes/onSave";
import withRenderProcess from '@xem/entity/classes/withRenderProcess';

import withCustomPageCreate from "@xem/custom-page-common/classes/withCustomPageCreate";
import withCustomPageUpdate from "@xem/custom-page-common/classes/withCustomPageUpdate";

import withPopupCreate from "../components/form/action/create";
import withPopupEdit from "../components/form/action/edit";
import withPopupConfig from "../components/form/action/config";
import blocks from "../components/form";
import { map } from '../helpers/config';
import withFormFilter from '../helpers/Filter';

const ListContainer = loadable(
	()=>import('../components/CustomPagesView').then(
		(c) => c.default)
);


export default CustomPagesCompose({}, [
	withProps(({ pageCreate, pageUpdate, entity, query }) => ({
		pageProps: {
			path: map({ path: 'list' }),
			other: map({ path: 'listpage', page: ':page' }), query },
	})),
	withFormFilter,
],[
	withCustomPageCreate,
	withCustomPageUpdate, blocks,
	withProps(({ pageCreate, pageUpdate, entity, query }) => ({
		onSave	: onSave(pageCreate, pageUpdate,{ entity })
	})),
	withPopupConfig, withPopupCreate, withPopupEdit,
	withRenderProcess()
])(ListContainer);