import isArray from "@gqlapp/base/utils/isArray";
type typeOptions ={
  idKey?: string;
  parentKey?: string;
  childrenKey?: string;
}

export class Menus {
  public props: any;
  private entities: any[] = [];

  constructor(props:any = {}){
    this.props = props;
  }
	
	
	/**
	 * instanse
	 */
	public static instanse(props?:any) {
		return new this(props);
	}
	
  /**
   * get Links
   */
  get links() {
    return this.entities.sort((a, b) => a.weight - b.weight);
  }

  get length() {
    return this.entities.length;
  }

  /**
   * TREE
   */
  get tree() {
    try{
      // @ts-ignore
      const { currentUser: user, route_type, blockMenus }: any = this.props;
      let entities = [...this.links]

        .filter(
        ({ id, perms, routeTypes, withoutRouteTypes }) => {
          return  ((!perms  || !!user.hasPerms(perms))
            && (!routeTypes || isArray(routeTypes) && routeTypes.includes(route_type))
              && !(isArray(withoutRouteTypes) && withoutRouteTypes.includes(route_type))
            )
            && (!blockMenus || isArray(blockMenus) && !blockMenus.includes(id))
        }
      )

      return this.setParentActive().listToTree(entities);
    }catch (e) {}
    return [];
  }


  protected setParentActive(item: any = null, activedIds:any[] = []){
    if(item){
      item.parent.map(({ targetId }:any) => {
        activedIds.push(targetId);
      });
    }else{
      for(let index in this.links){
        let item = this.links[index];
        if(item.isActive){
          item.parent = item.parent || [];
          item.parent.map(({targetId}:any) => {
            activedIds.push(targetId);
          });
          break;
        }
      }
    }

    this.entities.map((e)=>{
      if(!e.isActive && activedIds.includes(e.id)){
        Object.assign(e,{isActive: true})
        this.setParentActive(e, activedIds);
      }
      return e;
    })
    return this;
  }

  public load(options: any = {}) {
    const item = this.groupBy(this.entities);
    Object.keys(item).map(key => {
      const e = item[key];
      item[key] = this.listToTree(e);
    });
    return item;
  }

  /**
   *
   * @param url
   * @param menu
   */
  public add({ url ,...menu}:any) {
    let active = false;
    try{
      let { settings: { str, regex}  } = this.props;
      regex = regex || []
      active = (str === url) || !!regex.filter(({ local, menu }: { local:any, menu:any }) => local.test(str) && menu.test(url)).length;
    }catch (e) {
      // console.warn(e)
    }
    if(!this.entities.filter(({id}) => id ===menu.id).length){
      Object.assign(menu, { url, isActive: active});
      this.entities.push(menu);
    }
    return this;
  }


  /**
   * Update entities
   * @param entities
   * @returns {this}
   */
  public update(entities: any) {
    if (!entities) {
      return this;
    }

    const { links } = entities;
    this.save(links);
    return this;
  }

  /**
   *
   * @param entities
   */
  public save(entities: any[]) {
    if (!entities || !entities.length) {
      return this;
    }
    const { settings: { str }} = this.props;

    const $entities: any[] = [];
    entities.map(({...entity}: any) => {
      const isActive = !!entity.url.path === str;
      $entities.push(Object.assign(entity, {isActive}));
    });

    this.entities = $entities;
    return this;
  }

  private groupBy(objectArray: any[]) {
    return objectArray.reduce((acc, obj) => {
      const key = obj.vid.targetId;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  }

  private listToTree(data: any, options: any & typeOptions = {}) {

    options = options || {};
    const ID_KEY = options.idKey || 'id';
    const PARENT_KEY = options.parentKey || 'parent';
    const CHILDREN_KEY = options.childrenKey || 'children';

    const tree = [];
    const childrenOf = {};
    let id: string, parentId: any[], item: any = {};

    for (let i = 0, length = data.length; i < length; i++) {
      item = data[i];
      id = item[ID_KEY];
      parentId = item[PARENT_KEY] || 0;
      // every item may have children
      childrenOf[id] = childrenOf[id] || [];
      // init its children
      item[CHILDREN_KEY] = childrenOf[id];
      if (parentId && parentId.length && parentId[0].targetId !== 0) {
        parentId.map((pid: any) => {
          // init its parent's children object
          childrenOf[pid.targetId] = childrenOf[pid.targetId] || [];
          // push it into its parent's children object
          childrenOf[pid.targetId].push(item);
        });
      } else {
        tree.push(item);
      }
    }

    return tree;
  }
}

export default Menus;
