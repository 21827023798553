import React from "react";

export default function withRenderProcess(rest?: any){
  return function (Component: any){
    return function (props: any){
      return React.createElement((({ children, onRenders }) => <>
        { (!!(onRenders && Object.keys(onRenders).length)) && Object.keys(onRenders)
          .map(key => onRenders[key])
          .map((C, key) => <C key={key} {...rest} />)}
        {children}
      </>), props, React.createElement(Component, props))
    }
  }
}