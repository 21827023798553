const processMenu = (menus, { name, title, url, onClick, ...val }, props = {}) => {
  name = name || title;

  (url && url.path) && ( url = url.path )

  Object.assign(val, { name, title, onClick, url });

  if (typeof onClick === 'function') {
    Object.assign(val, { url: '#', onClick: (e) => onClick(e, props) });
  } else if (typeof onClick === 'string' && onClick === 'toggle') {
    Object.assign(val, {
      url: '#',
      onClick: (e) => {
        e.preventDefault();
        try {
          props?.siteAction?.callback();
        } catch (e) {
          error(e);
        }
      },
    });
  }
  menus.add(val);
};


export default processMenu;
