// import { locationMore, statsMore } from "./StatsOperations";

export interface Channel{
  name      :any;
  pageView  :any;
  users     :any;
  newUsers  :any;
}

export interface Devices{
  name    : string;
  percent : number;
  count   :number;
}


export interface Location{
  ip    : number;
  count :number;
  country_code: string;
  country: string;
  ip_to: number;
  ip_from: number;
  city: string;
  state: string;
}

export interface StatsInfo{
  views           :number;
  links           :number;
  ViewsByXemLink  :number;
  linksByXemLink  :number;
  viewByDomains   :number;
  linksByDomains  :number;
  linksByGuest    :number;
}


export class Stats {
  private _location: Location[];
  private _channels: Channel[];
  private _stats: StatsInfo;
  private _devices: Devices[];
  private _heatmap: any[];
  public views:number;
  public users:number;
  public newUsers:number;

  /**
   *
   */
  static instanse(){
    return new this();
  }

  /**
   * get IP
   */
  public get ips(){
    const _IPS:number[] = [];
    let location = this._location || [];
    let ips = location.map(({ip}) => Number(ip) || 0);
    ips.map((ip)=> (!_IPS.includes(ip)) ? _IPS.push(ip): '');
    return _IPS;
  }

  /**
   * get locations
   */
  public get locations(){
    return this._location || [];
  }

  /**
   * set locations
   * @param value
   */
  public set locations(value: any){
    const _LOCATIONS:Location[] = [];
    let { edges }:any = value || {}
    edges = edges || [];
    if(edges.length){
      edges.map((value:Location)=>{
        _LOCATIONS.push(value)
      })
    }

    this._location = _LOCATIONS;
  }

  /**
   * get locations
   */
  public get heatmap(){
    return this._heatmap || [];
  }

  /**
   * set locations
   * @param value
   */
  public set heatmap(value: any){
    const _HEATMAP:any[] = [];
    let { edges }:any = value || {}
    edges = edges || [];
    edges.map((val:any)=>_HEATMAP.push(val));
    this._heatmap = _HEATMAP;
  }


  /**
   *
   */
  public get devices(){
    return this._devices || [];
  }


  /**
   *
   */
  public set devices(values){
    let { edges }:any = values || {};
    edges = edges || [];
    const _DEVICES:Devices[] = [];
    if(edges.length){
      edges.map(({ name, count, percent }:Devices)=>{
        _DEVICES.push({
          name      : name,
          count     : count || 0,
          percent   : Math.round(count * 100 /percent) || 0
        })
      })
    }
    this._devices = _DEVICES;
  }

  /**
   *
   */
  public get channels(){
    return this._channels || [];
  }

  /**
   *
   * @param value
   */
  public set channels(value: any){
    let { views:totalPageView, users:totalUsers, newUsers:totalNewUsers, edges}:any = value || {};
    edges = edges || [];
    const _CHANNELS:Channel[] = [];
    if(edges.length){
      edges.map(({name,  pageView, users, newUsers}:Channel)=>{
        _CHANNELS.push({
          name      : { value: name },
          pageView  : { value: pageView , pecent: totalPageView ? Math.round(pageView*100/totalPageView) : 0 },
          users     : { value: users    , pecent: totalUsers    ? Math.round(users*100/totalUsers) : 0 },
          newUsers  : { value: newUsers , pecent: totalNewUsers ? Math.round(newUsers*100/totalNewUsers) : 0 }
        })
      })
    }
    this._channels = _CHANNELS;
  }



  /**
   *
   */
  public get data(){
    return this._stats || {};
  }

  /**
   *
   * @param value
   */
  public set data(value){
    const {
      views, links,
      ViewsByXemLink, linksByXemLink,
      viewByDomains, linksByDomains,
      linksByGuest
    }: any = value || {}

    this._stats = { views, links, ViewsByXemLink, linksByXemLink, viewByDomains, linksByDomains, linksByGuest }
  }

  public save({ data, locations, channels }: any){

    if(typeof data !== 'undefined'){
      this.data = data;
    }

    if(typeof locations !== 'undefined'){
      this.locations = locations;
    }

    if(typeof channels !== 'undefined'){
      this.channels = channels;
    }
  }

}

export default Stats;
