import { default as Domains } from './Domains';

export const map = ({ id, name, hostname, hash, hash_raw, scheme, verify_type, parent_id, status, weight,
                      childrenItems, childrenCount, uid, author, is_default, is_home_site, connect, created, redirect, access }) =>{

  let children = {
    edges: childrenItems.map(item => map(item)),
    count: childrenCount
  }

  return {

    ...access,
    id, name, hostname, hash, hash_raw, scheme, verify_type, parent_id, status, weight,
    children, uid, author, is_default, is_home_site, connect, created, redirect
  }
}

interface DomainAccess{
  accessPublic: false,
  accessUpdate: false,
  accessDelete: false,
  accessBlock: false,
  accessUnblock: false,
  accessRedirect: false,
  accessAdmin: false,
  accessActive: false,
  accessManager: false,
  accessSSL: false,
}

class Access implements DomainAccess{
  accessPublic: false;
  accessSSL: false;
  accessActive: false;
  accessAdmin: false;
  accessBlock: false;
  accessDelete: false;
  accessManager: false;
  accessRedirect: false;
  accessUnblock: false;
  accessUpdate: false;

  /**
   * @param accessPublic
   * @param accessSSL
   * @param accessActive
   * @param accessBlock
   * @param accessUnblock
   * @param accessRedirect
   * @param accessDelete
   * @param accessUpdate
   * @param accessAdmin
   * @param accessManager
   */
  set access({ accessPublic, accessSSL, accessActive, accessBlock, accessUnblock, accessRedirect, accessDelete, accessUpdate, accessAdmin, accessManager }: any){
    this.accessSSL      = accessSSL;
    this.accessSSL      = accessSSL;
    this.accessBlock    = accessBlock;
    this.accessUnblock  = accessUnblock;
    this.accessRedirect = accessRedirect;
    this.accessDelete   = accessDelete;
    this.accessUpdate   = accessUpdate;
    this.accessAdmin    = accessAdmin;
    this.accessManager  = accessManager;
    this.accessActive   = accessActive;
    this.accessPublic   = accessPublic;
  }

  /**
   *
   */
  get access(){
    return {
      accessSSL:    this.accessSSL,
      accessBlock:    this.accessBlock,
      accessUnblock:  this.accessUnblock,
      accessRedirect: this.accessRedirect,
      accessDelete:   this.accessDelete,
      accessUpdate:   this.accessUpdate,
      accessAdmin:   this.accessAdmin,
      accessManager:   this.accessManager,
      accessActive:   this.accessActive,
      accessPublic: this.accessPublic
    }
  }
}

export class Domain extends Access{
  private _id: number;
  public name: string;
  public full: string;
  private _hostname: string;
  private _scheme: string;
  private _schemeRaw: string;
  private _status: string;
  private _hash: string;
  private _hash_raw: string;
  private _weight: number;
  private _uid: number;
  private _author: string;
  private _is_default: boolean;
  public is_home_site: boolean;
  public isPublic: boolean
  private _connect: string[];
  private _created: number;
  private _parent_id: number;
  private _type: string;
  private _children: any;
  public redirect: string;
  public ssl: any;

  public static instanse(data?: any) {
    return new this().save(data || {});
  }

  /**
   *
   * @param children
   * @param props
   */
  public save({
    children,
    ... props
  }: any) {
    this._children = Domains.instanse();
    this._children.update(children);
    this.access = props;
    this.info = props;
    return this;
  }

  /**
   *
   * @param id
   * @param name
   * @param hostname
   * @param hash
   * @param hash_raw
   * @param ssl
   * @param scheme
   * @param verify_type
   * @param parent_id
   * @param status
   * @param weight
   * @param isPublic
   * @param uid
   * @param author
   * @param is_default
   * @param is_home_site
   * @param connect
   * @param created
   * @param redirect
   */
  set info({
      id, full, name, hostname, hash, hash_raw, ssl,
      scheme, verify_type, parent_id, status, weight, isPublic,
      uid, author, is_default, is_home_site, connect, created, redirect
    }){

    this._id = id;
    this.name = name;
    this.full = full;
    this._status = status;
    this._weight = weight;

    this.ssl = ssl;
    this._scheme = scheme;
    this._schemeRaw = scheme;
    this._uid = uid;
    this._author = author;
    this._hostname = hostname;
    this._hash = hash;
    this._hash_raw = hash_raw;
    this._created = created;
    this._is_default = is_default;

    this._connect = connect;
    this._type = verify_type;
    this._parent_id = parent_id;
    this.is_home_site = is_home_site;
    this.isPublic = isPublic;
    this.redirect = redirect;

  }

  get childrenCount() {
    return this._children.count || 0;
  }

  get childrenItems() {
    return this._children.items;
  }


  get children() {
    return this._children;
  }

  get id() {
    return this._id || 0;
  }

  get type() {
    return this._type || 'ns';
  }
  get scheme() {
    if(this._scheme === 'upload'){
      return 'https'
    }
    return this._scheme || 'http';
  }

  get schemeRaw() {
    return this._schemeRaw || 'http';
  }

  get hostname() {
    return this._hostname || '';
  }
  get hash() {
    return this._hash || '';
  }
  get hashRaw() {
    return this._hash_raw || '';
  }
  get status() {
    return this._status || 'inactive';
  }
  get weight() {
    return this._weight || 0;
  }
  get uid() {
    return this._uid || 0;
  }
  get parent_id() {
    return this._parent_id || 0;
  }
  get author() {
    return this._author || '';
  }

  get created() {
    return this._created * 1000 || null;
  }
  get site() {
    return `${this.scheme}://${this.hostname}`;
  }

  get is_default() {
    return !!this._is_default;
  }

  get connect() {
    return this._connect || [];
  }
}

export default Domain;
