import {Taxonomy} from './Taxonomy';
import Entities from '@gqlapp/core-common/Entities';
import entitiesMore from './entitiesMore';

export class Taxonomies extends Entities {

  public _fetchMore: any = null;
  public subscribeToMore: any = null;
  public updateQuery: any = null;
  public _protected: boolean = false;

  set fetchMore(value: any){
    this._fetchMore = value;
  }

  get fetchMore(){
    return entitiesMore(this._fetchMore);
  }


  set protected(value: any){
    this._protected = value;
  }

  get protected(){
    return this._protected;
  }

  /**
   * get pager
   */
  get variables(): any {
    return {
      pageSize: this.pageSize, orderBy: this.orderBy, direction: this.direction,
      page: this.page, filter: this.filter, protected: this.protected,
    };
  }

  /**
   * instanse
   */
  static instanse(props?: any){
    return (new this(props)).save([]);
  }


  constructor(props: any = null) {
    super(props);
    this.protected = !!props?.protected;
  }

  /**
   * options
   * @param $vid
   * @param level
   * @param options
   */
  public options($vid: string, level: number = 0, options: any = {}): any[] {
    const e = this.entities.filter($tax => $tax.hierarchy === $vid || !$vid);
    let item = this.listToTree(e, options);
    return this.options_render(item, 0);
  }

  /**
   * load
   * @param $vid
   * @param options
   */
  public load($vid: string, options: any = {}) {
    return this.entities.filter($tax => $tax.hierarchy === $vid || !$vid);
  }

  /**
   * Update entities
   * @param entities
   * @param reset
   * @returns {this}
   */
  public update(entities: any, reset: boolean = false) {
    if (reset) {
      this.entities = [];
    }
    if (!entities) {
      return this;
    }
    const { edges, count } = entities;
    this.count = count;
    this.save(edges);
    return this;
  }

  /**
   * add more entities
   * @param entities
   * @returns {this}
   */
  public add(entities: any) {
    if (!entities) {
      return this;
    }
    const { edges, count } = entities;
    this.count = count;
    edges.map((node: any) => {
      const $entity = new Taxonomy(null);
      $entity.save(node);
      this.addEntity($entity);
    });
    return this;
  }

  /**
   * save
   * @param entities
   */
  public save(entities: any[]) {
    /**
     * Check exists entity
     */
    if (!entities) {
      return this;
    }
  
    /**
     * Map entities
     */
    const $entities: Taxonomy[] = [];
    
    entities.map((node: any) => {
      const $entity = new Taxonomy();
      $entity.save(node);
      $entities.push($entity);
    });
    /**
     * Set entities
     */
    this.entities = $entities;
    return this;
  }

  /**
   *
   * @param $options
   * @param $level
   * @param $entities
   * @private
   */
  private options_render($options: any[], $level: number = 0, $entities: any[] = []) {
    $options.map((opt: any) => {
      let $name = '';
      if ($level > 0) {
        for (let i = 0; i < $level; i++) {
          $name += '--';
        }
      }

      $name = `${$name} ${opt.name}`;

      $entities.push({
        value: opt.id,
        label: $name.trim(),
      });

      if (opt.children && opt.children.length) {
        $entities = this.options_render(opt.children, $level + 1, $entities);
      }
    });
    return $entities;
  }

  /**
   *
   * @param objectArray
   * @private
   */
  private groupBy(objectArray: any) {
    return objectArray.reduce((acc: any, obj: any) => {
      const key = obj.vid;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  }



  public treeBy(tree: any[] =[],terms: number[] = []){

  }
  /**
   * Build true
   * @param data
   * @param options
   */
  private listToTree(data: any, options: any = {}) {
    options = options || {};
    const ID_KEY = options.idKey || 'id';
    const PARENT_KEY = options.parentKey || 'parent_id';
    const CHILDREN_KEY = options.childrenKey || 'children';

    const tree = [],
        childrenOf = {};
    let item: any, id, parentId;

    for (let i = 0, length = data.length; i < length; i++) {
      item = data[i];
      id = item[ID_KEY];
      parentId = item[PARENT_KEY] || 0;
      // every item may have children
      childrenOf[id] = childrenOf[id] || [];
      // init its children
      item[CHILDREN_KEY] = childrenOf[id];
      if (parentId != 0) {
        // init its parent's children object
        childrenOf[parentId] = childrenOf[parentId] || [];
        // push it into its parent's children object
        childrenOf[parentId].push(item);
      } else {
        tree.push(item);
      }
    }

    return tree;
  }
}

export default Taxonomies;
