import Entities from '@gqlapp/core-common/Entities';
import User from './User';
import usersMore from './usersMore';

class Users extends Entities {
  public _fetchMore: any = null;
  public subscribeToMore: any = null;
  public updateQuery: any = null;

  constructor(props:any = {}) {
    let { orderBy, ...rest} =  props || {}
    super({...rest, orderBy: orderBy || 'CHANGED' });
    this.interface = User;
  }


  set fetchMore(value: any){
    this._fetchMore = value;
  }

  get fetchMore(){
    return usersMore(this._fetchMore);
  }

  /**
   *
   */
  public static instanse(props:any = null) {
    return (new this(props)).save([]);
  }
}

export default Users;
