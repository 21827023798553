import React from 'react';
import clsx from 'clsx';
import Style from "../helpers/style/Styles";
import Box from "@mui/material/Box";
import Base from 'reactstrap/lib/Container';

const Container = ({ className, style, ...props }) =>
  <Base {...props} className={clsx(className)} fluid style={{
    ...style, maxWidth: '100%', width: '1024px',
    margin: '50px auto 0'
  }} />

export default Style((props) => {
  let __html = props?.entity?.body;
  return (<>
    <Container>
      <Box sx={{
        backgroundColor: '#ffffff',
      }} dangerouslySetInnerHTML={{__html}} />
    </Container>
  </>)
});
