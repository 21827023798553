import Image from '@gqlapp/core-common/Image';
import isArray from "@gqlapp/base/utils/isArray/isArray";

export interface TaxonomyInterface {
  id: number;
  hierarchy: string;
  name: string;
  description: string;
  weight: number;
  parent_id: number;
  changed?: number;
  status: boolean;
  canUpdate: boolean;
  canDelete: boolean;
}

export class Taxonomy implements TaxonomyInterface {
  public id: number = 0;
  public hierarchy: string = '';
  public icon: string = '';
  public name: string = '';
  public description: string = '';
  public author: string = '';
  public weight: number = 0;
  public parent_id: number = 0;
  public child: null;
  public status: boolean = false;
  public canDelete: boolean = false;
  public canUpdate: boolean = false;
  public canView: boolean = false;
  private _views: number;
  private _links: number;
  private _assigned: any[];
  public _images?: Image[] = [];

  /**
   *
   */
  public static instanse(data?: any) {
    return new this().save(data || {});
  }

  /**
   *
   * @param data
   */
  constructor(data: TaxonomyInterface = null) {
    if (data) {
      this.save(data);
    }
  }

  /**
   *
   */
  public reset() {
    const $self = this;
    Object.assign($self, new Taxonomy());
  }

  /**
   *
   * @param id
   * @param hierarchy
   * @param name
   * @param weight
   * @param author
   * @param parent_id
   * @param images
   * @param icon
   * @param child
   * @param description
   * @param canDelete
   * @param canUpdate
   * @param canView
   * @param status
   * @param views
   * @param links
   * @param assigned
   */
  public save({
    id,
    hierarchy,
    name,
    weight,
    author,
    parent_id,
    images,
    icon,
    child,
    description,
    canDelete,
    canUpdate,
    canView,
    status,
    views,
    links,
    assigned
  }: any) {
    this.id = id || 0;
    this.name = name || 0;
    this.author = author || '';
    this.hierarchy = hierarchy || '';
    this.weight = weight || 0;
    this.parent_id = parent_id || 0;
    this.child = child || 0;
    this.description = description || '';
    this.status = status || true;
    this.canDelete = canDelete || false;
    this.canUpdate = canUpdate || false;
    this.canView = canView || false;
    this.icon = icon || false;
    this.views = views;
    this.links = links;
    this.images = images || [];
    this.assigned = assigned;
    return this;
  }

  /**
   *
   * @param value
   */
  set assigned(value: any[]) {
    this._assigned = value || [];
  }

  get assigned() {
    return this._assigned;
  }


  /**
   * @param _images
   */
  set images(_images) {
    try {
      const images: any[] = [];
      if (isArray(_images)) {
        _images.map((img: any) => images.push(img));
      } else {
        images.push(_images);
      }
      this._images = images;
    } catch (e) {
      console.warn(e.toString());
    }
  }

  /**
   * View
   */
  get views() {
    return this._views || 0;
  }

  set views(value: number) {
    this._views = value;
  }

  /**
   * Lunk
   */
  get links() {
    return this._links || 0;
  }

  set links(value: number) {
    this._links = value;
  }

  /**
   * Images
   */
  get images() {
    return this._images.filter(image => image);
  }

  /**
   * image
   */
  get image() {
    return this._images[0] || new Image();
  }
}

export default Taxonomy;
