import loading from "@gqlapp/base/utils/loading";
import Stats from "./Stats";

/**
 *
 * @param stats
 * @param fetchMore
 * @param loadbar
 */
export const statsLocationMore = (stats: Stats, fetchMore: any, { loadbar }: any) => async (variables: any, bar?: any) =>
    new Promise(async (resolve, reject) => loading((bar || loadbar), async()=>{
        try {
            await fetchMore({
                variables,
                updateQuery: async (_: any, { fetchMoreResult: { locations } }: any) => {
                    try { stats.locations = locations; } catch (e) {error(e)}
                    resolve(locations);
                }
            });
        }catch (e) {
            error(e);
            reject({});
        }
    }));

export default statsLocationMore;
