import { graphql } from 'react-apollo';
import LINKS_QUERY from '../graphql/linksQuery.graphql';
import compose from "recompose/compose";
import withProps from "recompose/withProps";
import mapInput from '@gqlapp/base/utils/mapInput';

import Links from './Links';

type LinkProps = {
  ownProps: any & {
    links: Links
  }
}

/**
 * withLinks
 * @param input
 */
export const withLinks = (input: any) =>compose(
  withProps((props: any) => mapInput(input, props)),
  graphql(LINKS_QUERY, {
    options: ({ links, fetchPolicy, notifyOnNetworkStatusChange }: any) =>{
      let variables = links.variables;
      return  ({
        fetchPolicy: fetchPolicy || null,
        variables,
        notifyOnNetworkStatusChange: !!notifyOnNetworkStatusChange,
        // notifyOnNetworkStatusChange: true
      })
    },
    props({
            ownProps: { links, entitiesReset },
            data: { loading, res, refetch, error, updateQuery, fetchMore, subscribeToMore },
          }: any & LinkProps) {
      links.refetch = refetch;
      links.fetchMore = fetchMore;
      links.subscribeToMore = subscribeToMore;
      links.updateQuery = updateQuery;

      // console.error({ links, loading, res, __SSR__, __TEST__ })
      if (!loading && res) {
        links.update(res, !!entitiesReset);
      }

      return {
        loading,
        errors: error ? error.graphQLErrors : null,
      };
    },
  })
);

export default withLinks;
