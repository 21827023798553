import React from 'react';


const Popup = React.forwardRef(({ component, ...props}, ref) => {

  function getDisplayName(WrappedComponent) {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
  }

  let popupRef = React.createRef();
  const [state, setStatus] = React.useState({
    isOpen: false,
  });

  const close = () =>{
    state.close && state.close();
    setStatus({ isOpen: false });
  }

  const open = (values = {}) =>{
    // @ts-ignore
    setStatus({ ...values, isOpen: true });
  }

  const innerRef = (node) =>{
    node && node?.open && node?.open();
    if(node && !popupRef.current){
      popupRef.current = node;
    }
    node && props?.innerRef && props?.innerRef(node)
  }

  React.useImperativeHandle(ref, () => ({ close, open }));

  return state.isOpen ? React.createElement(component, {
    ...props,
    ...state,
    componentName: getDisplayName(component),
    popupRef,
    open,
    close,
    innerRef
  }): null;
});


export default Popup;
