import React from "react";
import clsx from 'clsx';
import Row from "@gqlapp/base/Row";
import Col from "@gqlapp/base/Col";
import Field from "@gqlapp/forms-client-react/FieldAdapter";
import RenderField from "@gqlapp/base/RenderField";
import Box from "@mui/material/Box";
import Button from "reactstrap/lib/Button";
import ButtonGroup from "reactstrap/lib/ButtonGroup";
import FormBase from "reactstrap/lib/Form";
import compose, { btnProps } from "./Filter.Utils";
import Search from "@xem/icon/base/Search.svg";
import Hide from "@xem/icon/base/Hide.svg";
import Filter from "@xem/icon/base/Filter.svg";
import Delete from "@xem/icon/base/Delete";


export default compose((props) =>{
  let { sx, selecterSx,handleSubmit, setFieldValue, values } = props;
  let { type, onClick, onKeywordChange, onClickType, canDelete } = props;

  let { ids, keyword, onSelect } = values;

  let length = ids?.length || 0;
  return (<>
    <Box sx={sx}>
      <Row className={'control-form'} none>
        <Col>
          <Field
            {...{
              className: 'keyword',
              placeholder: `search.keywords`.t(),
              component: RenderField,
              name: 'keyword',
              value: keyword,
              onChange: (value) => {
                onKeywordChange(value);
                setFieldValue('keyword', value)
              },
              startAdornment: <div style={{ width: '36px', height: '36px'}}>
                <Search width={'36px'} height={'36px'} />
              </div>
            }}
          />
        </Col>
        <Col xs={'auto'} className={'d-flex align-items-center'}>
          <Button onClick={onClickType('hide')} className={clsx('icon', 'hide', { 'active': onSelect === 2})} color={'default'}><Hide /></Button>
        </Col>
        <Col xs={'auto'} className={'d-flex align-items-center'}>
          <Button onClick={onClick} className={'icon'} color={'default'}><Filter /></Button>
        </Col>
        {(canDelete) && <Col xs={'auto'} className={'d-flex align-items-center'}>
          <Button onClick={onClickType('delete')} className={clsx('icon', 'delete', { 'active': onSelect === 1})} color={'default'}><Delete/></Button>
        </Col>}
      </Row>
    </Box>
    {(!!onSelect) && <Box sx={selecterSx} className={'selecter'}>
      <FormBase onSubmit={handleSubmit}>
        <ButtonGroup className={'w-100'}>
          <Button
            onClick={()=>setFieldValue('onSelect', 0)}
            {...btnProps}
            style={{ minHeight: '50px' }}
          >
            {`cancel`.t()}
          </Button>
          <Button
            type={'submit'}
            {...btnProps}
            disabled={!length}
            style={{ minHeight: '50px', '--btn-outline-secondary': '#C21C21' }}
          >
            {[`link:form.${type}.btn.link`, `link:form.${type}.btn.links`].oddeven(length).t({ value: length })}
          </Button>
        </ButtonGroup>
      </FormBase>
    </Box>}
  </>)
})