import React from 'react';
import clsx from "clsx";
import Logo from "../icons/LogoLogined";
import Calendar from "@xem/icon/dashboard/Calendar.svg";
import Search from "@xem/icon/dashboard/Search.svg";
import Link from "@xem/look/Link";

import Container from 'reactstrap/lib/Container';
import Row from '@gqlapp/base/Row';
import Col from '@gqlapp/base/Col';
import Button from 'reactstrap/lib/Button';

const HeadSide = ({ isDashboard, className, webType, ...props}) => {
  let actionDate = (value)=>{
    let call = props?.action?.get
    if(typeof call === 'function'){
      call(value);
    }
  }

  let headerProps = { 'data-header': true }
  headerProps[`data-header-${webType}`] = true;
  const currentUser = props?.currentUser;
  const logined = currentUser && !!currentUser.id;

  return (<section
    {...headerProps}
    className={clsx(className, `header-${webType}`, 'shadow', 'position-relative')}
  >
    <Container fluid className={'bg-white'}>
      <div className={"header"}>
        <Row>
          <Col>
            <div style={{ height: '50px' }} className={'d-flex align-items-center pl-3'}>
              <Link to={logined ? '/dashboard.html':'/'}><Logo /></Link>
            </div>
          </Col>
          <Col xs={'auto'} className={'d-flex align-items-center'}>
            <div className={'px-3'}>
              {(props.onActions && props.onActions['Search']) && <Button onClick={props.onActions['Search']()} color={'default'} className={'p-0'}>
                <Search/>
              </Button>}
              {(!!isDashboard) && <Button onClick={()=> actionDate()} color={'default'} className={'p-0 ml-2'}>
                <Calendar/>
              </Button>}
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  </section>)
}

export default HeadSide;
