import settings from '@xem/config';
import Action from '@xem/entity/classes/Action';
import custompage from "../../../../custompage/client-react/helpers/config";

const page = {

}

export const map = ({ id, page, path }: any) => `${page[path]}`.replace(/:id/, id).replace(/:page/, page);


export const PageRoute = {
  ...settings.route,
  exact: true,
  action: Action.instanse(),
  classNameOnPage: 'theme-page',
  webType: 'web',
  runtimejs: true,
}


export default custompage;
