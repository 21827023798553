import React from 'react';
import loadable from 'next/dynamic';

const YoutubeContainer = loadable(
	()=>import('./Youtube').then(
		(c) => c.default)
);

export default YoutubeContainer;

