import loading from "@gqlapp/base/utils/loading";
import Stats from "./Stats";

/**
 *
 * @param stats
 * @param fetchMore
 * @param loadbar
 */
export const statsChannelMore = (stats: Stats, fetchMore: any, { loadbar }: any) => async (variables: any, bar?: any, wait?: boolean) =>
  new Promise(async (resolve, reject) => loading((bar || loadbar), async ()=>{
      try{
        await fetchMore({
          variables,
          updateQuery: async (_: any, { fetchMoreResult: { res } }: any) => {
            if(!wait) {
              try { stats.channels = res; } catch (e) { error(e); }
            }
            resolve(res);
          }
        });
      }catch (e) {
        error(e);
        reject({});
      }
  }));

export default statsChannelMore;
