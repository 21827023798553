import { graphql } from 'react-apollo';
import LINKS_UPDATE from '../graphql/linksUpdate.graphql';
import map from '@gqlapp/base/utils/map';
import loading from '@gqlapp/base/utils/loading';

/**
 * withLinksUpdate
 * @param Component
 */
export const withLinksUpdate = (Component: any) =>
  graphql(LINKS_UPDATE, {
    props: ({ mutate, ownProps: { loadbar } }: any) => ({
      linksUpdate: async (variables: any) =>
        loading(loadbar, async () =>
          map(
            map(await mutate({ variables }), ({ data }: any) => data),
            ({ linksUpdate: res }: any) => res
          )
        )
    })
  })(Component);

export default withLinksUpdate;
