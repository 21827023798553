import withProps from 'recompose/withProps';
export default withProps({
  classes:{
    root: {
      '&':{
        position: 'relative'
      },
      '& [data-scroll]':{
        width: '100%',
        overflowX: 'scroll',
        'msOverflowStyle': 'none',
        'scrollbarWidth': 'none',
        '&.blockClick':{
          '&:before':{
            // backgroundColor: '#C21C21',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            content: '" "',
            width: 'calc(100%)',
            height: 'calc(100%)',
            display: 'table',
            zIndex: 1000,
            position: 'absolute',
          },
        },
        '&::-webkit-scrollbar':{
          display: 'none'
        },
        '&>.init':{
          width: '9999999px',
          justifyContent: 'flex-start !important'
        }
      }
    }
  }
});
