/**
 * extractRootDomain
 * @param url
 */
export const extractHostname = (url) => {
  let hostname;
  // find & remove protocol (http, ftp, etc.) and get hostname
  try {
    // tslint:disable-next-line:prefer-conditional-expression
    if (url.indexOf('//') > -1) {
      hostname = url.split('/')[2];
    } else {
      hostname = url.split('/')[0];
    }

    // find & remove port number
    hostname = hostname.split(':')[0];
    // find & remove "?"
    hostname = hostname.split('?')[0];
  } catch (e) {}

  return hostname;
};

export default extractHostname;