import React from 'react';
import ClientModule from '@gqlapp/module-client-react';
import Col from "@gqlapp/base/Col";
import IconDomain from '../icons/Domain.svg'
import { map } from '../config'
const UserMenus = [{
	id: 1001,
	icon: <IconDomain />,
	title: 'Brand Domain',
	handleClick: (e)=>{
		e.preventDefault();
		historyPush(map({ path: 'list'}))
	}
}]
	.map(({ weight, ...value }, index)=>({
		...value,
		perms: ['isAdminDomain', 'managerDomain', 'isAdmin'],
		weight: weight || index,
		type: 'item',
		parent: [{ targetId: 'settings' }],
		wrapper: (props)=><Col {...props} xs={6} />,
	}))

export default new ClientModule({
	menus:[{ positions: ['menu.mobile'], items: UserMenus }]
})
