/**
* ucwords
* @param str
*/
export function ucwords(str) {
  try {
    // tslint:disable-next-line:only-arrow-functions
    str = str.replace(/(\b\w)/gi, function(m) {
      return m.toUpperCase();
    });
  } catch (e) {}
  return str;
}

export default ucwords;