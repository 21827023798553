import { graphql } from 'react-apollo';
import PRODUCTS_DELETE from "../graphql/ProductsDelete.graphql";
import loading from "@gqlapp/base/utils/loading";
import map from "@gqlapp/base/utils/map";

/**
 *
 */
export const withProductsDeleting = graphql(PRODUCTS_DELETE, {
  props: ({ mutate, ownProps: { loadbar } }: any) => ({
    productsDelete: async (variables: any) =>
      loading(loadbar, async () =>
        map(
          map(await mutate({ variables }), ({ data }: any) => data),
          ({ res }: any) => res
        ))
  })
});

export default withProductsDeleting;
