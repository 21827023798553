import { graphql } from 'react-apollo';
import mapInput from '@gqlapp/base/utils/mapInput';
import removeTypename from '@gqlapp/base/utils/removeTypename';
import compose from 'recompose/compose';
import withProps from 'recompose/withProps';

import PRODUCTS_QUERY from "../graphql/ProductsQuery.graphql";

export const withProducts  = (input:any)=> compose(
  withProps((props:any) => mapInput(input, props)),
  graphql(PRODUCTS_QUERY, {
    options: ({ page, pageSize, orderBy, direction, filter }:any) =>
      ({ variables: { page, pageSize, orderBy, direction, filter }}),
    props({
            ownProps:{ products },
            data: { loading, res, refetch, error, updateQuery, fetchMore, subscribeToMore }
    }:any) {
      if(!loading && res){ products.update(removeTypename(res)); }
      return { loading, productsRefetch:refetch, productsMore: fetchMore, productsSubscribeToMore: subscribeToMore, updateQuery, errors: error ? error.graphQLErrors : null };
    }
  }));

export default withProducts;
export { PRODUCTS_QUERY }
