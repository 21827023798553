import {withFormik} from "formik";
import Box from "@mui/material/Box";
import Row from "@gqlapp/base/Row";
import Col from "@gqlapp/base/Col";
import RenderField from "@gqlapp/base/RenderField";
import Search from "../icons/control/Search.svg";
import Button from "reactstrap/lib/Button";
import Delete from "../icons/control/Delete.svg";
import Filter from "../icons/control/Filter.svg";
import Hide from "../icons/control/Hide.svg";
import React from "react";
import Field from "@gqlapp/forms-client-react/FieldAdapter";
import Radio from '@mui/material/Radio';
import ButtonGroup from "reactstrap/lib/ButtonGroup";
import FormBase from "reactstrap/lib/Form";
import { getFilterByKey, btnProps, BaseConfig } from './Utils';
import compose from "./Filter.Utils";
import clsx from 'clsx';

let timeoutKeyword = null;
export const FormFilter =  compose((props) =>{

  let { sx, currentUser, setFieldValue, handleSubmit, onActions, onKeywordChange, onSelectChange, values } = props;
  let canDelete = false, { ids, defaultKey, keyword, onSelect } = values;

  try{
    let perms = currentUser.perms;
    canDelete = perms.isAdminLink && perms.isAdmin;
  }catch(e){}

  defaultKey = defaultKey || 'latest';

  const map = (({ title, key, ...rest})=>{
    return {
      ...rest,
      handleClick:()=> {
        onSelectChange(key);
      },
      title: () => (<Row none>
        <Col xs={'auto'}><Radio checked={(key === defaultKey)} /></Col>
        <Col className={'d-flex align-items-center'}>{title}</Col>
      </Row>)
    }
  });

  const viewMenus = [
    { key: `viewsLatest`,      title: 'link:views.latest'.t() },
    { key: `viewsMost`,        title: 'link:views.most'.t()},
    { key: `viewsQrcode`,           title: 'link:views.qrcode'.t()},
    // { key: `viewsLeast`,       title: `link:views.least`.t()},
  ].map(value=> map(value));

  const titleMenus = [
    { key: `az`,          title: `link:views.az`.t()},
    { key: `za`,          title: 'link:views.za'.t() }
  ].map(value=> map(value));

  const shortedMenus = [
    { key: `latest`,       title: 'link:shorted.latest'.t() },
    { key: `longest`,      title: 'link:shorted.longest'.t() },
    { key: `unpublished`,      title: 'link:filter.actions.unpublished'.t() },
  ].map(value=> map(value));


  const onClick = () =>{
    (onActions && onActions.Toolbars) && onActions.Toolbars(
      {
        enableClose: true,
        items: [ viewMenus, titleMenus, shortedMenus ].map((items)=>({
          items , type: 'group',
          wrapper: ({ children })=><Box className={'btn-group-vertical'} sx={{ '&':{ width: '100%', '&> .btn': { padding:0}} }}>{children}</Box>,
        })),
        headerProps:{ style: { padding: '7px 0' } },
        title: 'label.links.filter'.t() })
  }

  const onClickType = (type)=> ()=>{

    if(([0, 2].includes(onSelect)) && (type === 'delete')){
      setFieldValue('onSelect', 1)
    }
    else if(([0, 1].includes(onSelect)) && (type === 'hide')){
      setFieldValue('onSelect', 2)
    }else{
      setFieldValue('onSelect', 0)
    }
  }

  let type = null;
  switch (onSelect){
    case 1:
      type = 'delete'
      break;
    case 2:
      type = 'hide'
      break;
  }

  let tabIndex = 1;
  const fieldConfig = (props) => BaseConfig({ ...props, setFieldValue, tabIndex })

  return (<>
    <Box sx={sx}>
      <Row className={'control-form'} none>
        <Col>
          <Field
            {...fieldConfig({
              className: 'keyword',
              placeholder: `link:list.search.placeholder`.t(),
              name: 'keyword',
              value: keyword,
              onChange: (value) => {
                onKeywordChange(value);
                setFieldValue('keyword', value)
              },
              searchIcon: { }
            })}
          />
        </Col>
        <Col xs={'auto'} className={'d-flex align-items-center'}>
          <Button onClick={onClickType('hide')} className={clsx('icon hide', { 'active': onSelect === 2})} color={'default'}><Hide /></Button>
        </Col>
        <Col xs={'auto'} className={'d-flex align-items-center'}>
          <Button onClick={onClick} className={'icon'} color={'default'}><Filter /></Button>
        </Col>
        {(canDelete) && <Col xs={'auto'} className={'d-flex align-items-center'}>
          <Button onClick={onClickType('delete')} className={clsx('icon delete', { 'active': onSelect === 1})} color={'default'}><Delete/></Button>
        </Col>}
      </Row>
    </Box>
    {(!!onSelect) && <Box sx={{
      zIndex: 1000, position: 'absolute',
      bottom: 0, left: 0, right: 0, height: '50px',
      backgroundColor: '#ffffff',
      '& .btn-group':{
        '--btn-outline-border-secondary': '#f2f2f2',
        '& .btn':{
          '&:first-of-type':{
            borderLeft: 'none !important'
          },
          '&:last-of-type':{
            borderRight: 'none !important'
          }
        }
      },
    }}>
      <FormBase onSubmit={handleSubmit}>
        <ButtonGroup className={'w-100'}>
          <Button
            onClick={()=>setFieldValue('onSelect', 0)}
            {...btnProps}
            style={{ minHeight: '50px' }}
          >
            {`cancel`.t()}
          </Button>
          <Button
            type={'submit'}
            {...btnProps}
            disabled={!ids.length}
            style={{ minHeight: '50px', '--btn-outline-secondary': '#C21C21' }}
          >
            {[`link:form.${type}.btn.link`, `link:form.${type}.btn.links`].oddeven(ids.length).t({ value: ids.length })}
          </Button>
        </ButtonGroup>
      </FormBase>
    </Box>}
  </>)
})

const filterForm = withFormik({
  mapPropsToValues: ({ cookie }) =>
    ({ ids: [], count: 0, all: false, page: 1, onSelect: 0, defaultKey: cookie?.filter?.key }),
  enableReinitialize: true,
  displayName: 'EntitiesForm', // helps with React DevTools
});

const FormWrapper = filterForm(({ children, ...props}) =>{
  let { onActions, entities, pageProps, setFieldValue, loadbar, values: { page } } = props;

  const innerRef = ()=>{
    (onActions['Filter']) && onActions['Filter']({ ...props, isOpen: true })
  }

  function getMore(items, page, reset = false) {
    entities.page = page;
    if(reset){ entities.update(null, true); }
    return entities
      .fetchMore(entities.variables, loadbar)
      .then((res)=>entities.add(res))
      .then(()=>setFieldValue('page', page));
  }

  /**
   * handle Page change
   * @param page
   * @returns {Promise<void>}
   */
  let handlePageChange = async (page) => {
    if (page !== entities.page) {
      let items = entities.items || [];
      await getMore(items, page);
    }
  }


  /**
   *
   * @param keyword
   */
  const onKeywordChange = (keyword)=>{
    keyword = keyword || ``;
    entities.page = 1;

    entities.filter = {
      search: [{ keyword, type: 'all' }]
    }

    clearTimeout(timeoutKeyword);
    timeoutKeyword = setTimeout( async () => {
      await setFieldValue('keyword', keyword);
      await getMore([], 1, true);
    }, 500);
  }


  /**
   * onSelectChange
   * @param key
   * @returns {Promise<void>}
   */
  const onSelectChange = async (key)=>{

    await setFieldValue('defaultKey', key);

    let { orderBy, direction, status } = getFilterByKey(key);

    /**
     * End direction
     */

    entities.page = 1;
    entities.orderBy = orderBy;
    entities.direction = direction;
    entities.filter = { status };

    setCookie('link_list_sort', key)
    await getMore([], 1, true);
  }

  pageProps = {
    ...entities.variables,
    ...pageProps,
    page,
    pageCount: entities.pageCount,
    collectionSize: entities.count,
    handlePageChange
  }

  Object.assign(props, { onKeywordChange, onSelectChange, parentFieldValue: setFieldValue, pageProps });

  return <>
    <Box ref={innerRef} sx={{ paddingTop: '0px', backgroundColor: '#f2f2f2', minHeight: 'calc(100%)' }}>
      {typeof children === 'function' && children(props)}
      {typeof children !== 'function' && children}
    </Box>
  </>
});

export default function (Component){
  return function (props){
    return <FormWrapper {...props}>{Component}</FormWrapper>
  }
}