import { graphql } from "react-apollo";
import QUERY from "../graphql/PageQuery.graphql";
import mapInput from "@gqlapp/base/utils/mapInput";
import compose from "recompose/compose";
import withProps from "recompose/withProps";


export const queryPage = graphql(QUERY, {
  skip: ({ skip, path }: any) => {
    return !path || skip;
  },
  options: ({ path, fetchPolicy }: any) => ({
    variables: { path },
    fetchPolicy: fetchPolicy || 'cache-and-network',
  }),
  props({
          data: { loading, res, refetch, subscribeToMore, error},
          ownProps: { page }
  }: any) {
    (!!page) && (page.refetch = refetch);
    (!!page) && (page.subscribeToMore = subscribeToMore);
    if (!loading && res && page) {
      page.save(res);
    }
    return { loading, errors: error ? error.graphQLErrors : null };
  }
});


export { QUERY as PAGE_QUERY }
export default (input?: any) => compose(
  withProps((props: any) => mapInput(input, props)),
  queryPage
);