import { graphql } from 'react-apollo';
import LINK_UPDATE from '../graphql/linkUpdate.graphql';
import map from '@gqlapp/base/utils/map';
import loading from '@gqlapp/base/utils/loading';

/**
 * withLinkUpdate
 * @param Component
 */
export const withLinkUpdate = (Component: any) =>
  graphql(LINK_UPDATE, {
    props: ({ mutate, ownProps: { loadbar } }: any) => ({
      linkUpdate: async (variables: any) =>
        loading(loadbar, async () =>
          map(
            map(await mutate({ variables }), ({ data }: any) => data),
            ({ linkUpdate: res }: any) => res
          )
        )
    })
  })(Component);

export default withLinkUpdate;
