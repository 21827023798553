import countries from '@gqlapp/config/country/countries'
import opt from "@gqlapp/base/utils/opt";
class Profile {
  private _name: string = '';
  public firstName: string = '';
  public lastName: string = '';
  public middle: string = '';

  set name(value) {
    this._name = value;
  }

  get name() {
    return this._name || '';
  }

  constructor({ name, first_name, last_name, middle }: any) {
    this.name = name;
    this.firstName = first_name;
    this.lastName = last_name;
    this.middle = middle;
  }
}

class Address {
  public country            : string;
  public zone               : string;
  public locality           : string;
  public dependentlocality  : string;
  public address            : string;
  // tslint:disable-next-line:variable-mail
  public address_2          : string;
  public zipcode          : string;

  constructor({ country, zone, locality, dependent_locality, address, address_2, zipcode }: any) {
    this.country = country;
    this.zone = zone;
    this.locality = locality;
    this.dependentlocality = dependent_locality;
    this.address = address;
    this.address_2 = address_2;
    this.zipcode  = zipcode;
  }

  public toString(){
    let address = [];
    if(this.address) address.push(this.address);
    if(this.address_2) address.push(this.address_2);
    if(this.locality) address.push(this.locality);
    if(this.dependentlocality) address.push(this.dependentlocality);
    if(this.zone) address.push(this.zone);

    if(this.country){
      let { label: country } = opt(countries, this.country)
      address.push(country.t());
    }

    if(this.zipcode) address.push(this.zipcode);
    return address.join(', ');
  }
}

class Auth {
  public facebook: any = {};
  public google: any = {};
  constructor({ facebook, google }: any) {
    this.facebook = facebook || {};
    this.google = google || {};
  }

  public toArray(){
    let $fields = [];
    if(this.facebook?.status){
      $fields.push({ ...this.facebook, type: 'facebook'});
    }

    if(this.google?.status){
      $fields.push({ ...this.google, type: 'google'});
    }

    return $fields;
  }
}


interface UserAccess{
  accessUpdate: false,
  accessDelete: false,
  accessBlock: false,
  accessUnblock: false,
  accessAdmin: false,
  accessManager: false,
}


export class User{

  public refetch: any = null;
  public fetchMore: any = null;
  public subscribeToMore: any = null;


  public id: number | undefined;
  public mail: string | undefined;
  public name: string | undefined;
  public username: string | undefined;
  public image: string | undefined;
  public domainID: number = 0;

  public orderRole: string | undefined;

  private _roles: any[] = [];
  public role: string = null;

  // tslint:disable-next-line:variable-mail
  public status: boolean | undefined;
  private _phone: string | undefined;
  private _area: string | undefined;
  private birthday: string | undefined | null;
  public sex: string | undefined;

  private _address = new Address({});
  private _profile = new Profile({});
  private _auth = new Auth({});


  public photo: string | undefined;
  public ga: string | undefined;
  public pixel: string | undefined;
  public perms: any;
  public access: any;


  /**
   * instanse
   */
  public static instanse(data?:any) {
    return (new this()).save(data || {});
  }

  get address() {
    return this._address;
  }

  set address(address: any) {
    this._address = new Address(address || {});
  }

  /**
   * profile
   */
  get profile() {
    return this._profile;
  }
  set profile(profile) {
    this._profile = new Profile(profile || {});
  }

  get auth() {
    return this._auth;
  }
  set auth(auth) {
    this._auth = new Auth(auth || {});
  }


  get display_name() {
    return this.profile.name || '';
  }


  set phone(value: string) {
    try{ value = value.replace(/\D/g, "")}catch (e) {}
    try{
      let { phone:code, value: area } = ([...countries]
        .filter(({ phone:code }: any)=>
          new RegExp(`^${code}`)
            .test(value.replace(/\D/g, ""))
        )).shift();
      value = value.replace(new RegExp(`^${code}`), '')
      this._area = area;
    }catch (e) {}
    this._phone = value
  }


  set area(value) { this._area = value || 'VN';}

  get area() { return this._area || 'VN';}

  get phone() { return this._phone || ''; }


  get roles() {
    return this._roles;
  }

  set roles(roles: any) {
    const rest: any[] = [];
    roles = typeof roles === 'string' ? [roles] : roles || [];
    roles.map((role: string) => {
      if (!rest.includes(role)) {
        rest.push(role);
      }
    });
    this._roles = rest;
  }

  public reset() {
    // tslint:disable-next-line:no-this-assignment
    const $self = this;
    Object.assign($self, User.instanse());
  }

  // @ts-ignore
  public save(props) {
    let { id, image, orderRole, photo, status, name, birthday, sex, username, domainID } = props;
    let { role, roles, perms, access, mail, phone, area, address, profile, ga, pixel, auth } = props;


    this.ga     = ga;
    this.pixel  = pixel;
    this.orderRole   = orderRole;

    this.id           = id;
    this.mail         = mail;
    this.name         = name;
    this.username     = username;
    this.status       = !!status;
    this.sex          = sex || 'other';
    this.birthday     = birthday;
    this.domainID     = domainID || 0;
    this.area         = area;
    this.phone        = phone;
    this.role         = role || 'anonymous';
    this.roles        = roles || ['anonymous'];
    this.auth         = auth;
    this.address      = address;
    this.profile      = {...profile, name};
    this.photo        = photo
    this.image        = image
    this.perms        = perms;
    this.access = access;

    return this;
  }

  public hasPerms(perms: any) {
    const $perms = this.perms || {};
    perms = typeof perms === 'string' ? [perms] : perms || [];

    return !!perms.filter((perm: string) => $perms[perm]).length;
  }

  public hasRole(roles: any) {
    const $roles = this.roles;
    roles = typeof roles === 'string' ? [roles] : roles || [];
    return !!roles.filter((role: string) => $roles.includes(role)).length;
  }

}

export default User;
