import React from 'react';
import loadable from 'next/dynamic';

const LockedContainer = loadable(
	()=>import('./Locked').then(
		(c) => c.default)
);

export default LockedContainer;

