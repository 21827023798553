import {withFormik} from "formik";
import Box from "@mui/material/Box";
import Row from "@gqlapp/base/Row";
import Col from "@gqlapp/base/Col";
import RenderField from "@xem/look/Renders/Field";
import Search from "./icons/Search.svg";
import Button from "reactstrap/lib/Button";
import React from "react";
import Field from "@gqlapp/forms-client-react/FieldAdapter";
import Radio from '@mui/material/Radio';
import ButtonGroup from "reactstrap/lib/ButtonGroup";
import FormBase from "reactstrap/lib/Form";
import Notice from './icons/Notice.svg';
import Domain, { map } from "@xem/domain-common/classes/Domain";
import Filter from "../helpers/icons/DomainFilter.svg";

export const btnProps = {
  outline: true,
  className: 'rounded-0',
  color: 'secondary',
};


export const getFilterByKey = (key) =>{
  let orderBy = 'CREATED';
  let direction = 'DESC';
  let status = 'active';

  /**
   * Từ a-z
   * và z-a
   */
  if(['az', 'za'].includes(key)){
    orderBy = 'HOSTNAME';
  }


  /**
   * Direction
   */
  if(['az'].includes(key)){
    direction = 'ASC';
  }

  return { status, direction, orderBy };
}

const MuiStyles= ({ onDel, onHide }) => ({
  '&':{
    zIndex: 1000,
    position: 'absolute',
    top: '0px',
    left: 0,
    right: 0,
    paddingTop: '0',
    backgroundColor: '#f2f2f2'
  },
  '& .control-form':{
    padding: '7px 10px',
    backgroundColor: '#fff',
    '& .icon':{
      '&.delete':{
        color: (!!onDel )? '#C21C21': '#000000'
      },
      '&.hide':{
        color: (!!onHide )? '#C21C21': '#000000'
      }

    },
    '& .MuiInputBase-adornedStart':{
      paddingLeft: 0
    },

    '& .MuiFormControl-root':{
      '&.keyword':{
        '& .MuiOutlinedInput-notchedOutline':{
          border: 'none'
        },
        '& .MuiInputBase-root':{
          backgroundColor: '#f2f2f2',
          borderRadius: '36px',
          '& .MuiInputBase-input':{
            padding: '6.5px 10px 6.5px 0',
          }
        },

        marginTop: 0,
        marginBottom: 0,
        paddingRight: '5px'
      }
    },
    '& .btn': {
      '&.icon':{
        padding: '0 0 0 10px'
      }
    }
  }
});


let timeout = null;
let timeoutKeyword = null;

export const FormFilter =  withFormik({
  validate: ((values, { setValues })=>{
    clearTimeout(timeout);
    timeout = setTimeout( () => {
      if(!(values.onSelect)){
        Object.assign(values, { ids: [] });
      }
      setValues(values)
    }, 100);
  }),
  mapPropsToValues: ({ values }) => (values),
  handleSubmit: async function(values, { props: { entities, onActions, onUpdates, onDeletes }}) {
    let { ids, onSelect } = values;
    let type = null;
    switch (onSelect){
      case 1:
        type = 'delete'
        break;
      case 2:
        type = 'hide'
        break;
    }
    let confirm = onActions?.confirm || (()=>()=>{})
    confirm({
      label: <><Notice /></>,
      children: <>
        <Box sx={{ width: '100%'}} className={'d-flex justify-content-center'}>
          <Box>
            <span>{[`link:popup.${type}.link`, `link:popup.${type}.links`]
              .oddeven(ids.length).t({ value: ids.length })}</span>
          </Box>
        </Box>
      </>,

      btnCancel: `link:links.actions.cancel`.t(),
      btnConfirm: [`link:form.${type}.btn.link`, `link:form.${type}.btn.links`]
        .oddeven(ids.length)
        .t({ value: '' }),

      handleSubmit: ({}, { props:{ onClose } })=>{
        let { ids, onSelect } = values || {}

        let value;

        if(onSelect === 1){
          value = onDeletes({ ids });
        }
        else if(onSelect === 2) {
          value = onUpdates({ids, input: { status: 'inactive' }});
        }

        if(value){
          value.then(()=>{
            entities && entities.refetch && entities.refetch()
            onClose && onClose();
          });
        }
      }
    })()
  },
  enableReinitialize: true,
  displayName: 'EntitiesDeleteForm', // helps with React DevTools
})((
  { actionFilter, setFieldValue, handleSubmit, onActions, onKeywordChange, onSelectChange,
    values
  }) =>{

  let { ids, defaultKey, keyword, onSelect } = values || { }


  let styles = MuiStyles({ onDel: onSelect === 1, onHide: onSelect === 2 });

  defaultKey = defaultKey || 'latest';

  const map = (({ title, key, ...rest})=>{
    return {
      ...rest,
      handleClick:()=> {
        onSelectChange(key);
      },
      title: () => (<Row none>
        <Col xs={'auto'}><Radio checked={(key === defaultKey)} /></Col>
        <Col className={'d-flex align-items-center'}>{title}</Col>
      </Row>)
    }
  });


  const titleMenus = [
    { key: `latest`,          title: 'domain:views.brand'.t() },
    { key: `az`,          title: `domain:views.az`.t()},
    { key: `za`,          title: 'domain:views.za'.t() }
  ].map(value=> map(value));


  const onClick = () =>{
    (onActions && onActions.Toolbars) && onActions.Toolbars(
      {
        enableClose: true,
        items: [ titleMenus ].map((items)=>({
          items , type: 'group',
          wrapper: ({ children })=><Box className={'btn-group-vertical'} sx={{ '&':{ width: '100%', '&> .btn': { padding:0}} }}>{children}</Box>,
        })),
        headerProps:{ style: { padding: '7px 0' } },
        title: 'domain:label.filter'.t() })()
  }

  let type = null;
  switch (onSelect){
    case 1: type = 'delete'; break;
    case 2: type = 'hide'; break;
  }

  return (<>
    <Box sx={styles}>
      <Row className={'control-form'} none>
        <Col>
          <Field
            {...{ onRemove: setFieldValue }}
            {...{
              className: 'keyword',
              placeholder: `search.keywords`.t(),
              component: RenderField,
              name: 'keyword',
              value: keyword,
              onChange: (value) => {
                onKeywordChange(value);
                setFieldValue('keyword', value)
              },
              startAdornment: <div style={{ width: '36px', height: '36px'}}>
                <Search />
              </div>
            }}
          />
        </Col>
        <Col xs={'auto'} className={'d-flex align-items-center'}>
          <Button onClick={onClick} className={'icon'} color={'default'}><Filter /></Button>
        </Col>
      </Row>
    </Box>
    {(!!onSelect) && <Box sx={{
      zIndex: 1000, position: 'absolute',
      bottom: 0, left: 0, right: 0, height: '50px',
      backgroundColor: '#ffffff',
      '& .btn-group':{
        '--btn-outline-border-secondary': '#f2f2f2',
        '& .btn':{
          '&:first-of-type':{
            borderLeft: 'none !important'
          },
          '&:last-of-type':{
            borderRight: 'none !important'
          }
        }
      },
    }}>
      <FormBase onSubmit={handleSubmit}>
        <ButtonGroup className={'w-100'}>
          <Button
            onClick={()=>setFieldValue('onSelect', 0)}
            {...btnProps}
            style={{ minHeight: '50px' }}
          >
            {`cancel`.t()}
          </Button>
          <Button
            type={'submit'}
            {...btnProps}
            disabled={!ids.length}
            style={{
              minHeight: '50px',
              '--btn-outline-secondary': '#C21C21',
            }}
          >
            {[`link:form.${type}.btn.link`, `link:form.${type}.btn.links`].oddeven(ids.length).t({ value: ids.length })}
          </Button>
        </ButtonGroup>
      </FormBase>
    </Box>}
  </>)
})


const FormWrapper =  withFormik({
  mapPropsToValues: ({ cookie }) =>
    ({ ids: [], count: 0, all: false, page: 1, onSelect: 0, defaultKey: cookie?.filter?.key }),
  enableReinitialize: true,
  displayName: 'EntitiesForm', // helps with React DevTools
})(({ children, ...props}) =>{
  let { onActions, entities, pageProps, setFieldValue, loadbar, values: { page } } = props;

  const innerRef = ()=>{
    (onActions['Filter']) && onActions['Filter']({ ...props, isOpen: true })
  }

  function getMore(items, page, reset = false) {
    entities.page = page;
    return entities.fetchMore(entities.variables, loadbar).then(async (res)=>{

      /**
       *
       */
      [...(res?.edges || [])].map(value=> items.push(Domain.instanse(value)));

      items = items.map((item)=>map(item));

      entities.update({ edges: items, count: res?.count }, reset)
      setFieldValue('page', page)
    });
  }

  /**
   * handle Page change
   * @param page
   * @returns {Promise<void>}
   */
  let handlePageChange = async (page) => {
    if (page !== entities.page) {
      let items = entities.items || [];
      await getMore(items, page);
    }
  }


  /**
   *
   * @param keyword
   */
  const onKeywordChange = (keyword)=>{
    // debug({ keyword })
    keyword = keyword || ``;
    entities.page = 1;

    entities.filter = {
      search: [{ keyword, type: 'all' }]
    }

    clearTimeout(timeoutKeyword);
    timeoutKeyword = setTimeout( async () => {
      setFieldValue('keyword', keyword);
      await getMore([], 1, true);
    }, 500);
  }


  /**
   * onSelectChange
   * @param key
   * @returns {Promise<void>}
   */
  const onSelectChange = async (key)=>{

    setFieldValue('defaultKey', key);

    let { orderBy, direction, status } = getFilterByKey(key);

    /**
     * End direction
     */

    entities.page = 1;
    entities.orderBy = orderBy;
    entities.direction = direction;
    entities.filter = { status };

    setCookie('domain_list_sort', key)
    await getMore([], 1, true);
  }

  pageProps = {
    ...entities.variables,
    ...pageProps,
    page,
    pageCount: entities.pageCount,
    collectionSize: entities.count,
    handlePageChange
  }

  Object.assign(props, { onKeywordChange, onSelectChange, parentFieldValue: setFieldValue, pageProps });

  return <>
    <Box ref={innerRef} sx={{ paddingTop: '0px', backgroundColor: '#f2f2f2', minHeight: 'calc(100%)' }}>
      {typeof children === 'function' && children(props)}
      {typeof children !== 'function' && children}
    </Box>
  </>
});

export default function (Component){
  return function (props){
    return <FormWrapper {...props}>{Component}</FormWrapper>
  }
}