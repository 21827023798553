import {graphql} from "react-apollo";
import CUSTOM_PAGE_UPDATE from "../graphql/CustomPageUpdate.graphql";
import loading from "@gqlapp/base/utils/loading";
import map from "@gqlapp/base/utils/map";

export const withCustomPageUpdate = graphql(CUSTOM_PAGE_UPDATE, {
  // @ts-ignore
  props: ({ mutate, ownProps: { loadbar } }: any) => ({
    pageUpdate: async (variables: any) =>
      loading(loadbar, async () =>
        map(
          map(await mutate({ variables }), ({ data }: any) => data),
          ({ res }: any) => res
        )
      )
  })
});

export default withCustomPageUpdate;