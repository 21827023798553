import withProps from 'recompose/withProps';
let timeout = null;
const withLoading = withProps((props) => {
  clearTimeout(timeout);
  timeout = setTimeout(() => {
    if (!props?.loading && props?.loadbar && props?.loadbar?.done) {
      props?.loadbar?.done();
    }
  }, 100);
});

export default withLoading;
