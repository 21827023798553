import React from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import MaterialIcon from '@mui/material/Icon';

const Icon = ({ id, tag, children, classNameIcon, className, ...props }) => {
  if(['material'].includes(tag)){
    tag = MaterialIcon;
  } else if(['icofont'].includes(tag)) {
    classNameIcon = clsx('icon', children, classNameIcon);
    children = (<><i { ...props} className={classNameIcon} /></>);
    tag = 'span';
  }

  return React.createElement(tag, { ...props , id, className }, children);
};

Icon.propTypes = {
  icon: PropTypes.any,
  classNameIcon: PropTypes.node
};

Icon.defaultProps = {
  tag: 'material',
  className: 'd-inline-flex'
};

export default Icon;
