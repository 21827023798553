import { graphql } from 'react-apollo';
import LOGOUT from "../graphql/Logout.graphql";
import map from '@gqlapp/base/utils/map';
import loading from '@gqlapp/base/utils/loading';

export default graphql(LOGOUT, {
  // @ts-ignore
  props: ({ mutate, ownProps: { loadbar } }) => ({
    logout: async () => loading(loadbar, async ()=> map(
      map(await mutate(), ({ data }: any)=> data),
      ({ logout:res }:any)=> res
    ))
  })
});
