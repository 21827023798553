import { graphql } from 'react-apollo';
import USERS_DELETE from "../graphql/UsersDelete.graphql";
import map from '@gqlapp/base/utils/map';
import loading from '@gqlapp/base/utils/loading';

const withUsersDeleting = (Component: any) =>
  graphql(USERS_DELETE, {
    props: ({ mutate, ownProps: { loadbar } }:any) => ({
      usersDelete: async (variables: any) => loading(loadbar, async ()=> map(
        map(await mutate({variables}),
          ({ data }: any)=> data),
        ({ res }:any)=> res
      ))
    })
  })(Component);

export default withUsersDeleting;
