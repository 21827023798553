import saveStatus from '@gqlapp/base/utils/saveStatus';

/**
 *
 * @param action
 * @param errorMsg
 */
export const onAction  = (action: any, { errorMsg }: any = {}) => async (input: any) => {
  let values = await action(input);
  // @ts-ignore
  return saveStatus({ values, errorMsg });
};

export default onAction;
