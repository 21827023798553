import React from 'react';
import * as PropTypes from "prop-types";
const Delete = ({...props}) => (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill={"currentColor"} {...props}>
    <>
        <path d="M40 0H0V40H40V0Z" fill="none"/>
        <path d="M13.111 27.778C13.1128 28.3667 13.3475 28.9308 13.7638 29.3472C14.1802 29.7635 14.7443 29.9982 15.333 30H24.222C24.8107 29.9982 25.3748 29.7635 25.7912 29.3472C26.2075 28.9308 26.4422 28.3667 26.444 27.778V14.444H13.111V27.778ZM27.556 11.111H23.667L22.556 10H17L15.889 11.111H12V13.333H27.556V11.111Z" fill="currentgColor"/>
    </>
</svg>);

Delete.propTypes = {
    width: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    height: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    className: PropTypes.node
}

Delete.defaultProps = {
    width: 40,
    height: 40
}

export default Delete;
