import { graphql } from 'react-apollo';
import mapInput from '@gqlapp/base/utils/mapInput';
import removeTypename from '@gqlapp/base/utils/removeTypename';
import compose from 'recompose/compose';
import withProps from 'recompose/withProps';

import GENS_QUERY from '../graphql/gensQuery.graphql'

const withGens  = (input:any)=> compose(
  withProps((props:any) => mapInput(input, props)),
  graphql(GENS_QUERY, {
    options: ({ page, pageSize, orderBy, direction, filter }:any) =>
      ({ variables: { page, pageSize, orderBy, direction, filter }}),
    props({
            ownProps:{ gens },
            data: { loading, res, refetch, error, updateQuery, fetchMore, subscribeToMore }
    }:any) {
      if(!loading && res){ gens.update(removeTypename(res)); }
      return { loading, gensRefetch:refetch, gensMore: fetchMore, gensSubscribeToMore: subscribeToMore, updateQuery, errors: error ? error.graphQLErrors : null };
    }
  }));

export default withGens;
export { GENS_QUERY }
