import React from 'react';
import * as PropTypes from "prop-types";
const IconRemove = (props) => (<>
    <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z" fill="#E2E2E2"/>
        <path d="M10.5 4.20525L9.79475 3.5L7 6.29475L4.20525 3.5L3.5 4.20525L6.29475 7L3.5 9.79475L4.20525 10.5L7 7.70525L9.79475 10.5L10.5 9.79475L7.70525 7L10.5 4.20525Z" fill="black"/>
    </svg>
</>);


IconRemove.propTypes = {
    width: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    height: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
}


IconRemove.defaultProps = {
    width: 14,
    height: 14
}

export default IconRemove;
