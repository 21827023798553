import isString from './../isString';
import isDefined from './../isDefined';

/** getCurrency
 * @param input
 * @param options
 */
export function formatNumber(input, options = null) {
  input = input || 0;
  if (isString(options)) {
    options = JSON.parse(options);
  } else if (!isDefined(options)) {
    options = { precision: 0, decimal_marker: '.', symbol: '', symbol_after: true, thousands_marker: ',' };
  }

  const c = isNaN((options.precision = Math.abs(options.precision))) ? 2 : options.precision;
  const d = options.decimal_marker === undefined ? '.' : options.decimal_marker;
  const t = options.thousands_marker === undefined ? ',' : options.thousands_marker;
  const s = input < 0 ? '-' : '';
  const i = String(parseInt((input = Math.abs(Number(input) || 0).toFixed(c)), 0));
  let j = i.length;
  j = j > 3 ? j % 3 : 0;

  return (
    s +
    (j ? i.substr(0, j) + t : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) +
    (c
      ? d +
      Math.abs(input - Number(i))
        .toFixed(c)
        .slice(2)
      : '')
  );
}

export default formatNumber;
