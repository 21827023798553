import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import withStyles from '@mui/styles/withStyles';
import Box from "@mui/material/Box";
const styles = {
  /* Styles applied to the root element. */
  root: {},
};

const View = React.forwardRef(function View({ children, id, classes, className, main, tag, ...props }, ref) {
  let component = tag || 'div'
  return React.createElement(Box, {
    ...props ,
    id,
    ref,
    component,
    className: clsx(classes.root, 'view', { 'view-main': main }, className),
  }, children);
});

View.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.data.json file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css) below for more details.
   */
  classes: PropTypes.object,
  /**
   * @ignore
   */
  className: PropTypes.string
};

export default withStyles(styles, { name: 'MuiView' })(View);
