import React from 'react';
import ClientModule from "@gqlapp/module-client-react";
import { map, PageRoute } from "./helpers/config";
import Route from "@xem/router/classes/Route";

import Page from './containers/PageContainer';

/**
 *
 * @param props
 * @param component
 * @param options
 * @param path
 */
function getProps(props: any, component: any, options: any, path: string = null){
  let getInitialProps = component?.getInitialProps;
  let newProps = {...props, ...options, component, getInitialProps}
  if(path){
    Object.assign(newProps, { path: map({ path }) })
  }
  return newProps
}

export default new ClientModule({
  route: {
    Page : <Route {...getProps(PageRoute, Page, { unloginedClass: '', classNameOnPage: 'theme-web' })} />
  }
});
