import Action from '@xem/entity/classes/Action';

const product = {
  list: '/products.html',
  listpage: '/products/:page/page.html',
};

export const map = ({ id, page, path }: any) => `${product[path]}`.replace(/:id/, id).replace(/:page/, page);


const perms = ['isAdmin'];
const styleConfigs = { style: { '--button-bar-height': '50px' } };
import settings from '@xem/config';

export const defaultRoute = {
  ...settings.route,
  action: Action.instanse(),
  runtimejs: true
};


export const ProductRoute = {
  ...defaultRoute,
  ...styleConfigs,
  perms,
  exact: true,
  webType: 'product',
  mobile: { show: true },
  classNameOnPage: 'cpanel theme-mobile'
};

export default product;
