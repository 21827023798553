import loading from "@gqlapp/base/utils/loading";

/**
 *
 * @param fetchMore
 */
export default function fetchMore(fetchMore: any) {
  return async (variables: any, loadbar?: any) =>
    new Promise(async (resolve, reject) => loading(loadbar, async ()=>{
      try{
        await fetchMore({
          variables,
          updateQuery: async (_: any, { fetchMoreResult: { res } }: any) => {
            res = res || [];
            resolve(res);
          }
        });
      }catch (e) {
        error(e);
        reject({});
      }
    }))
};
