import i18next, { i18n as I18N, Resource, InitOptions } from 'i18next';
import mergeRecursive from '@gqlapp/base/utils/merge/mergeRecursive';
import CommonModule from '@gqlapp/module-common';

import { initReactI18next } from 'react-i18next';
import settings from '@xem/config/i18n';

import LanguageDetector from './LanguageDetector';

const I18N_CONFIG: InitOptions = {
  resources: {},
  preload: settings.langList,
  detection: {
    lookupCookie: settings.cookie
  },
  react: {
    bindI18n: 'languageChanged',
    bindI18nStore: '',
    // @ts-ignore
    debug: false,
    transEmptyNodeValue: '',
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
    useSuspense: true
  },
  ...settings.init
};

if (['mobile', 'web'].indexOf(PLATFORM) >= 0) {
  if (PLATFORM === 'web') {
    (I18N_CONFIG.detection as any).caches = __SSR__ ? ['cookie'] : ['sessionStorage'];
  }
  I18N_CONFIG.interpolation = {
    escapeValue: false // not needed for React!!
  };
  // @ts-ignore
  I18N_CONFIG.react = { wait: false };
}

if (settings.enabled) {
  if (['mobile', 'web'].indexOf(PLATFORM) >= 0) {
    i18next.use(LanguageDetector);
  }

  i18next.use(initReactI18next).init(I18N_CONFIG);
}


/**
 * Adds resources into the i18next bundle
 *
 * @param i18n - i18next
 * @param resources - The resources to add
 */
const addResourcesI18n = (i18n: I18N, resources: Array<{ ns: string; resources: Resource; more?: string[] }>) => {
  const namespaces = {};
  // tslint:disable-next-line:no-shadowed-variable
  (resources || []).map(({ ns, resources }: any) => {
    namespaces[ns] = resources;
  });

  resources = (resources || []).map(({ more, resources: res, ...rest }) => {
    (more || []).map(key => {
      // @ts-ignore
      res = mergeRecursive(namespaces[key], res);
    });
    return { ...rest, resources: { ...res } };
  });

  let whitelist = settings.langList || []
  for (const localization of resources) {
    for (const lang of Object.keys(localization.resources)) {
      const resource = (whitelist as string[]).filter((lng: string) => lng.includes(lang));
      if (resource.length) {
        i18n.addResourceBundle(resource[0], localization.ns, localization.resources[lang]);
      }
    }
  }

};

export default new CommonModule({
  onAppCreate: [async (modules: CommonModule) => addResourcesI18n(i18next, modules.localizations)]
});
