import settings from '@xem/config';
import Action from "@xem/entity/classes/Action";

const link = {
	list: '/links.html',
	detail: '/link/:id/view.html',
	create: '/link/short-link.html',
	taxonomyView: '/campaign/:id/links.html'
}


export const map = ({ id, path }: any) => `${link[path]}`.replace(/:id/, id);


const defaultConfig = {
	...settings.route,
	load: false
}

export const userConfigLogined = {
	...defaultConfig,
	action: Action.instanse(),
	isDashboard: true,
	mobile: { show: true },
	redirectOnAnonymous: true,
	webType: 'cpanel',
	redirect: '/login.html',
	runtimejs: true,
	classNameOnPage: 'theme-mobile'
}

export const userConfigAnonymous = {
	...defaultConfig,
	load: true,
	isHome: true,
	loading: false,
	redirectOnLoggedIn: true,
	redirect: '/dashboard.html',
}
