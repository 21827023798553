import compose from "recompose/compose";
import withProps from "recompose/withProps";
import Domains from "./Domains";
import withDomains from "./withDomains";
import withDomainByRoot from "./withDomainByRoot";

export default compose(
  withProps(({ domains })=>{
    domains = Domains.instanse();
    domains.filter = {
      type: ['ns'],
      get_default: true,
      public: true
    }
    return { domains };
  }),
  withDomains(({ isOpen }: any)=>({ skip: !isOpen })),
  withDomainByRoot
)

