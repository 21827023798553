import loading from "@gqlapp/base/utils/loading";
import Stats from "./Stats";

/**
 * statsDeviceMore
 * @param stats
 * @param fetchMore
 * @param loadbar
 */
export const statsDeviceMore = (stats: Stats, fetchMore: any, { loadbar }: any) => async (variables: any, bar?: any) =>
  new Promise(async (resolve, reject) => loading((bar || loadbar), async()=>{
    try {
      await fetchMore({
        variables,
        updateQuery: async (_: any, { fetchMoreResult: { devices } }: any) => {
          try { stats.devices = devices;} catch (e) {error(e)}
          resolve(devices);
        }
      });
    }catch (e) {
      error(e);
      reject({});
    }
  }));

export default statsDeviceMore;
