import ClientModule from '@gqlapp/module-client-react';

// @ts-ignore
const map = ({ name, onClick, path, parent, value, ...rest }: any) => ({ query: { id } = {}, ...self }: any = {}, callback: any)=> callback({
	...rest,
	itemProps:{
		className: 'btn btn-link px-2 mr-2 ml-0'
	},
	weight: value,
	id: `menu-${name}`,
	// @ts-ignore
	name: `${name}`.t({}),
	// @ts-ignore
	title: `${name}`.t({}),
	className: ['m-0 p-0'],
	parent : parent || [{ targetId: 0 }]
})


const menus = [
	{
		weight: 1,
		name: 'shortlink',
		url: '/', onClick: null
	},
	{
		weight: 2,
		name: 'Social text',  onClick: null,
		url: '/bold-italic-facebook'
	}
].map(map)




// @ts-ignore
export default new ClientModule({
	menus:[
		{ positions: ['web.header', 'cpanel.header'], items: menus }
	]
})
