import { graphql } from 'react-apollo';
import mapInput from '@gqlapp/base/utils/mapInput';
import compose from 'recompose/compose';
import withProps from 'recompose/withProps';
import LINK_QUERY from '../graphql/linkQuery.graphql';
import Link from "./Link";

export { LINK_QUERY };

/**
 * PageSearch
 * @param subscribeToMore
 * @constructor
 */
export const linkLoad = (subscribeToMore: any) => (variables: any) =>
  new Promise(async resolve => {
    try {
      subscribeToMore({
        document: LINK_QUERY,
        variables: variables,
        updateQuery: async ( _: any, { subscriptionData: { data: { res } } }: any ) => {
          resolve(res);
        }
      });
    } catch (e) {
      resolve({});
    }
  });

export const queryLink =  graphql(LINK_QUERY, {
  skip: ({ skip, skipLink, path }: any) => {
    return !path || skip || skipLink
  },
  options: ({ path, fetchPolicy }: any) => {
    return ({
      fetchPolicy: fetchPolicy || null,
      variables: { path },
      // notifyOnNetworkStatusChange: true
    })
  },
  props({ data: { loading, res, refetch, error, subscribeToMore }, ownProps: { link } }: any & { ownProps: any & { link: Link }}) {
    (!!link) && (link.refetch = refetch);
    (!!link) && (link.subscribeToMore = subscribeToMore);

    // @ts-ignore
    if (!loading && res && link) {
      link.save(res);
    }

    return {
      loading,
      linkLoading: loading,
      errors: error ? error.graphQLErrors : null
    };
  }
});

/**
 * withLink
 * @param input
 */
export const withLink = (input?: any) => compose(
  withProps((props: any) => mapInput(input, props)),
  queryLink
)

export default withLink;
