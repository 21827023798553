import React from 'react';
import TextField from "./TextField";

let timeout = null;
/**
 *
 * @type {function(*=): *}
 */
function RenderField({ ...newProps }){
  const [status, setStatus] = React.useState(false);
  let { input, name, removeProps, onRemove } = newProps || {}
  let { onFocus, onBlur } = input || {}

  removeProps = removeProps || {}

  function switchStatus(value, time = 10){
    clearTimeout(timeout);
    timeout = setTimeout(()=>setStatus(value), time);
  }

  Object.assign(input, {
    onFocus: function (e){
      onFocus && onFocus(e);switchStatus(true);
    },
    onBlur: function (e){
      onBlur && onBlur(e);switchStatus(false);
    },
  })

  Object.assign(newProps, { input })

  try{
    if( typeof onRemove !== 'undefined' && !!status){
      Object.assign(removeProps, { onClick: () => onRemove(name, '') })
      Object.assign(newProps, { removeIcon: { removeProps } })
    }
  }catch (e) {
    debug('Field', name, e);
  }

  /**
   *
   */
  return (<TextField {...newProps} />)
}

export default RenderField;
