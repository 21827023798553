import React from "react";
import withProps from "recompose/withProps";
import compose from "recompose/compose";
import Popup from "@gqlapp/base/Modal/Popup";

function render(ref: any, type?: string, Render?: any){
  return withProps((props: any) => {
    let onGlobalRenders = props?.onGlobalRenders || {}
    onGlobalRenders[type] = (rest: any) =>
      <Popup {...props} {...rest} ref={ref} component={Render} />
    return { onGlobalRenders  }
  });
}

/**
 *
 * @param ref
 * @param rest
 */
function modalCallback(ref: any, rest: any){
  try { ref.current.open(rest); } catch (e) {}
}

/**
 * renderCallback
 * @param ref
 * @param type
 * @param Render
 */
export function callback(ref: any, type?: string, Render?: any) {
  return compose(
    render(ref, type, Render),
    withProps((props: any) => {
      let onActions = props?.onActions || {}
      onActions[type] = function (rest: any){
        return () => modalCallback(ref, rest);
      }
      return { onActions  }
    }));
}

/**
 * renderCallback
 * @param ref
 * @param type
 * @param Render
 * @param optProps
 */
export default function renderCallback(ref: any, type: string, Render: any, optProps?: any) {
  return compose(
    render(ref, type, Render),
    withProps((props: any) => {
    let onActions = props?.onActions || {}
    onActions[type] = (rest: any) => modalCallback(ref, { ...optProps, ...rest });
    return { onActions  }
  }));
}