import React from 'react';
import withProps from 'recompose/withProps';
import dynamic from 'next/dynamic';
import Popup from "@gqlapp/base/Modal/Popup";

const Form = dynamic(() => import('../components/form/RootAdd').then((c) => c.default));

const DataForm = (({ isOpen, ...props }) => (
  <>{!__SERVER__ && isOpen && <><Form {...props} isOpen={isOpen} /></>}</>));

export const withOnCreate = withProps((props) => {
  let ref = React.createRef();
  let onRenders = props?.onRenders || {}
  let onActions = props?.onActions || {}


  Object.assign(onActions, {
    domainRootAdd: (val) => {
      try { ref.current.open(val); } catch (e) {}
    }
  })

  Object.assign(onRenders, {
    domainRootAdd: (rest) => (
      <Popup
        {...props}
        {...rest}
        ref={ref}
        component={DataForm}
      />
    )
  })

  return {
    onRenders,
    onActions
  }
});

export default withOnCreate;
