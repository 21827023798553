
export default function filterByKeys(obj, keep, { isHttps }){
  const result = {};
  keep = keep || [];
  Object.keys(obj).map(key => {
    try {
      let value = obj[key];
      if (key === 'cookie') {
        try { value = value.replace('SSESS', 'SESS'); } catch (e) {}
        if (isHttps) {
          try { value = value.replace('SESS', 'SSESS');} catch (e) {}
        }
      }
      if (!!value && keep.includes(key)) {
        result[key] = value;
      }
    } catch (e) {}
  });
  return result;
}
