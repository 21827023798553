import React from 'react';
import withProps from 'recompose/withProps';
import dynamic from 'next/dynamic';
import Popup from "@gqlapp/base/Modal/Popup";

const Form = dynamic(() => import('../components/form/Assign').then((c) => c.default));

const DataForm = (({ isOpen, ...props }) => (
  <>{!__SERVER__ && isOpen && <><Form {...props} isOpen={isOpen} /></>}</>));

export const withOnAssign = withProps((props) => {
  let ref = React.createRef();
  let onRenders = props?.onRenders || {}
  let onActions = props?.onActions || {}

  Object.assign(onActions, {
    TaxonomyAssign: (val) => (rest)=>{
      try { ref.current.open({...val, ...rest}); } catch (e) {}
    }
  })

  Object.assign(onRenders, {
    TaxonomyAssign:  (rest) => (
      <Popup
        {...props}
        {...rest}
        ref={ref}
        component={DataForm}
      />
    )
  })

  return {
    onRenders,
    onActions
  }
});

export default withOnAssign;
