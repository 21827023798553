export default {
	load: (props) =>{

		let webType = props?.webType || 'web';
		let isDisableSwipeable = !!props?.isDisableSwipeable;

		let modalProps = {
			webType,
			onSwipedDown: function ({ to, findSnap, onClose }){
				if(typeof findSnap === 'function') {
					to = findSnap(to);
					if (to === 0) {
						// @ts-ignore
						onClose && onClose();
					}
				}
			},
			defaultSnap: ({ maxHeight })=> {
				return maxHeight
			},
			snapPoints: ({ maxHeight })=>{
				let points = [0];
				if(maxHeight){
					points.push(maxHeight);
				}
				return points;
			}
		}

		if(!isMobile() || isDisableSwipeable){
			Object.assign(modalProps, {
				disableHeaderSwipeable: true,
				disableBodySwipeable: true,
				disableFooterSwipeable: true
			});
		}

		if(webType === 'web'){
			Object.assign(modalProps, {
				webType,
				defaultSnap: ()=> null,
				snapPoints: ()=> [0]
			});
		}

		return modalProps
	}
}
