import React from 'react';
import resources from './locales';
import ClientModule from "@gqlapp/module-client-react";
import menu from "./helpers/menu";
import Route from "@xem/router/classes/Route";
import List from './containers/List';
import { TaxonomyRoute, map} from "./helpers/config";
import forms from './helpers/forms';
// @ts-ignore
let getInitialProps = List?.getInitialProps;
/**
 * @todo xyz
 */
export default new ClientModule(forms, menu, {
  route:{
    TaxonomyList        : <Route {...TaxonomyRoute} component={List} path={map({ path: 'list' })} {...{ webType: 'list', getInitialProps }} runtimejs />,
    TaxonomyListPage    : <Route {...TaxonomyRoute} component={List} path={map({ path: 'listpage', page: ':page' })} {...{ webType: 'list', getInitialProps }} runtimejs />,
  },
  localization: [{ ns: 'taxonomy', resources, more: ['common'] }]
});
