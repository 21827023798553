import { graphql } from 'react-apollo';
import USERS_UPDATE from "../graphql/UsersUpdate.graphql";
import loading from '@gqlapp/base/utils/loading';
import map from '@gqlapp/base/utils/map';

const withUsersUpdate = (Component: any) =>
  graphql(USERS_UPDATE, {
    props: ({ mutate, ownProps: { loadbar } }: any) => ({
      usersUpdate: async (variables: any) => loading(loadbar, async ()=> map(
        map(await mutate({variables}),
          ({ data }: any)=> data),
        ({ res }:any)=> res
      ))
    })
  })(Component);

export default withUsersUpdate;
