import Gen from './Gen';
import Entities from '@gqlapp/core-common/Entities';

export default class Gens extends Entities {
  constructor(props: any) {
    super(props);
    this.interface = Gen;
  }
  
  /**
   * instanse
   */
  public static instanse(props:any = {}) {
    return (new this(props)).save([]);
  }

}
