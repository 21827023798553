import React from 'react';
import ClientModule from '@gqlapp/module-client-react';
import IconLink from '../icons/Link.svg'
import IconListLink from '../icons/ListLink.svg'
import IconCampaigns from '../icons/Campaigns.svg'
import Row from "@gqlapp/base/Row";
import Col from "@gqlapp/base/Col";
import { map } from '../config'

const OtherMenus = [
  {
    id: 'link',
    weight: 1,
    type: 'group',
    // @ts-ignore
    label: 'link:shortlink'.t(),
    wrapper: (props)=><Row {...props} medium />,
  }
];

  [
    {
      id: 2,
      icon: <IconLink />,
      title: 'link:title.create', type: 'item',
      handleClick: (e, props)=>{
        const { onActions } = props || {}
        e.preventDefault();
        onActions && onActions.Create && onActions.Create({ 'title': `Create`.t()});
      }
    },

    { id: 3,
      icon: <IconListLink />,
      title: 'link:title.view',
      url: map({ path: 'list'}),
			path: 'list',
      handleClick: (e)=>{
        e.preventDefault();
        historyPush(map({ path: 'list'}))
      }
    },
    {
      id: 4,
      icon: <IconCampaigns />,
      title: 'Campaigns',
			url: map({ path: 'campaigns'}),
			path: 'campaigns',
      handleClick: (e)=>{
        e.preventDefault();
        historyPush(map({ path: 'campaigns'}))
      }
    }
  ]
    .map(({ weight,...value }, index)=>({
  ...value,
  weight: weight || index,
  type: 'item',
  parent: [{ targetId: 'link' }],
  wrapper: (props)=><Col {...props} xs={6} />,
}))
    .map(value=>OtherMenus.push(value))

export default new ClientModule({
  menus: [
    { positions: ['menu.mobile'], items: OtherMenus }
  ]
});
