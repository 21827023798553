import ClientModule from '@gqlapp/module-client-react';
import { mapMenu } from "../utils";
import React from "react";
import DomainBlackList from "../icons/DomainBlackList.svg";
import { map } from "../config";
import DomainWebrisks from "../icons/DomainWebrisks.svg";
const UserMap = (props)=> mapMenu({...props,
  perms: ['isAdminDomain', 'managerDomain', 'isAdmin'],
});


const UserMenus = [
  {
    id: 'WebriskList',
    name: 'Webrisk add',
    weight: 1,
    sx: { width: '60px !important' },
    content: (<DomainWebrisks width={'60px'} height={20} />),
    routeTypes: ['WebriskList'],
    onClick:(e, { onActions, action }) =>{
      e.preventDefault();
      (onActions && onActions.webriskCreate) && onActions.webriskCreate({
        refetch: () => {
          const callback = action.callback;
          // debug('#XXXXX', callback)
          if(typeof callback == 'function'){
            callback();
          }
        }
      })
    }
  },
  {
    id: 'domain_block',
    name: 'Blacklist domain',
    weight: 1002,
    sx: { width: '80px !important' },
    content: (<DomainBlackList width={'60px'} height={20} />),
    routeTypes: ['DomainList'],
    onClick:(e, {}) =>{
      e.preventDefault();
      historyPush(map({ path: 'list' }))
    }
  },
].map(UserMap)

export default new ClientModule({
  menus:[{ positions: ['bottom.mobile'], items: UserMenus }]
})
