import ClientModule from '@gqlapp/module-client-react';
import { mapMenu } from "../utils/utils";
import React from "react";
import ProductAdd from "../icons/ProductAdd.svg";
import StoreSetting from "../icons/Settings.svg";
import Box from "@mui/material/Box";

const UserMap = (props)=> mapMenu({...props,
  perms: ['isAdminProduct', 'managerProduct', 'isAdmin'],
});

let timeout = null;
const UserMenus = [
  {
    id: 'product_add',
    name: 'Add product',
    weight: 1,
    sx: { width: '80px !important' },
    content: (<ProductAdd width={'60px'} height={20} />),
    routeTypes: ['ProductList'],
    onClick:(e, { onActions, ...props }) =>{
      e.preventDefault();

      if(!props?.site?.storeId ){
        let confirm = ( props )=> {
          (onActions && onActions.confirm) && (onActions.confirm(props)())
        }

        confirm({
          handleSubmit: ({}, { props:{ onClose } })=>{
            onActions && onActions.storeSetting && onActions.storeSetting();
            clearTimeout(timeout);
            timeout = setTimeout(()=>{
              onClose && onClose();
            }, 200)
          },
          children: <>
            <Box sx={{ width: '100%'}} className={'d-flex justify-content-center'}>
              <Box><span>{`store:form.actions.product.errorNotity`.t()}</span></Box>
            </Box>
          </>,
          btnCancel: `store:form.actions.product.btnClose`.t(),
          btnConfirm: `store:form.actions.product.btnConfirm`.t(),
        })

      }else{
        onActions && onActions.productCreate && onActions.productCreate({ 'title': `product create`.t()});
      }
    }
  },
  {
    id: 'store-settings',
    name: 'Store settings',
    weight: 1002,
    sx: { width: '80px !important' },
    content: (<StoreSetting width={'60px'} height={20} />),
    routeTypes: ['ProductList'],
    onClick:(e, { onActions, store, ...props }) =>{
      e.preventDefault();
      onActions && onActions.storeSetting && onActions.storeSetting();
    }
  },
].map(UserMap)

export default new ClientModule({
  menus:[{ positions: ['bottom.mobile'], items: UserMenus }]
})
