import React from 'react';
import ClientModule from '@gqlapp/module-client-react';
import Col from "@gqlapp/base/Col";
import IconBlacklist from '../icons/Blacklist.svg'
import {map} from "../config";

const UserMenus = [
	{ id: 'webrisk',
		icon: <IconBlacklist />,
		title: 'Blacklist Domain',
		handleClick: ()=>{
			historyPush(map({ path: 'list' }))
		}
	}
]
	.map(({ weight, ...value }, index)=>({
		...value,
		perms: ['isAdmin'],
		weight: weight || index,
		type: 'item',
		parent: [{ targetId: 'settings' }],
		wrapper: (props)=><Col {...props} xs={6} />
	}))


export default new ClientModule({
	menus:[
		{ positions: ['menu.mobile'], items: UserMenus }
	]
})
