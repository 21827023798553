
export interface ImageInterface {
  title: string;
  big: any;
  large: any;
  thumb: any;
  width: number;
  height: number;
  id: number;
}


export class Image implements ImageInterface {
  public big    : any = { url: '', width: 0, height: 0 };
  public large  : any = { url: '' };
  public thumb  : any = { url: '' };
  public title  : string;
  public width  : number = 0;
  public height : number = 0;
  public id    : number = 0;
}
export default Image;