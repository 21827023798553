import { graphql } from 'react-apollo';
import GEN_DELETE from "../graphql/GenDelete.graphql";
import loading from "@gqlapp/base/utils/loading";
import map from "@gqlapp/base/utils/map";

/**
 *
 */
export const withGenDeleting = graphql(GEN_DELETE, {
  props: ({ mutate, ownProps: { loadbar } }: any) => ({
    genDelete: async (variables: any) =>
      loading(loadbar, async () =>
        map(
          map(await mutate({ variables }), ({ data }: any) => data),
          ({ res }: any) => res
        ))
  })
});

export default withGenDeleting;
