import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { tagPropType, mapToCssModules } from '../utils/utils'
import Box from "@mui/material/Box";
const rowColWidths = ['xs', 'sm', 'md', 'lg', 'xl', 'vl'];
const rowColsPropType = PropTypes.oneOfType([PropTypes.number, PropTypes.string]);

const propTypes = {
  tag: tagPropType,
  noGutters: PropTypes.bool,
  className: PropTypes.string,
  cssModule: PropTypes.object,
  form: PropTypes.bool,

  none: PropTypes.bool,
  mini: PropTypes.bool,
  small: PropTypes.bool,
  big: PropTypes.bool,
  medium: PropTypes.bool,

  xs: rowColsPropType,
  sm: rowColsPropType,
  md: rowColsPropType,
  lg: rowColsPropType,
  xl: rowColsPropType,
  vl: rowColsPropType
};


const defaultProps = {
  tag: Box,
  widths: rowColWidths
};


const Row = React.forwardRef(function Row({ children, id, tag: Tag, widths,...props }, ref) {
  const {
    className,
    cssModule,
    noGutters,
    form,
    big,
    small,
    mini,
    medium,
    none,
    ...attributes
  } = props;

  const colClasses = [];

  widths.forEach((colWidth, i) => {
    let colSize = props[colWidth];

    delete attributes[colWidth];

    if (!colSize) {
      return;
    }

    const isXs = !i;
    colClasses.push(isXs ? `row-cols-${colSize}` : `row-cols-${colWidth}-${colSize}`);
  });

  const classes = mapToCssModules(clsx(
    {'row-big': big}, {'row-mini': mini}, {'row-small': small}, {'row-medium': medium}, {'row-none': none},
    className,
    noGutters ? 'no-gutters' : null,
    form ? 'form-row' : 'row',
    colClasses
  ), cssModule);

  return React.createElement(Tag, {
    ...attributes ,
    id,
    ref,
    className: classes,
  }, children);
});

Row.propTypes = propTypes;
Row.defaultProps = defaultProps;

export default Row;
