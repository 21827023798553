import React from "react";
import ClientModule from "@gqlapp/module-client-react";
import Row from "@gqlapp/base/Row";
import Col from "@gqlapp/base/Col";
import IconListUser from "../icons/List.svg";

const UserMenus = [
	{
		id: 'settings',
		weight: 1000,
		type: 'group',
		// @ts-ignore
		label: 'Settings',
		wrapper: (props)=><Row {...props} medium />,
	}
];

[
	{ id: 2000,
		icon: <IconListUser />,
		weight: 2000,
		perms: ['isAdminUsers', 'isAdmin'],
		title: 'List Account',
		handleClick: ()=>historyPush('/users.html')
	}
]
	.map(({ weight,...value }: any, index)=>({
		...value,
		weight: weight || index,
		type: 'item',
		parent: [{ targetId: 'settings' }],
		wrapper: (props)=><Col key={index} {...props} xs={6} />,
	}))
	.map(value=>UserMenus.push(value))



export default new ClientModule({
	menus:[{ positions: ['menu.mobile'], items: UserMenus }]
});
