import {graphql} from "react-apollo";
import WEBRISKS_DELETE from "../graphql/webrisksDelete.graphql";

/**
 *
 * @param Component
 */
export const withWebrisksDeleting = graphql(WEBRISKS_DELETE, {
  props: ({ mutate }) => ({
    webrisksDelete: async (ids: number[]) => mutate({ variables: { ids } })
  })
});

export default withWebrisksDeleting;
