import React from 'react';
import resources from './locales';

import { GenRoute, map } from "./helpers/config";
import Route from "@xem/router/classes/Route";
import List from './containers/ListContainer';
import Edit from './containers/EditContainer';
import ClientModule from "@gqlapp/module-client-react";

/**
 *
 * @param props
 * @param component
 * @param options
 * @param path
 * @param id
 */
function getProps(props: any, component: any, options: any, path: string = null, id?: string){
  let getInitialProps = component?.getInitialProps;
  let newProps = {...props, ...options, component, getInitialProps}
  if(path){
    Object.assign(newProps, { path: map({ path, id }) })
  }
  return newProps
}

let options = {
  classNameOnPage: null,  className: null, webType: 'gen', style:{ '--page-header-height': '0px !important' }
}

export default new ClientModule({
  route: {
    GenList : <Route {...getProps(GenRoute, List, {},'list')} />,
    GenEdit : <Route {...getProps(GenRoute, Edit, options,'edit', ':id')} />,
    GenCreate : <Route {...getProps(GenRoute, Edit, options,'create')} />
  },
  localization: [{ ns: 'gen', resources, more: ['common'] }]
});
