import React from 'react';
import withProps from 'recompose/withProps';
import dynamic from 'next/dynamic';
import Popup from "@gqlapp/base/Modal/Popup";

const Form = dynamic(() => import('../components/form/Merge').then((c) => c.default));

const DataForm = (({ isOpen, ...props }) => (
  <>{!__SERVER__ && isOpen && <><Form {...props} isOpen={isOpen} /></>}</>));

export const withOnCreate = withProps((props) => {
  let ref = React.createRef();
  let onRenders = props?.onRenders || {}
  let onActions = props?.onActions || {}

  let Render = (rest) => (
    <Popup
      {...props}
      {...rest}
      ref={ref}
      component={DataForm}
    />
  );

  let Action = (val) => (rest)=>{
    try { ref.current.open({...val, ...rest}); } catch (e) {}
  }

  Object.assign(onActions, {
    TaxonomyMerge: Action
  })

  Object.assign(onRenders, {
    TaxonomyMerge: Render
  })

  return {
    onRenders,
    onActions
  }
});

export default withOnCreate;
