const stripDiacritics = require('@gqlapp/base/utils/diacritics').stripDiacritics


/**
 *
 * @returns {*[]}
 */
Array.prototype.unique = function () {
  return [...new Set(this)];
};


/**
 * Array translate
 * @returns {string}
 */
Array.prototype.oddeven = function (value) {
  if (this.length === 2) {
    value = value || 0;
    let values = this;
    let val = values[value !== 1 ? 1 : 0];
    return `${val}`;
  }
  return ``;
};


/**
 * remove element form
 * @param element
 * @returns {*}
 */
Array.prototype.remove = function (element) {
  function removeItemAll(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }
  return removeItemAll(this, element)
}

/**
 * Array filter by keyword
 * @param keywords
 * @param config
 * @returns {*[]}
 */
Array.prototype.f = function (keywords, config) {

  keywords = keywords || '';

  const trimString = (str) => `${str}`.replace(/^\s+|\s+$/g, '');

  const defaultStringify = (option) => {
    let type = typeof option;
    switch (type) {
      case 'object':
        return `${option.label} ${option.value}`
      case 'string':
        return `${option}`
      default:
        return ``
    }
  }

  const { ignoreCase, ignoreAccents, stringify, trim, matchFrom } = {
    ignoreCase: true,
    ignoreAccents: true,
    stringify: defaultStringify,
    trim: true,
    matchFrom: 'any',
    ...config,
  };

  let input = trim ? trimString(keywords) : keywords;
  input = input || ``;
  let arrs = this;
  arrs = arrs.filter(value => {
    let candidate = trim ? trimString(stringify(value)) : stringify(value);
    candidate = candidate || ``;

    if (ignoreCase) {
      input = input.toLowerCase();
      candidate = candidate ? candidate.toLowerCase(): candidate;
    }

    if (ignoreAccents) {
      input = stripDiacritics(input);
      candidate = stripDiacritics(candidate);
    }

    switch (matchFrom){
      case 'equal':
        return candidate === input;
      case 'start':
        return candidate.substr(0, input.length) === input;
      default:
        return candidate.indexOf(input) > -1;
    }
  });
  return arrs;
}
