import saveStatus from '@gqlapp/base/utils/saveStatus';

/**
 *
 * @param action
 * @param errorMsg
 */
const onAction = ({ action, errorMsg }: { action: any, errorMsg?: string }) => async (input?:any) => {
  let values = await action(input);
  // @ts-ignore
  return saveStatus({ values, errorMsg });
};

export default onAction;
