import { graphql } from 'react-apollo';
import mapInput from '@gqlapp/base/utils/mapInput';
import removeTypename from '@gqlapp/base/utils/removeTypename';
import compose from 'recompose/compose';
import withProps from 'recompose/withProps';

import GEN_QUERY from "../graphql/GenQuery.graphql";

const withGen = (input:any)=> compose(
  withProps((props:any) => mapInput(input, props)),
  graphql(GEN_QUERY, {
    skip: ({ entity_id }: any) => !entity_id,
    options: ({ entity_id, fetchPolicy }: any) =>({ fetchPolicy, variables: { id: parseInt(entity_id) } }),
    props({ data: { loading, res, refetch, error }, ownProps:{ gen }}:any) {
      if(!loading && res){
        gen.save(removeTypename(res));
      }
      return { loading, genRefetch: refetch, errors: error ? error.graphQLErrors : null };
    }
  }));

export { GEN_QUERY }
export default withGen;
