import ClientModule from '@gqlapp/module-client-react';
import { mapMenu } from "./utils";
import React from "react";
import IconHome from "../icons/home.svg";
import IconPage from '@xem/icon/footer/Page.svg'

const UserMap = (props)=> mapMenu({...props,
  perms: ['isMember']
});


const UserMenus = [
  {
    id: 'home',
    name: 'home',
    weight: 1,
    sx: { width: '60px !important' },
    content: (<IconHome width={'60px'} height={20} />),
    withoutRouteTypes: ['LinkDetail', 'Dashboard'],
    onClick:(e, {}) =>{
      e.preventDefault();
      try{ historyPush('/') }catch (e) { console.warn(e); }
    }
  },
  {
    id: 'biopage',
    name: 'BIO page',
    weight: 1002,
    sx: { width: '60px !important' },
    content: (<IconPage width={'60px'} height={20} />),
    routeTypes: ['Dashboard', 'LinkList', 'TaxonomyList', 'TaxonomyLinks', 'TaxonomyListPage'],
    withoutRouteTypes: ['LinkDetail', 'DomainList', 'WebriskList', 'UserList'],
    onClick:(e, {}) =>{
      e.preventDefault();
      try{ historyPush('/pages.html') }catch (e) { console.warn(e); }
      // msg({ message: 'Under contract'.t()})
    }
  },
].map(UserMap)

export default new ClientModule({
  menus:[{ positions: ['bottom.mobile'], items: UserMenus }]
})
