import React from "react";
import ClientModule from '@gqlapp/module-client-react';
import Menu from '@xem/icon/footer/Menu.svg'

// @ts-ignore
const map = ({ id: mid, name, onClick, path, parent, value, ...rest }) =>
	({ query: { id } = {}, ...self } = {}, callback)=> callback({
	...rest,
		id: mid || name,
		name: (typeof name !== 'undefined') ? <div style={{ minWidth: '60px', lineHeight: '10px', fontSize: '10px', padding: '4px 5px 0' }}>{`${name}`.t()}</div>: null,
		onClick,
		perms: ['isMember'],
		parent : parent || [{ targetId: 0 }],
		className: ['m-0 p-0'],
		itemProps:{
			className: 'btn btn-link px-2 mr-2 ml-0'
		}
})


const menus = [
	{
		name: 'menu',
		weight: 10000000,
		sx: { width: '60px !important' },
		content: (<Menu width={'60px'} height={20} />),
		onClick: (e, { onActions })=>{
			e.preventDefault();
			// @ts-ignore
			onActions && onActions.Menu && onActions.Menu({ 'title': `Menu`.t() });
		}
	}
].map(map)




// @ts-ignore
export default new ClientModule({
	menus:[
		{ positions: ['bottom.mobile'], items: menus }
	]
})
