export class Webrisk {
  private _id: number;
  private _path: string;
  private _weight: number;
  private _created: number;
  private _type: string;

  public static instanse(data?: any) {
    return new this().save(data || {});
  }

  /**
   *
   * @param id
   * @param path
   * @param weight
   * @param type
   * @param created
   */
  public save({
    id,
    path,
    weight,
    type,
    created
  }: any) {
    this._id = id;
    this._path = path;
    this._weight = weight;
    this._created = created;
    this._type = type;
    return this;
  }

  get id() {
    return this._id || 0;
  }
  get path() {
    return this._path || '';
  }

  get type() {
    return this._type;
  }

  get weight() {
    return this._weight || 0;
  }

  get created() {
    return this._created * 1000 || null;
  }
}

export default Webrisk;
