import Base from "@xem/entity/classes/Base";
import isArray from "@gqlapp/base/utils/isArray";


export class Page extends Base {
  public data: any;
  public blocks: Map<string, any>;
  public settings: any;
  status: string = 'active';

  public body: string
  /**
   * instanse
   */
  public static instanse(data?:any) {
    return new this().save(data || {});
  }

  /**
   * save
   */
  public save(_value: any): this {
    super.save(_value);
    let self = this;
    
    let { data, body, status }: any= _value || {}
    let { blocks: _blocks, settings }: any= data || {};
    let blocks = new Map();

    if(_blocks && _blocks.length){
      _blocks.map(({ key, value })=>{
        if(isArray(value?.settings)){
          value.settings = {}
        }
        blocks.set(key, value);
      })
    }

    if(status === 0){
      this.status = 'inactive';
    }
    else if(status === -1){
      this.status = 'blocked';
    }

    self.blocks = blocks;
    self.settings = settings;

    self.body = body;
    return self;
  }
}

export default Page;