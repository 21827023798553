import React from "react";
import dynamic from "next/dynamic";
import renderCallback from "@xem/entity/classes/renderCallbackD";

const Form = dynamic(() => import('./Form').then((c) => c.default));

const DataForm = ((props) => (
	<>{!__SERVER__ && (!!props?.isOpen) && <><Form {...props} /></>}</>));

let ref = React.createRef();
export default renderCallback(ref, 'setIcon', DataForm)