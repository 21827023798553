import * as React from "react";
import BaseModule, { BaseModuleShape } from './BaseModule';
import { foldTo } from 'fractal-objects';
import { ApolloLinkStateParams } from '@gqlapp/module-common';
interface BlockShape {
  component: any;
  position?: string;
  positions?: string[];
  weight?: number;
}

interface MenuShape {
  items: any[];
  position?: string;
  positions?: string[];
  weight?: number;
}


interface AcionShape {
  name: string;
  func: any;
}

/**
 * React client feature modules interface.
 */
export interface ClientModuleShape extends BaseModuleShape {
  // Route list
  route?: any;
  // withPreProps
  withPreProps?: any[];
  // Popup Components
  popupComponents?: any[];
  // Route Type
  routeType?: any;
  // Regex
  regex?: any[];
  // nids
  nids?: number[];
  // Block list to 3rd party theme
  blocks?: BlockShape[];
  //
  shareContext?: { [key: string]: any };
  // URL list to 3rd party css styles
  menus?: MenuShape[];
  // routes
  alias?: any[];
  // routes
  actions?: AcionShape[];
  // Apollo Link State default state and client resolvers
  resolver?: ApolloLinkStateParams[];
}

// @ts-ignore
interface ClientModule extends ClientModuleShape {}

/**
 * React client feature module implementation.
 */
class ClientModule extends BaseModule {
  /**
   * Constructs React client feature module representation, that folds all the feature modules
   * into a single module represented by this instance.
   *
   * @param modules feature modules
   */
  constructor(...modules: ClientModuleShape[]) {
    super(...modules);
    foldTo(this, modules);
  }


  /**
   *
   * @param positions
   */
  public getPopup(positions: string[]) {
    const popupComponents = this.popupComponents || [];
    return popupComponents
      .filter(content => {
        let isValidate = false;
        positions.map((p) =>{
          const poss: string[] = content.positions || [];
          if(poss.includes(p)){
            isValidate = true;
          }
        })
        return !content.positions  || isValidate;
      })
      .map(content => content.component);
  }

  /**
   * Type by Route
   */
  get typeByRoute(){
    let type = this.props?.route_type;
    if(!Object.keys(this.route).includes(type)){
      type = 'PageNotFound';
    }
    return type;
  }


  /**
   * @returns client-side React route components list
   */
  get routes() {
    let type = this.typeByRoute;
    // @ts-ignore
    return  React.cloneElement(this.route[type], {...this.props, $self: this.self})
  }

  /**
   * @returns client-side React route components list
   */
  get preload() {
    return this.withPreProps;
  }

  /**
   * getRouteProps
   */
  get getRouteProps(){
    let type = this.typeByRoute;

    let component = this.route[type];
    if((component) && (component.props)){
      return component.props;
    }
    return {}
  }

  /**
   * getBlock
   * @param positions
   * @param props
   * @returns blocTk
   */
  public getBlock(positions: string[], props: any = {}) {
    const blocks = this.blocks || [];
    const contexts = this.shareContext || {};
    return blocks
      .filter(block => {
        let isValidate = false;
        positions.map((p) =>{
          const poss: string[] = block.positions || [];
          const pos: string = block.position;
          if(poss.includes(p) || pos === p){
            isValidate = true;
          }
        })

        return isValidate;
      })
      .sort((a, b) => a.weight - b.weight)
      .map(block => block.component)
      .map((component: React.ReactElement<any>, idx: number, items: Array<React.ReactElement<any>>) =>
        // @ts-ignore
        React.createElement(component, {
          ...props,
          ...contexts,
          $self: this.self,
          key: component.key || idx + items.length
        })
      );
  }

  /**
   * menuMain
   */
  public getMenu(position: string) {
    const menus: any[] = [];
    (this.menus || [])
      .filter(({ positions: poss, position: pos }) => (poss || []).includes(position) || position === pos )
      .sort((a, b) => a.weight - b.weight)
      .map(({ items }) => items)
      .map(items => (items || []).map(item => menus.push(item)));
    return menus;
  }

  /**
   *
   * @param name
   */
  public callback(name: any){
    let func = ()=>{} ;
    try{
      func = [...this
        .actions
        .filter(({ name: n })=> name == n)]
        .shift()
        .func;

    }catch (e) {}
    return func;
  }

  /**
   * Get client module
   */
  get self() {
    return this;
  }
}

export default ClientModule;
