import compose from "recompose/compose";
import withOnFilter from "./withOnFilter";
import withOnUserAdd from "./withOnUserAdd";
import withOnUserRole from "./withOnUserRole";
import withRenderTools from "@xem/look/Renders/Tools/withTools";
import withRenderProcess from '@xem/entity/classes/withRenderProcess';

export default compose(
	withRenderTools,
	withOnUserAdd,
	withOnFilter,
	withOnUserRole,
	withRenderProcess()
)
