import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();

export default {
  rest: '/graphql/',
  domainCookieAll : publicRuntimeConfig.domainCookieAll,
  slug: publicRuntimeConfig.slug,
  api: publicRuntimeConfig.api,
  server: publicRuntimeConfig.api_server,
  upload: publicRuntimeConfig.upload,
  logging: {
    level: (process.env.npm_config_argv || '').search(/(dev|start)/) >= 0 ? 'debug' : 'info',
    debugSQL: process.env.DEBUG_SQL || false,
    apolloLogging: (process.env.npm_config_argv || '').search(/(devx)/) >= 0
  },
  // Check here for Windows and Mac OS X: https://code.visualstudio.com/docs/editor/command-line#_opening-vs-code-with-urls
  // Use this protocol handler for Linux: https://github.com/sysgears/vscode-handler
  stackFragmentFormat: 'vscode://file/{0}:{1}:{2}'
};
