class Action{
  name = null;

  constructor(name?: string) {
    this.name = name
  }

  /**
   *
   */
  public static instanse(name?:string) {
    return (new this(name));
  }

  _callback: any = ()=> { };

  set callback(value: any){
    this._callback = value
  }

  get callback(){
    return this._callback;
  }


  set set(value: any){
    this._callback = value
  }

  get get(){
    return this._callback;
  }
}

export default Action;