import { graphql } from 'react-apollo';
import DOMAIN_BY_ROOT from '../graphql/DomainByRoot.graphql';
import map from '@gqlapp/base/utils/map';

/**
 * withLinkCreate
 * @param Component
 */
export const withDomainByRoot = graphql(DOMAIN_BY_ROOT, {
    props: ({ mutate }: any) => ({
      domainByRoot: async (variables: any) =>{
        return map(
          map(await mutate({ variables }), ({ data }: any) => data),
          ({ res }: any) => res
        )
      }
    })
  });

export default withDomainByRoot;
