import { graphql } from 'react-apollo';
import LINK_CREATE from '../graphql/linkCreate.graphql';
import map from '@gqlapp/base/utils/map';
import loading from '@gqlapp/base/utils/loading';

/**
 * Create new Link
 * @param Component
 */
export const withLinkCreate = (Component: any) =>
  graphql(LINK_CREATE, {
    props: ({ mutate, ownProps: { loadbar } }: any) => ({
      linkCreate: async (variables: any) =>
        loading(loadbar, async () =>
          map(
            map(await mutate({ variables }), ({ data }: any) => data),
            ({ linkCreate: res }: any) => res
          )
        )
    })
  })(Component);

export default withLinkCreate;
