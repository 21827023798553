const types = require('./utils/types');
const UAParser = require('ua-parser-js/dist/ua-parser.min');
const useMediaQuery = require("@mui/material/useMediaQuery").default;
const useTheme = require("@mui/material/styles").useTheme;

ClientUAInstance = new UAParser();
setUserAgent= (agent) => ClientUAInstance.setUA(agent);
isBrowser   = () => !!types.isBrowserType(ClientUAInstance.getDevice());
isSafari    = () => !!types.isSafariType(ClientUAInstance.getBrowser());
isChrome    = () => !!types.isChromeType(ClientUAInstance.getBrowser());
isAndroid   = () => !!types.isAndroidType(ClientUAInstance.getOS());
isIOS       = () => !!types.isIOSType(ClientUAInstance.getOS());
isMobile    = () => isAndroid() || isIOS() || !!types.isMobileAndTabletType(ClientUAInstance.getDevice());
media       = (key) => {
  return useMediaQuery(useTheme().breakpoints.up(key || 'lg'));
}