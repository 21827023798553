import { default as Webrisk } from './Webrisk';
import Entities from '@gqlapp/core-common/Entities';
import fetchMore from '@xem/entity/classes/fetchMore';


export class Webrisks extends Entities {

  public _fetchMore: any = null;

  set fetchMore(value: any){
    this._fetchMore = value;
  }

  get fetchMore(){
    return fetchMore(this._fetchMore);
  }


  constructor(props?:any) {
    super(props);
    this.interface = Webrisk;
  }
  
  /**
   * instanse
   */
  static instanse(props?:any){
    return new this(props);
  }
  
  get webrisks(){
    return this.items.map(({ id, scheme, hostname}: any) => ({
      value: id, label: `${scheme}://${hostname}`
    }));
  }
  
  get value(){
    return this.items.filter(({is_default}: any)=> !!is_default ).map(({ id, scheme, hostname}: any) => ({
      value: id, label: `${scheme}://${hostname}`
    })).shift();
  }
}

export default Webrisks;
