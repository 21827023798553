import React from 'react';
import * as PropTypes from "prop-types";
const IconColor = ({...props}) => {
    return (<>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" fill={'currentColor'} {...props}>
            <rect x="0" y="0" width="100" height="100" fill={'currentColor'}/>
        </svg>
    </>)
};


IconColor.propTypes = {
    width: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    height: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    className: PropTypes.node
}


IconColor.defaultProps = {
    width: 14,
    height: 14
}

export default IconColor;
