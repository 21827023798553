import Action from '@xem/entity/classes/Action';

const link = {
  list: '/links.html',
  listpage: '/links/:page/page.html',
  hidden: '/hidden.html',
  detail: '/link/:id/view.html',
  edit: '/node/:id',
  taxonomyView: '/campaign/:id/links.html',
	campaigns: '/campaigns.html'
}

export const map = ({ id, page, path }: any) => `${link[path]}`.replace(/:id/, id).replace(/:page/, page);


const perms = ['isMember'];
const styleConfigs = { style: { '--button-bar-height': '50px' } };
import settings from '@xem/config';

export const defaultRoute = {
  ...settings.route,
  action: Action.instanse()
};


export const LinkRoute = {
  ...settings.route,
  ...styleConfigs,
  perms,
  runtimejs: true,
  exact: true,
  webType: 'link',
  mobile: { show: true },
  classNameOnPage: 'cpanel theme-mobile',
  action: Action.instanse()
};

export default link;
