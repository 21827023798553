import { graphql } from 'react-apollo';
import { compose, withProps} from "recompose";
import mapInput from "@gqlapp/base/utils/mapInput";

import STATS_LOCATION_QUERY from "../graphql/StatsLocation.graphql";

import statsLocationMore from "./Stats/statsLocationMore";

export const withStatsLocation = (input?: any) => (Component: any) =>
	compose(
		withProps((props: any) => mapInput(input, props)),
		graphql(STATS_LOCATION_QUERY, {
			skip: ({ skip }: any) => !!skip,
			options: ({ fetchPolicy, id, type, dateType }: any = {}) => ({
				fetchPolicy, variables: { id: id || null, type, dateType }
			}),
			props({ data: { loading, locations, fetchMore, error }, ownProps, ownProps: { stats } }: any) {
				if (!loading) {
					stats.locations = locations;
				}
				return { loading, statsLocationMore: statsLocationMore(stats, fetchMore, ownProps), errors: error ? error.graphQLErrors : null };
			}
		})
	)(Component);

export default withStatsLocation;
