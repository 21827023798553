import React from 'react';
import * as PropTypes from "prop-types";

const IconArrow = ({...props}) => (<>
    <svg viewBox="0 0 30 30" {...props} style={{ color: '#ffffff'}}>
        <g transform="translate(-628 -15)">
            <rect width="30" height="30" rx="15" transform="translate(628 15)" fill="currentColor" />
            <path d="M13.81,12.106,11.083,9.38a.656.656,0,0,0-.465-.191h-.446a5.685,5.685,0,1,0-.984.984v.446a.656.656,0,0,0,.191.465l2.726,2.726a.654.654,0,0,0,.927,0l.774-.774A.659.659,0,0,0,13.81,12.106ZM5.688,9.188a3.5,3.5,0,1,1,3.5-3.5A3.5,3.5,0,0,1,5.688,9.188Z" transform="translate(636 23)" fill="#212121" />
        </g>
    </svg>
</>);


IconArrow.propTypes = {
    width: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    height: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    className: PropTypes.node
}


IconArrow.defaultProps = {
    width: 30,
    height: 30
}

export default IconArrow;
