import React from "react";
import withProps from "recompose/withProps";
import Popup from "@gqlapp/base/Modal/Popup";

/**
 *
 * @param ref
 * @param typeCallback
 * @param Render
 * @param optRest
 */
// @ts-ignore
export default function renderCallback(ref?: any, typeCallback: string, Render: any, optRest?: any) {
  return withProps((props: any) => {
    let onRenders = props?.onRenders || {}
    let onActions = props?.onActions || {}

    onActions[typeCallback] = function (rest: any){
      try { ref.current.open({ ...rest, ...optRest}); } catch (e) {}
    }

    onRenders[typeCallback] = function (rest: any){
      return <Popup
        {...props}
        {...rest}
        ref={ref}
        component={Render}
      />
    }

    return { onRenders, onActions  }
  });
}