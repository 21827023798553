import React from 'react';
import ClientModule from "@gqlapp/module-client-react";
import HeadSide from "./components/head/HeadSide";
import CpanelHeadSide from "./components/head/Cpanel.HeadSide";
import FooterSide from "./components/FooterSide";
// @ts-ignore
import BottomComponent from "./components/bottom/BottomSide";

import popupComponents from './components/toolbars';
// @ts-ignore
const Bottom = (props: any) => React.createElement(BottomComponent, {...props, className: 'bottom-toolbar'});
// @ts-ignore
const BottomSide = (props: any) => React.createElement(BottomComponent, {...props, className: 'bg-white', style: { height: '50px'}});

export default new ClientModule({
	popupComponents,
	blocks: [
		{
			positions: [ 'web.head', 'textsocial.head' ],
			component: HeadSide, weight: -1
		},
		{
			positions: [ 'cpanel.head', 'link.head', 'list.head', 'locked.head', 'linkview.head', 'page-not-found.head'],
			component: CpanelHeadSide, weight: -1
		},
		
		{
			positions: [ 'web.footer','textsocial.footer'],
			component: FooterSide
		},
		{
			positions: [ 'web.bottom', 'textsocial.bottom'],
			component: Bottom
		},
		{
			positions: [ 'cpanel.bottom',  'list.bottom' ],
			component: BottomSide
		}
	]
});
