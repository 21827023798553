import React from 'react';
import * as PropTypes from "prop-types";
const IconEye = ({ locked, ...props}) => (<>
    { (!!locked)&&<>
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
            <g id="eye_on" data-name="eye on" transform="translate(-319 -211)">
                <rect id="nèn_36x36" data-name="nèn 36x36" width="36" height="36" transform="translate(319 211)" fill="#fff"/>
                <path id="eye_on-2" data-name="eye on" d="M52,269.015a4.43,4.43,0,0,0,3.283-1.374,4.791,4.791,0,0,0,0-6.671,4.617,4.617,0,0,0-6.57,0,4.791,4.791,0,0,0,0,6.671A4.445,4.445,0,0,0,52,269.015Zm1.628-2.275c-.85,0-2.369-1.5-2.369-1.5l-1.943-1.5-.27-.221c0-.863.195-.813.79-1.413a2.94,2.94,0,0,1,2.168-.9,2.909,2.909,0,0,1,2.162.907,3.032,3.032,0,0,1,.886,2.2,3,3,0,0,1-.893,2.2C53.567,267.109,54.481,266.741,53.631,266.741ZM52,272.615a12.1,12.1,0,0,1-7.2-2.3,13.962,13.962,0,0,1-4.8-6.009,13.962,13.962,0,0,1,4.8-6.009,12.426,12.426,0,0,1,14.4,0,13.962,13.962,0,0,1,4.8,6.009,13.962,13.962,0,0,1-4.8,6.009A12.1,12.1,0,0,1,52,272.615ZM52,264.308Zm0,6.646a10.855,10.855,0,0,0,6.073-1.814,11.714,11.714,0,0,0,4.214-4.832,11.725,11.725,0,0,0-4.209-4.832,11.065,11.065,0,0,0-12.141,0,11.909,11.909,0,0,0-4.241,4.832,11.921,11.921,0,0,0,4.236,4.832A10.833,10.833,0,0,0,52,270.954Z" transform="translate(285 -35)" fill="#686868"/>
                <g id="Group_1898" data-name="Group 1898" transform="translate(211.022 -151.799) rotate(39)">
                    <path id="Path_2774" data-name="Path 2774" d="M0,0H27.853" transform="translate(323.5 217.5)" fill="none" stroke="#686868" strokeWidth="1.5"/>
                    <path id="Path_2775" data-name="Path 2775" d="M0,.508H27.853" transform="translate(323.5 215.5)" fill="none" stroke="#fff" strokeWidth="1.5"/>
                </g>
            </g>
        </svg>
    </>}

    { (!locked)&&<>
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" {...props}>
            <g id="eye_on" data-name="eye on" transform="translate(-319 -211)">
                <rect id="nèn_36x36" data-name="nèn 36x36" width="36" height="36" transform="translate(319 211)" fill="#fff"/>
                <path id="eye_on-2" data-name="eye on" d="M52,269.015a4.43,4.43,0,0,0,3.283-1.374,4.791,4.791,0,0,0,0-6.671,4.617,4.617,0,0,0-6.57,0,4.791,4.791,0,0,0,0,6.671A4.445,4.445,0,0,0,52,269.015Zm-.01-1.606a2.909,2.909,0,0,1-2.162-.907,3.032,3.032,0,0,1-.886-2.2,3,3,0,0,1,.893-2.2,2.94,2.94,0,0,1,2.168-.9,2.909,2.909,0,0,1,2.162.907,3.032,3.032,0,0,1,.886,2.2,3,3,0,0,1-.893,2.2A2.94,2.94,0,0,1,51.994,267.409ZM52,272.615a12.1,12.1,0,0,1-7.2-2.3,13.962,13.962,0,0,1-4.8-6.009,13.962,13.962,0,0,1,4.8-6.009,12.426,12.426,0,0,1,14.4,0,13.962,13.962,0,0,1,4.8,6.009,13.962,13.962,0,0,1-4.8,6.009A12.1,12.1,0,0,1,52,272.615ZM52,264.308Zm0,6.646a10.855,10.855,0,0,0,6.073-1.814,11.714,11.714,0,0,0,4.214-4.832,11.725,11.725,0,0,0-4.209-4.832,11.065,11.065,0,0,0-12.141,0,11.909,11.909,0,0,0-4.241,4.832,11.921,11.921,0,0,0,4.236,4.832A10.833,10.833,0,0,0,52,270.954Z" transform="translate(285 -35)" fill="#686868"/>
            </g>
        </svg>
    </>}
</>);


IconEye.propTypes = {
    locked: PropTypes.bool,
    width: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    height: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    className: PropTypes.node
}


IconEye.defaultProps = {
    width: 36,
    height: 36
}

export default IconEye;
