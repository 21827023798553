import { graphql } from 'react-apollo';
import LINK_UPLOAD from '../graphql/linkImageUpload.graphql';
import loading from '@gqlapp/base/utils/loading';

/**
 * withLinkUpload
 */
export const withLinkUpload = graphql(LINK_UPLOAD, {
  props: ({ mutate, ownProps: { loadbar } }: any) => ({
    uploadFile: async ({ file }: any) =>
      loading(loadbar, async () => {
        try {
          const { data }: any = await mutate({ variables: { file }, fetchPolicy: 'no-cache' });
          return data?.res;
        } catch (e) {
          return null;
        }
      })
  })
});

export default withLinkUpload;
