import React from 'react';
import * as PropTypes from "prop-types";
const Square = ({...props}) => (<>
    <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" fill={'currentColor'} {...props}>
        <rect width="30" height="30" fill={'currentColor'}/>
    </svg>
</>);


Square.propTypes = {
    width: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    height: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    className: PropTypes.node
}


Square.defaultProps = {
    width: 30,
    height: 30
}

export default Square;