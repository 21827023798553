import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import domainRep from '@gqlapp/base/utils/domainRep';
/**
 * This component is wrapped around any part of a page.
 * When that part of the page is clicked, it selects
 * the text within.
 */


const ClickToSelect = ({ onSelect, className, containerElement: Element, children }) =>{
  const onClick = e => {
    e.target.setSelectionRange(0, e.target.value.length);
  };

  const ref = { target: null };

  const select = e => {
    e.preventDefault();
    const range = document.createRange();
    const sel = window.getSelection();
    range.selectNodeContents(ref.target);
    sel.removeAllRanges();
    sel.addRange(range);
    if (onSelect) onSelect();
  };
  children = domainRep(children);
  return (<>
      <Element
        className={clsx(className, 'click-on-select', 'w-100', 'd-none d-md-block')}
        ref={(el) => ref.target = el}
        onClick={select}
      >{children}</Element>

      <input
        type={'text'}
        readOnly={'readonly'}
        className={clsx(className, 'click-on-select', 'text-primary', 'w-100', 'd-md-none')}
        // onFocus={onClick}
        onClick={onClick}
        defaultValue={children}
      />
    </>
  );
}

ClickToSelect.propTypes = {
  children: PropTypes.any,
  containerElement: PropTypes.oneOf(['span', 'div']),
  onSelect: PropTypes.func
}

ClickToSelect.defaultProps = {
  containerElement: 'span',
}
export default ClickToSelect;
