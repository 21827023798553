import React from "react";
import Box from "@mui/material/Box";

/**
 *
 * @param children
 * @param props
 * @returns {Element}
 * @constructor
 */
function FormWrapper({ children, ...props}){
  const BaseForm  = (({ onActions }) =>{

    React.useEffect(()=>{
      onActions['gens.filter'] && onActions['gens.filter'](props);
    }, [])

    return <>
      <Box sx={{ paddingTop: '0px', backgroundColor: '#f2f2f2', minHeight: 'calc(100%)' }}>
        {typeof children === 'function' && children(props)}
        {typeof children !== 'function' && children}
      </Box>
    </>
  })

  return <BaseForm {...props} />
}

export default function (Component){
  return function (props){
    return <FormWrapper {...props}>{Component}</FormWrapper>
  }
}