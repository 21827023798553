import {graphql} from "react-apollo";
import DOMAIN_VERIFY from "../graphql/domainUpdate.graphql";

/**
 *
 * @param Component
 */
export const withDomainVerified = graphql(DOMAIN_VERIFY, {
  props: ({ mutate }) => ({
    domainVerify: async (id: number) => mutate({ variables: { id, input: { status: "active" } } })
  })
});

export default withDomainVerified;
