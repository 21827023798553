import React from 'react';
import Button from 'reactstrap/lib/Button'
import { map as mapUrl } from "../config";
import Box from "@mui/material/Box";
import IconBack from '../icons/control/Back.svg'

/**
 * Map
 * @param menuID
 * @param name
 * @param onClick
 * @param path
 * @param parent
 * @param rest
 * @returns {function(*, *): *}
 */
export const map = ({ id: menuID, name, onClick: menuOnClick, path, parent, ...rest }) => {

  return (props, callback)=> {
    let { query, query: { id } } = props || {};

    let onClick = menuOnClick

    if(typeof menuOnClick == 'function'){
      onClick = (e)=> menuOnClick(e, {...props, query} );
    }
    else if(typeof menuOnClick == 'string'){
      onClick = (e)=> {
        e.preventDefault();
        try{
          let action = null, key = `link.${menuOnClick}`;
          if((props?.actions[key]) && (action = props?.actions[key])){
            action.get()
          }
        }catch (e) {}
      }
    }

    return callback({
      ...rest,
      onClick,
      url: mapUrl({ path: path || name, id}),
      id: menuID || `link-${name}`,
      title: !(typeof name !== 'undefined') ? null : `link:menu.${name}`.t(),
      name: (typeof name !== 'undefined') ? <div style={{ minWidth: '60px', lineHeight: '10px', fontSize: '10px', padding: '4px 5px 0' }}>{`link:menu.${name}`.t()}</div>: null,
      className: ['m-0 p-0'],
      classIconName: ['d-flex align-items-center justify-content-center'],
      parent : parent || [{targetId: 0}],
    })
  }
}


export const back = {
  id: 'link-btn-back',
  name: 'home',
  weight: 1,
  render: (props)=>{
    let { className, style }= props;
    const Icon = function Icon(){
      return <>
        <Box sx={{ width: '100%'}} className={'w-100 d-flex align-items-center justify-content-center'}>
          <IconBack height={24}/>
        </Box>
        <Box sx={{ width: '100%'}} className={'button-text text-nowrap text-h6'}>
          <Box sx={{ minWidth: '60px', lineHeight: '10px', fontSize: '10px', padding: '6px 5px 0' }} suppressHydrationWarning>
            {`back`.t()}
          </Box>
        </Box>
      </>
    }

    return <Button
      {...{ color: 'default', className, style }}
      onClick={()=>historyBack()}
    >
      <Box className={'d-flex align-items-center'}  style={{ width: '100%',  height: '100%'}}>
        <Box><Icon /></Box>
      </Box>
    </Button>
  }
}
