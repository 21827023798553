
/**
 * Removes the specified paths from the input object and the nested objects.
 * Returns a new object composed of the properties that were not removed.
 *
 * @param obj - The source object
 * @param paths - The property paths to remove
 */
export const omitNestedNew = (obj, paths) => {
  paths = typeof paths === 'string' ? [paths] : paths;
  paths.map(path => {
    try {
      delete obj[path];
    } catch (e) {}
  });
  const omittedObject = obj;

  try {
    Object.keys(omittedObject).forEach((key) => {
      if (typeof omittedObject[key] === 'object' && omittedObject[key] !== null) {
        omittedObject[key] = omitNestedNew(omittedObject[key], paths);
      }
    });
  } catch (e) {}
  return omittedObject;
};

export default omitNestedNew;