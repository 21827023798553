import withProps from "recompose/withProps";
import compose from "recompose/compose";
import mapInput from "@gqlapp/base/utils/mapInput";
import {graphql} from "react-apollo";
import DOMAINS_QUERY from "@xem/webrisk-common/graphql/webrisksQuery.graphql";

/**
 *
 * @param input
 */
export const withWebrisks = (input: any) => compose(
  withProps((props: any) => mapInput(input, props)),
  withProps(({ webrisks:{ variables } }: { webrisks: any} )=>({ ...variables })),
  graphql(DOMAINS_QUERY, {
    options: ({ page, pageSize, orderBy, direction, filter, fetchPolicy }: any) =>{
      return ({
        variables: { page, pageSize, orderBy, direction, filter },
        fetchPolicy: fetchPolicy || null,
      })
    },
    props({
            data: { loading, res, refetch, error, updateQuery, fetchMore, subscribeToMore },
            ownProps: { webrisks }
          }: any) {

      webrisks.refetch = refetch;
      webrisks.fetchMore = fetchMore;
      webrisks.subscribeToMore = subscribeToMore;
      webrisks.updateQuery = updateQuery;

      if (!loading && res) {
        webrisks.update(res);
      }
      return {
        loading,
        errors: error ? error.graphQLErrors : null
      };
    }
  })
);

export default withWebrisks;
