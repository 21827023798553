import loadingProcess from "@gqlapp/base/utils/loading/loading";


/**
 * QueryLoading
 * @param loadbar
 * @param subscribeToMore
 * @param QUERY
 * @constructor
 */
function QueryLoading(loadbar: any, subscribeToMore: any, QUERY: any){
  return async (variables: any)=>{
    return await loadingProcess(loadbar, async ()=>new Promise(async resolve => {
      try {
        subscribeToMore({
          document: QUERY, variables: variables,
          updateQuery: async ( _: any, { subscriptionData: { data: { res } } }: any ) => {
            resolve(res);
          }
        });
      } catch (e) {
        resolve({});
      }
    }));
  }
}

export default QueryLoading;