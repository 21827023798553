import extractRootDomain from "../extractRootDomain";
import ucwords from "../ucwords";

/**
 * removeHttp
 * @param url
 * @param domainOnly
 */
export const domainRep = (url, domainOnly = false) => {
  try {
    const domain = extractRootDomain(url);
    const extractDomain = `${domain}`.split('.');
    const repStr = extractDomain.map(val => ucwords(val)).join('.');
    return domainOnly ? repStr : `${url}`.replace(`${domain}`, repStr);
  } catch (e) {
    return url;
  }
};

export default domainRep;