import React from "react";
import ClientModule from '@gqlapp/module-client-react';
import resources from './locales';
import menu from './helpers/menu';
import { userConfigLogined, userConfigAnonymous } from './helpers/config';
import Route from '@xem/router/classes/Route';
import View from './containers/View';
import Dashboard from './containers/DashboardContainer';
// @ts-ignore
let getInitialProps = Dashboard.getInitialProps;

export default new ClientModule(menu,{
  route:{
    TypeIsFront:  <Route {...userConfigAnonymous} path={'/'} component={View} />,
    Dashboard:    <Route {...userConfigLogined}   {...{ getInitialProps }} path={'/dashboard'} component={Dashboard} />
  },
  localization: [{ ns: 'home', resources, more: ['common'] }]
});
