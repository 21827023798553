import compose from "recompose/compose";
import { withFormik } from "formik";
import Style from './Style';
import FilterConfirm from "./Filter.Confirm";


let timeout = null;
export default compose(
  Style,
  FilterConfirm,
  withFormik({
    enableReinitialize: true,
    displayName: 'EntitiesDeleteForm', // helps with React DevTools
    validate: ((values, { setValues }: any)=>{
      clearTimeout(timeout);
      timeout = setTimeout( () => {
        if(!(values.onSelect)){
          Object.assign(values, { ids: [] });
        }
        setValues(values)
      }, 100);
    }),
    mapPropsToValues: ({ values }: any) => (values),
    handleSubmit: async function(values, { props: { entities, filterConfirm, onUpdates, onDeletes }}) {
      let { ids, onSelect } = values;
      let type = null;
      switch (onSelect){
        case 1: type = 'delete';break;
        case 2: type = 'hide'; break;
      }

      /**
       * handleSubmit
       * @param onClose
       * @param filterConfirm
       */
      function handleSubmit({}, { props:{ onClose } }){
        let { ids, onSelect } = values || {}

        let value: any;

        if(onSelect === 1)      { value = onDeletes({ ids }); }
        else if(onSelect === 2) { value = onUpdates({ids, input: { status: 'inactive' }}); }

        if(value){
          value
            .then(func(entities?.refetch))
            .then(func(onClose));
        }
      }
      filterConfirm(ids, type, handleSubmit)
    }
  }))