import Link from './Link';
import linksMore from '@xem/entity/classes/fetchMore'
import Entities from '@gqlapp/core-common/Entities';


export class Links extends Entities {
  
  public ownDomain: boolean = false;
  public notOwnDomain: boolean = false;
  public _fetchMore: any = null;
  public subscribeToMore: any = null;
  public updateQuery: any = null;

  constructor(props:any = {}) {
    super(props);
    this.interface = Link;
  }

  /**
   *
   */
  public static instanse(props?: any) {
    return (new this(props)).save([]);
  }

  set fetchMore(value: any){
    this._fetchMore = value;
  }

  get fetchMore(){
    return linksMore(this._fetchMore);
  }

  get filter() {
    if(this.ownDomain){
      this._filter = { ...this._filter, ownDomain: this.ownDomain }
    }
    if(this.notOwnDomain){
      this._filter = { ...this._filter, notOwnDomain: this.notOwnDomain }
    }
    return this._filter || null;
  }

  set filter(value) {
    this._filter = { ...this.filter, ...value };
  }
}

export default Links;
