import { graphql } from "react-apollo";
import DOMAIN_UPDATE from "../graphql/domainUpdate.graphql";
import loading from "@gqlapp/base/utils/loading";
import map from "@gqlapp/base/utils/map";

/**
 *
 * @param Component
 */
export const withDomainUpdate = graphql(DOMAIN_UPDATE, {
  props: ({ mutate, ownProps: { loadbar } }: any) => ({
    domainUpdate: async (variables: any) =>
      loading(loadbar, async () =>
        map(
          map(await mutate({ variables }), ({ data }: any) => data),
          ({ domainUpdate: res }: any) => res
        )
      )
  })
});

export default withDomainUpdate;
