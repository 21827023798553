import React from 'react';
import ClientModule from '@gqlapp/module-client-react';
import { map as mapUrl } from "../config";
import Campaign from '@xem/icon/footer/Campaign.svg'

const map = ({ id: menuID, name, onClick: menuOnClick, path, parent, ...rest }) => {
  return (props, callback)=> {
    let { query, query: { id } } = props || {};
    let onClick;

    if(typeof menuOnClick == 'function'){
      onClick = (e)=> menuOnClick(e, {...props, query} );
    }
    else if(typeof menuOnClick == 'string'){
        onClick = (e)=> {
          e.preventDefault();
        }
    }

    return callback({
      ...rest,
      onClick,
      perms: ['isMember'],
      url: mapUrl({ path: path || name, id}),
      id: menuID || `taxonomy-${name}`,
      title: !(typeof name !== 'undefined') ? null : `taxonomy:menu.${name}`.t(),
      name: (typeof name !== 'undefined') ? <div style={{ minWidth: '60px', lineHeight: '10px', fontSize: '10px', padding: '4px 5px 0' }}>{`link:menu.${name}`.t()}</div>: null,
      className: ['m-0 p-0'],
      classIconName: ['d-flex align-items-center justify-content-center'],
      parent : parent || [{targetId: 0}],
    })
  }
}


/**
 *
 * @type {(function({query?: {id: *}}=, *): *)[]}
 */
const entities = [
  {
    id: 'campaign',
    name: 'campaign',
    weight: 3,
    withoutRouteTypes: ['LinkDetail', 'TaxonomyList', 'TaxonomyListPage', 'DomainList', 'WebriskList', 'UserList'],
    routeTypes: ['Dashboard', 'LinkList'],
    sx: { width: '60px !important' },
    content: (<Campaign width={'60px'} height={20} />),
    path: 'list'
  }
]
  .map(({ weight, ...value}, index) =>
    map({ ...value, weight: weight || index, maxWidth: 'inherit' })
  );

export default new ClientModule({ menus: [{ positions: ['bottom.mobile'], items: entities }]});
