const { DeviceTypes, BrowserTypes, OsTypes } = require("./constants");
// device types
const isMobileType = ({ type }) => type === DeviceTypes.Mobile;
const isTabletType = ({ type }) => type === DeviceTypes.Tablet;
const isMobileAndTabletType = ({ type }) =>
  type === DeviceTypes.Mobile || type === DeviceTypes.Tablet;
const isSmartTVType = ({ type }) => type === DeviceTypes.SmartTv;
const isBrowserType = ({ type }) => type === DeviceTypes.Browser;
const isWearableType = ({ type }) => type === DeviceTypes.Wearable;
const isConsoleType = ({ type }) => type === DeviceTypes.Console;
const isEmbeddedType = ({ type }) => type === DeviceTypes.Embedded;

// os types
const isAndroidType = ({ name }) => name === OsTypes.Android;
const isWindowsType = ({ name }) => name === OsTypes.Windows;
const isMacOsType = ({ name }) => name === OsTypes.MAC_OS;
const isWinPhoneType = ({ name }) => name === OsTypes.WindowsPhone;
const isIOSType = ({ name }) => name === OsTypes.IOS;

// browser types
const isChromeType = ({ name }) => name === BrowserTypes.Chrome;
const isFirefoxType = ({ name }) => name === BrowserTypes.Firefox;
const isChromiumType = ({ name }) => name === BrowserTypes.Chromium;
const isEdgeType = ({ name }) => name === BrowserTypes.Edge;
const isYandexType = ({ name }) => name === BrowserTypes.Yandex;
const isSafariType = ({ name }) =>
  name === BrowserTypes.Safari || name === BrowserTypes.MobileSafari;
const isMobileSafariType = ({ name }) => name === BrowserTypes.MobileSafari;
const isOperaType = ({ name }) => name === BrowserTypes.Opera;
const isIEType = ({ name }) =>
  name === BrowserTypes.InternetExplorer || name === BrowserTypes.Ie;
const isMIUIType = ({ name }) => name === BrowserTypes.MIUI;
const isSamsungBrowserType = ({ name }) => name === BrowserTypes.SamsungBrowser;

const isEdgeChromiumType = (ua) => typeof ua === 'string' && ua.indexOf('Edg/') !== -1;

module.exports = {
  isMobileType,
  isTabletType,
  isMobileAndTabletType,
  isSmartTVType,
  isBrowserType,
  isWearableType,
  isConsoleType,
  isEmbeddedType,

// os types
  isAndroidType,
  isWindowsType,
  isMacOsType,
  isWinPhoneType,
  isIOSType,

// browser types
  isChromeType,
  isFirefoxType,
  isChromiumType,
  isEdgeType,
  isYandexType,
  isSafariType,
  isMobileSafariType,
  isOperaType,
  isIEType,
  isMIUIType,
  isSamsungBrowserType,

  isEdgeChromiumType
};