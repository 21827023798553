import React from 'react';
import Base from "@gqlapp/base/RenderField";
import IconEye from "./icons/Eye";
import IconRemove from "./icons/Remove";
import IconSearch from "./icons/Search";
import IconArrow from "./icons/Arrow";
import IconDropdown from "./icons/Dropdown";
import IconColor from "./icons/Color";
import Button from "reactstrap/lib/Button";
import Box from "@mui/material/Box";


/**
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export default function TextField(props){
  let { searchIcon, searchIcon:{ searchProps: { sxSearch, ...searchProps } = {}, ...search} = {} } = props;
  let { eyeIcon, eyeIcon:{ eyeProps: { sxEye, ...eyeProps} = {}, ...eye} = {} } = props;
  let { removeIcon, removeIcon:{ removeProps: { sxRemove, ...removeProps} = {}, ...remove} = {} } = props;
  let { arrowIcon, arrowIcon:{ arrowProps: { sxArrow, ...arrowProps} = {}, ...arrow} = {} } = props;
  let { dropdownIcon, dropdownIcon:{ dropdownProps: { sxDropdown, ...dropdownProps} = {}, ...dropdown} = {} } = props;
  let { colorIcon, colorIcon:{ colorProps: { sxColor, ...colorProps} = {}, ...color} = {} } = props;

  let { startAdornment, prefix, endAdornment, suffix } = props;

  startAdornment  = prefix || startAdornment;
  endAdornment    = suffix || endAdornment;

  let styles = {
    '& > .btn':{
      padding: 0,
      margin: 0,
      minWidth: '24px',
      marginRight: '-5px'
    }
  }

  let btnProps = {
    color: 'default'
  }

  if(!startAdornment){
    if(searchIcon){
      searchProps = searchProps || {}
      startAdornment = <Box sx={{ ...styles, ...sxSearch }}>
        <Button {...{ ...btnProps, ...searchProps}}>
          <IconSearch {...search} />
        </Button>
      </Box>
    }
  }


  if(!endAdornment){
    if(colorIcon){
      endAdornment = <Box sx={{ ...styles, ...sxColor }}>
        <Button {...{ ...btnProps, ...colorProps}}><IconColor {...color} /></Button>
      </Box>
    }

    if(arrowIcon){
      endAdornment = <Box sx={{ ...styles, ...sxArrow }}>
        <Button {...{ ...btnProps, ...arrowProps}}><IconArrow {...arrow} /></Button>
      </Box>
    }

    if(dropdownIcon){
      endAdornment = <Box sx={{ ...styles, ...sxDropdown }}>
        <Button {...{ ...btnProps, ...dropdownProps}}><IconDropdown {...dropdown} /></Button>
      </Box>
    }

    if(eyeIcon){
      endAdornment = (<Box sx={{...styles, ...sxEye }}>
        <Button {...{ ...btnProps, ...eyeProps}}><IconEye {...eye} /></Button>
      </Box>)
    }

    if(removeIcon){
      endAdornment = (<Box className={'remove'} sx={{ ...styles, ...sxRemove }}>
        <Button {...{ ...btnProps, ...removeProps}}><IconRemove {...remove} /></Button>
      </Box>)
    }
  }

  return (<Base {...props} {...{ startAdornment, endAdornment }} />)
}
