import { graphql } from 'react-apollo';
import compose from "recompose/compose";
import withProps from "recompose/withProps";
import mapInput from "@gqlapp/base/utils/mapInput";
import statsDeviceMore from "./Stats/statsDeviceMore";
import STATS_DEVICE_QUERY from "../graphql/StatsDevices.graphql";

/**
 * withStatsDevice
 * @param input
 */
export const withStatsDevice = (input?: any) => (Component: any) =>
	compose(
		withProps((props: any) => mapInput(input, props)),
		graphql(STATS_DEVICE_QUERY, {
			skip: ({ skip }: any) => !!skip,
			options: ({ id, deviceProps }: any) => ({ variables: { id: id || null, ...deviceProps } }),
			props({ data: { loading, devices, error, fetchMore }, ownProps, ownProps: { stats } }: any) {
				if (!loading) {
					// console.warn('#######', 'withStatsDevice')
					stats.devices = devices;
				}
				return { loading, statsDeviceMore: statsDeviceMore(stats, fetchMore, ownProps), errors: error ? error.graphQLErrors : null };
			}
		})
	)(Component);

export default withStatsDevice;
