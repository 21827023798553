import React  from 'react';
import clsx from 'clsx';
import Box from '@mui/material/Box';
import Base from 'reactstrap/lib/Container';
import Row from '@gqlapp/base/Row';
import Col from '@gqlapp/base/Col';


const Container = ({ className, style, ...props}) =>
	<Base {...props} className={clsx(className)} fluid style={{ ...style, maxWidth: '1220px', margin: '0 auto' }} />

const HomeSection = ({}) => {

	let $data = [
		{
			title: "Rút Gọn Link".t(),
			description: "Chúng tôi không chỉ là tạo ra các URL ngắn,<br />mà tạo ra một đường link thông minh hơn.".t(),
			list: [
				'Liên kết thông minh mở app tức thời'.t(),
				'Tối ưu hóa liên kết trên đa nền tảng'.t(),
				'Kết nối tên miền thương hiệu riêng'.t(),
				'Quản lý, tạo chiến dịch dễ dàng'.t(),
			],
			href: '#'
		},
		{
			// className: 'active',
			title: "Qr Code".t(),
			description: "Thu hút  khách hàng mục tiêu bằng nhận<br />diện thương hiệu ngay trên mã Qr Code".t(),
			list: [
				'Tuỳ chỉnh thiết kế qr code theo ý thích'.t(),
				'Quét mã Qr Code không giới hạn'.t(),
				'Mã Qr Code động linh hoạt thông minh'.t(),
				'Định dạng chất lượng cao'.t(),
			],
			href: '#'
		},
		{
			title: "Bio Page".t(),
			description: "Chúng tôi không chỉ là tạo ra các URL ngắn,<br />mà tạo ra một đường link thông  minh hơn.".t(),
			list: [
				'Liên kết thông minh mở app tức thời'.t(),
				'Tối ưu hóa liên kết trên đa nền tảng'.t(),
				'Kết nối tên miền thương hiệu riêng'.t(),
				'Quản lý, tạo chiến dịch dễ dàng'.t(),
			],
			href: '#'
		}
	]
	return (<Box sx={{paddingBottom: '50px'}}>
		<Container>
			<Box
				component={'h1'}
				sx={{
					textAlign:'center',
					paddingTop: '30px',
				}}
			>
				{`Xem.Link Một Nền Tảng Nhiều Tính Năng`.t()}
			</Box>
			<Box
				component={'p'}
				sx={{
					textAlign:'center',
					paddingTop: '12px',
					marginBottom: '32px',
					fontSize: '16px'
				}}
				dangerouslySetInnerHTML={{ __html: `Tạo ra các công cụ hữu ích giúp cho<br /> việc chuyển tải thông tin nội dung của bạn dễ tiếp cận cho tất cả mọi người.`.t() }}
			/>

			<Row className={'row-sm-small row-md-medium row-lg-big'} sx={{
				'@media (min-width: 992px)':{
					'&':{
						marginRight: '-25px',
						marginReft: '-25px',
						'& > [class*=col], & > [class*=col-]':{
							paddingLeft: '25px !important',
							paddingRight: '25px !important'
						}
					},
				},
				'& > [class*=col]':{
					marginBottom: '10px',
				},
				'& .section-tab':{
					// border: '2px solid transparent',
					'.btn-link': {
						padding: '10px',
						borderRadius: '10px',
						minWidth: '174px',
						display: 'block',
						fontSize: '21px'
					},
					'&.active, &:hover': {
						border: '2px solid #0066CC',
						marginTop: '-2px',
						marginLeft: '-2px',
						marginRight: '-2px',
						'& .link':{
							display: 'flex',
							justifyContent: 'center',
							'.btn-link': {
								color: '#ffffff',
								backgroundColor: '#0066CC'
							}
						}
					},
					backgroundColor: '#ffffff',
					borderRadius: '10px',
					padding: '30px 15px',
					'& .description, & .title':{
						textAlign: 'center',
						marginBottom: '15px'
					},
					'& .title':{
						fontSize: '28px',
						color: '#000000'
					},
					'& .description':{
						fontWeight: 400,
						color: '#686868'
					},

					'& ul.list':{
						paddingLeft: '0',
						'& > li':{
							listStyle: 'none',
							margin: '0 0 10px 0',
							lineHeight: '24px',
							padding: '10px 0 10px 44px',
							backgroundImage: `url('data:image/svg+xml,<svg height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.533 9.967L4.667 11.833L10.667 17.833L24 4.5L22.134 2.634L10.667 14.034L6.533 9.967ZM21.333 21.834H2.667V3.167H16V0.5H2.667C1.96024 0.501848 1.28295 0.783428 0.783187 1.28319C0.283428 1.78295 0.00184776 2.46024 0 3.167V21.833C0.00184776 22.5398 0.283428 23.2171 0.783187 23.7168C1.28295 24.2166 1.96024 24.4982 2.667 24.5H21.333C22.0398 24.4982 22.7171 24.2166 23.2168 23.7168C23.7166 23.2171 23.9982 22.5398 24 21.833V11.167H21.333V21.834Z" fill="%234E79E5"/></svg>')`,
							backgroundPosition: 'left center',
							backgroundRepeat: 'no-repeat',
							// listStyleImage: `url('data:image/svg+xml,<svg height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.533 9.967L4.667 11.833L10.667 17.833L24 4.5L22.134 2.634L10.667 14.034L6.533 9.967ZM21.333 21.834H2.667V3.167H16V0.5H2.667C1.96024 0.501848 1.28295 0.783428 0.783187 1.28319C0.283428 1.78295 0.00184776 2.46024 0 3.167V21.833C0.00184776 22.5398 0.283428 23.2171 0.783187 23.7168C1.28295 24.2166 1.96024 24.4982 2.667 24.5H21.333C22.0398 24.4982 22.7171 24.2166 23.2168 23.7168C23.7166 23.2171 23.9982 22.5398 24 21.833V11.167H21.333V21.834Z" fill="%234E79E5"/></svg>')`,
							'&::marker':{
								height: '24px',
								marginRight: '10px'
							}
						}
					}
				}
			}}>
				{$data.map(({ className, href, title, description, list }, idx)=><Col key={idx} sm={12} md={4} >
					<Box className={clsx('section-tab', className)}>
						<Box component={'h2'} className={'title'}> {title}</Box>
						<Box component={'h4'} className={'description'}
								 dangerouslySetInnerHTML={{ __html: description }}
						/>
						<Box sx={{ margin: '0 10px', '.line': { margin: '15px 0'} }}><hr className={'line'} /></Box>
						<Box component={'ul'} className={'list'}>
							{list.map((item, key)=><li key={key}>{item}</li>)}
						</Box>
						<Box className={'link'} sx={{ margin: '0 10px', textAlign: 'center' }}>
							<Box component={'a'} href={href} className={'btn-link'}>{'Tìm hiểu thêm'}</Box>
						</Box>
					</Box>
				</Col>)}
			</Row>
		</Container>
	</Box>);
}

export default HomeSection;
