export default {
  enabled: true,
  langPickerRender: false,
  langList: ['en-EN', 'vi-VN'],
  cookie: 'lang',
  init:{
    lng: 'vi-VN',
    // nsSeparator: false,
    fallbackLng: 'vi-VN',
    defaultNS: 'common'
  }
};
