import React from 'react';
import ClientModule from '@gqlapp/module-client-react';

import Hotline from '@xem/icon/footer/hotline.svg'
import ChatOnline from '@xem/icon/footer/chatonline.svg'
import CreateLink from '@xem/icon/footer/Home.svg';
import Text from '@xem/icon/footer/Text.svg';
import Menu from '@xem/icon/footer/Menu.svg'
import { mapMenu } from './utils'

const GuestMap = (props)=> mapMenu({...props,
  perms: ['isAnonymous']
});

const GuestMenus = [
  {
    name: 'Hotline',
    weight: 0,
    content: <Hotline height={24} style={{ marginTop: '3px' }} />,
    onClick: ({}, { }) => msg({ type:'success', message: 'Under construction' }),
  },
  {
    id: 'chatonline',
    name: 'Chat Online',
    weight: 1,
    style:{ paddingTop: '3px' },
    content: (<ChatOnline height={24} style={{ marginTop: '3px' }} />),
    onClick: ({}, { }) => msg({ type:'success', message: 'Under construction' }),
  },
  {
    id: 'guest-home',
    weight: 2,
    className: ['p-0 border-0', 'mobile-home'],
    content: <CreateLink height={50} />,
    url: '/'
  },
  {
    id: 'socialtext',
    name: 'Social text',
    weight: 3,
    content: (<Text height={24} style={{ marginTop: '3px' }} />),
    url: '/bold-italic-facebook'
  },
  {
    id: 'guest-menu',
    name: 'menu',
    weight: 4,
    content: (<Menu height={24} style={{ marginTop: '3px' }} />),
    onClick: (e, { onActions })=>{
      e.preventDefault();
      onActions && onActions.UserToolbars && onActions.UserToolbars();
    }
  }
].map(GuestMap);



export default new ClientModule({
  menus:[
    { positions: ['bottom.mobile'], items: GuestMenus }
  ]
})
