import React from 'react';
import ClientModule from "@gqlapp/module-client-react";

// @ts-ignore
import resources from './locales';
import resourcesGlobal from './locales/global';
import RootComponent from './containers/RootComponent';
import Route from "@xem/router/classes/Route";

/**
 * utils
 */
import menu from './helpers/menu';
import RootProps from './helpers/RootProps';
import { userConfigAnonymous, userConfigLogined, map } from "./helpers/config";

/**
 * Containers
 */
import Login from "./containers/LoginContainer";
import Register from "./containers/RegisterContainer";
import ForgotPassword from './containers/ForgotPasswordContainer';
import ResetPassword from './containers/ResetPasswordContainer';
import List from './containers/ListContainer';
import Settings from './containers/SettingContainer';
import withOnUserSettings from './helpers/withOnUserSettings';
import withOnCountry from './helpers/withOnCountry';

// @ts-ignore
let ListInitialProps = List.getInitialProps;

export default new ClientModule(menu, RootProps, {
  popupComponents:[
    { positions: ['cpanel', 'list'], component: withOnUserSettings },
    { positions: ['web'], component: withOnCountry }
  ],
  route:{
    UserLogin:          <Route {...userConfigAnonymous} component={Login}           path={map({ path: 'login'})} />,
    UserRegister:       <Route {...userConfigAnonymous} component={Register}        path={map({ path: 'register'})} />,
    UserForgotPassword: <Route {...userConfigAnonymous} component={ForgotPassword}  path={map({ path: 'forgot'})} />,
    UserResetPassword:  <Route {...userConfigAnonymous} component={ResetPassword}   path={map(
      { path: 'reset', id: ':id', timestamp: ':timestamp',hash: ':hash' })} />,
    UserList:  <Route {...userConfigLogined} getInitialProps={ListInitialProps} component={List}  path={map({ path: 'list' })} />,
    UserSettings:  <Route {...userConfigLogined} component={Settings}  path={map({ path: 'settings' })} />,
  },
  // @ts-ignore
  rootComponentFactory: [req => <RootComponent req={req} />],
  localization: [
    { ns: 'user', resources, more: ['common'] },
    { ns: 'common', resources: resourcesGlobal }
  ]
});
