import saveStatus from '@gqlapp/base/utils/saveStatus';

/**
 * Delete multiple elements
 * @param Delete
 */
export const onDeletes  = (Delete: any, {}: any = {}) => async (input: any) => {
  let values = await Delete(input);
  // @ts-ignore
  return saveStatus({ values, errorMsg: 'link:delete.errorMsg'.t()});
};

export default onDeletes;
