const cookie = require('cookies-next');
const settings = require('@gqlapp/config').default;
const clientStorage = require('./utils/clientStorage').default;

// const regex = new RegExp(/(SESS|SSESS)(.*)/s);
const getOption = (options)=>{
  options = options || {}
  try{
    if(settings.app.domainCookieAll){
      if(!__SERVER__){
        Object.assign(options, { domain: `.${window.location.hostname}` })
      }else if(options?.req && options?.req?.headers && options?.req?.headers['x-forwarded-server']){
        Object.assign(options, { domain: `.${options?.req?.headers['x-forwarded-server'] }` })
      }
    }
  }catch (e) {
    // debug('######', e)
  }

  // debug({ options, d: settings.app.domainCookieAll })
  return options;
}

setCookie     = function (){
  let [key, value, options ] = arguments;
  if(key && value) {
    options = getOption(options);
    cookie.setCookie(key, value, options)
  }
}

hasCookie			= (key, options) => cookie.hasCookie(key, getOption(options));
getCookies 		= (options) => cookie.getCookies(getOption(options));
getCookie			= (key, options) => cookie.getCookie(key, getOption(options));
setCookies		= (key, data, options) => (key && data) ? cookie.setCookies(key, data, getOption(options)): null;
deleteCookie	= (key, options) => cookie.deleteCookie(key, getOption(options));
removeCookies	= (key, options) => cookie.removeCookies(key, getOption(options));
checkCookies	= (key, options) => cookie.checkCookies(key, getOption(options));

getItem         = (name) => clientStorage.getItem(name);
setItem         = (name, value) => clientStorage.setItem(name, value);
removeItem      = (name)=> clientStorage.removeItem(name);