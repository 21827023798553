import React from "react";
import { createPortal } from "react-dom";

export default function renderFormPopup(FormFilter: any, append: any, props: any){
  /**
   * append element
   */
  if(typeof append !== 'object'){
    if(typeof append === 'string'){
      append = $$(append);
    }else{
      append = $$('body > #__next > #switcher');
    }
  }
  return <>{(!!append) && createPortal(React.createElement(FormFilter, props), append)}</>
}