import React from 'react';
import withProps from "recompose/withProps";
import Button from "reactstrap/lib/Button";

/***
 * withWebShare
 * @type {InferableComponentEnhancerWithProps<{ShareBtn: function({url: *, title: *, text: *, children: *, btn: *, [p: string]: *}): *}, {}>}
 */
export const withWebShare = withProps(({}) => {
    const ShareBtn = ({ url, title, text, children, btn, ...props }) =>
      (<Button
        {...{
            ...props,
            onClick: async ()=>{
                try{
                    await navigator.share({ title, text, url });
                }catch (e){
                    console.warn(e)
                }
            }
        }}
        >{children}</Button>)
    return ({ ShareBtn })
})


export default withWebShare;
