import Entities from '@gqlapp/core-common/Entities';
import moment from "moment/moment";

export class Chart{
  time    : string;
  count : number;

  public save({ time, count}: any){
    this.time   = time;
    this.count  = count;
  }
}

export class Charts extends Entities{
  public dateType: string = 'TODAY';

  public options: any = {
    accessibility: { enabled: false },
    chart: { type: 'column', height: 200 },
    title: { text: '' },
    xAxis: {
      type: 'category',
      labels: {
        rotation: 0,
        style: {
          fontSize: '10px',
          fontFamily: 'Verdana, sans-serif'
        }
      }
    },
    yAxis: {
      min: 0,
      title: {
        text: ''
      },
      labels: {
        rotation: 0,
        style: {
          fontSize: '10px',
          fontFamily: 'Verdana, sans-serif'
        }
      }
    },
    legend: {
      enabled: false
    },
    tooltip: {
      // @ts-ignore
      pointFormat: 'values: <b>{point.y:.1f}</b>'.t()
    }
  }


  constructor() {
    super();
    this.interface = Chart;
  }


  /**
   * get pager
   */
  get variables(): any & { dateType: string } {
    return {
      dateType: this.dateType
    };
  }


  assign({ options, categories, datas}: any){
    const _DATAS: any[] = [];
    if(categories.length){
      (categories || []).map((value: any, idx: number)=>{
        _DATAS.push({ name: value, y: datas[idx]});
      })
      Object.assign(options, {series: [{ data: _DATAS }]})
    }else {
      Object.assign(options, {series: [{ data: datas }]})
    }
    return options;
  }


  dataByTab(value: number){


    let time: number = (new Date()).getTime(), i;
    let datas: any[] = [];
    let categories: any[] = [];
    let options = this.options;

    const xAxis = { type: 'datetime',  tickPixelInterval: 150 }
    const yAxis = {}
    // @ts-ignore
    const series =  [{ ...options.series, name: 'Lượt xem'.t() }];

    const _CHARTS = this.loadItems(value);

    switch(value){
      case 0:
        //REALTIME
        for (i = -59; i <= 0; i += 1) {
          let x = time + i * 1000;
          let y = 0;
          let now = moment(x).format('YYYY-MM-DD H:mm:ss');
          if(_CHARTS[now]){
            y = Number(_CHARTS[now]);
          }
          datas.push({ x, y });
        }

        Object.assign(options, { xAxis, yAxis, series, time: { useUTC: false}, legend: { enabled: false }, exporting: { enabled: false }});
        break;
      case 1:
        //DAY
        for (i = 0; i < 24; i++) {
          if(_CHARTS[i]){
            datas.push(Number(_CHARTS[i]));
          }else{
            datas.push(0);
          }
          categories.push(`~${i}h`);
        }

      case 2:
        //WEEK
        for (i = -6; i <= 0; i += 1) {
          let day = moment(time + i * 86400000).format('D-M');
          let index = moment(time + i * 86400000).format('YYYY-MM-DD');
          if(_CHARTS[index]){
            datas.push(Number(_CHARTS[index]));
          }else{
            datas.push(0);
          }
          categories.push(day)
        }
        break;
      case 3:
        // MONTH
        for (i = -29; i <= 0; i += 1) {
          let day = moment(time + i * 86400000).format('D-M');
          let index = moment(time + i * 86400000).format('YYYY-MM-DD');
          if(_CHARTS[index]){
            datas.push(Number(_CHARTS[index]));
          }else{
            datas.push(0);
          }
          categories.push(day)
        }
        break;
    }

    return this.assign({ options, categories, datas });
  }

  loadItems(activeTab: number){

    let items = this.items || []
    let _CHARTS = {};
    let _T = [];

    if(items && items.length){

      ([...items]).map(({count, time}) =>{
        if(!activeTab){
          const d = new Date(time);
          const s = d.getSeconds();
          for(let i = 9; i >= 0; i--){
            let rs = (s - (i * 10));
            if(rs > 0  && rs < 10 ){
              let x = d.getTime() - rs * 1000;
              _T.push(rs);
              time = moment(x).format('YYYY-MM-DD H:mm:ss');
              break;
            }
          }
        }
        count = parseInt(count);
        if(!_CHARTS[time]){
          _CHARTS[time] = 0;
        }
        _CHARTS[time] += count;
      });
    }
    return _CHARTS;
  }
  /**
   * instanse
   */
  static instanse(){
    return new this();
  }
}


export default Charts;