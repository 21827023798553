import compose from 'recompose/compose';
import withProps from 'recompose/withProps';
import withLoading from '@xem/utils/withLoading';
import Entity from '@xem/gen-common/classes/Gen';
import withGen, { GEN_QUERY } from '@xem/gen-common/classes/withGen';
import withGenDeleting from '@xem/gen-common/classes/withGenDeleting';
import setCache from '@xem/entity/classes/setCache';

/**
 *
 * @param input
 * @param withPropsInput
 * @param withPropsBefore
 */
export default (input?: any, withPropsInput?: any[], withPropsBefore?: any[]) => {
	return (Route: any) => {

		function getEntity({ query }: any) {
			/*** entity ID*/
			let entity = Entity.instanse();
			entity.id = parseInt(query?.id);
			return entity;
		}


		/**
		 * WithRouteLink
		 */
		const WithRouteLink = compose(
			withProps(input),
			withGenDeleting,
			withProps(({ query }) => ({ entity: getEntity({ query }) })),
			...(withPropsBefore || []),
			withGen(({ entity }) => ({ gen: entity, entity_id: entity?.id })),
			...(withPropsInput || []),
			withLoading
		)(Route);

		const bindCache = async (apolloClient: any, query: any, obj: any)=> {
			let entity = getEntity(obj)
			let variables = { id : entity.id }

			/** set cache*/
			await setCache({ query, variables, apolloClient })
		}


		// @ts-ignore
		WithRouteLink.getInitialProps = async ({ apolloClient, ...ctx}) => {
			ctx = ctx || {};
			await bindCache(apolloClient, GEN_QUERY, {...ctx})
			return ({...ctx})
		}

		return WithRouteLink;
	}
}
