import React from "react";
import Icon from "./Popup/Icon.svg";
import Render from "./View/Render";
import dynamic from "next/dynamic";
import compose from "recompose/compose";
import { indexCallback } from "../renderCallback";

const Form = dynamic(() => import('./Html').then((c) => c.default));

const DataForm = (({ isOpen, ...props }) => (
	<>{!__SERVER__ && isOpen && <><Form {...props} {...{ isOpen }} /></>}</>));

let ref = React.createRef();

export default compose(Render(ref), indexCallback(ref, DataForm, Icon, 'block.html', `page:block.html.label`));