import React from 'react';
import * as PropTypes from "prop-types";
const IconArrow = ({...props}) => {
    return (<>
        <svg width="6" height="10" viewBox="0 0 6 10" fill="none" {...props}>
            <path
              d="M5.84148 5.38767L1.43482 9.8387C1.38434 9.88983 1.32438 9.9304 1.25838 9.95808C1.19237 9.98575 1.12161 10 1.05015 10C0.978683 10 0.907923 9.98575 0.841915 9.95808C0.775908 9.9304 0.715949 9.88983 0.665473 9.8387L0.15883 9.31978C0.0571235 9.2168 0 9.07724 0 8.93173C0 8.78622 0.0571235 8.64666 0.15883 8.54368L3.64903 5L0.15883 1.45632C0.0571235 1.35334 0 1.21378 0 1.06827C0 0.922761 0.0571235 0.783197 0.15883 0.680218L0.672228 0.161298C0.722704 0.110168 0.782663 0.069602 0.848671 0.0419245C0.914678 0.0142469 0.985439 0 1.0569 0C1.12836 0 1.19912 0.0142469 1.26513 0.0419245C1.33114 0.069602 1.3911 0.110168 1.44157 0.161298L5.84148 4.61157C5.943 4.71465 6 4.85417 6 4.99962C6 5.14507 5.943 5.28459 5.84148 5.38767Z"
              fill="black"/>
        </svg>
    </>)
};


IconArrow.propTypes = {
    width: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    height: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    className: PropTypes.node
}


IconArrow.defaultProps = {
    width: 7,
    height: 14
}

export default IconArrow;
