import { translate } from "@xem/i18n-client-react";
import { setItem } from '@gqlapp/core-common/clientStorage';
import replace from "@gqlapp/base/utils/replace";
import Router from "next/router";
import i18next from 'i18next';
import baseErrorCatch from "@gqlapp/base/errorCatch";

import isEmpty from "@gqlapp/base/utils/isEmpty";

function RootComponent({ children, t, ...props }){

  /*** */
  try{ errorCatch = baseErrorCatch(props); }catch (e) { console.warn(e) }

  /** */
  try{ msg = (value)=> props?.alert && props?.alert(value); }catch (e) {}

  /** */
  try{ String.prototype.$$ = (e) => { try { return document.querySelector(e) }catch (e) {}}; }catch (e) {}

  /** */
  try{ historyPush = (value) => Router.replace(value).then(()=>{}); }catch (e) {}

  try{
    if([...props.history].length > 1){
      // @ts-ignore
      historyBack = function (){
        let backUrl = props.history.at(-2);
        backUrl = isEmpty(backUrl) ? "/": backUrl;
        historyPush(backUrl)
      }
    }
  }catch (e) {}



  t = __SERVER__ ? i18next.t : t;
  if (typeof t === 'function') {
    // @ts-ignore
    String.prototype.t = function (props = {}) {
      return t(this.toString(), props);
    }

    // @ts-ignore
    String.prototype.r = function (props = {}) {
      try {
        return replace(this.toString(), props);
      } catch (e) {
        return this.toString();
      }
    }

    // @ts-ignore
    Array.prototype.t = function (value, props = {}) {
      if (this.length === 2) {
        value = value || 0;
        let values = this;
        let val = values[value !== 1 ? 1 : 0];
        return `${val}`.t().r(props);
      }
      return ``;
    };
  }

  /**
   * Set location path
   */
  let asPath;
  if((asPath = props?.asPath)){
    setItem('location', asPath);
  }

  /**
   * historyPush
   * @param value
   */
  try{ Object.assign(window.history, { previous: { href: props?.preUrl }}); }catch (e) {}

  return children;
}


export default translate('common')(RootComponent)
