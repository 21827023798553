import React from "react";
import ClientModule from '@gqlapp/module-client-react';
import CreateLink from '@xem/icon/footer/Home.svg';
import { map } from './utils';

import IconLinks from '@xem/icon/footer/Links.svg'

const MemberMenus = [
  {
    id: 'links',
    name: 'links',
    weight: 2,
    content: (<IconLinks width={'60px'} height={20} />),
    withoutRouteTypes: ['LinkList', 'LinkDetail', 'UserList', 'UserList', 'DomainList', 'WebriskList'],
    routeTypes: ['Dashboard', 'TaxonomyList', 'TaxonomyLinks'],
    sx: { width: '60px !important' },
    path: 'list'
  },
  {
    id: 'webrisk-links',
    name: 'links',
    weight: 1001,
    content: (<IconLinks width={'60px'} height={20} />),
    routeTypes: ['WebriskList'],
    sx: { width: '60px !important' },
    path: 'list'
  },
  {
    id: 'createlink',
    weight: 1000,
    className: ['p-0 border-0', 'mobile-home'],
    withoutRouteTypes: ['LinkDetail'],
    sx: { width: '74px !important' },
    content: <CreateLink height={50} />,
    onClick: (e, { onActions })=>{
      e.preventDefault();
      onActions && onActions.Create && onActions.Create({ 'title': `Create`.t()});
    }
  }
]
  .map(({ weight, ...value}, index) =>
    map({ ...value, weight: weight || index, maxWidth: 'inherit', perms: ['isMember'] })
  );


export default new ClientModule({
  menus: [
    { positions: ['bottom.mobile'], items: MemberMenus }
  ]
});
