import React, { Fragment } from 'react';

/**
 *
 * @param items
 * @param func
 * @returns {JSX.Element}
 */
export const Map = ({ items = [], children}) => (<>
  {(!!items.length) && items.map((item, index)=>(<Fragment key={index}>
    {(typeof children === 'function') && <>{children({ ...item, index })}</>}
  </Fragment>))}
</>);

export default Map;
