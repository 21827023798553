import loadable from 'next/dynamic';
import DomainsCompose from "../helpers/DomainsCompose";
import withProps from "recompose/withProps";
import { map } from '../helpers/config';
import withFormFilter from '../helpers/Filter';
import withListProcess from '../helpers/MediaTools';
import withRenderProcess from '@xem/entity/classes/withRenderProcess';

const ListContainer = loadable(
  ()=>import('./List').then(
    (c) => c.default)
);

export default DomainsCompose(
  {},
  [
    withProps(({ query }) => ({
      pageProps: { path: map({ path: 'list' }), other: map({ path: 'listpage', page: ':page' }), query },
    })),
    withProps(({ entities, setFieldValue })=>({
      refetch: () =>{
        try{ entities.refetch() }catch (e) {}
        try{ setFieldValue('page', 1) }catch (e) {}
      }
    })),
    withFormFilter,
  ],
  [
    withListProcess,
    withRenderProcess({  entityType: 'domain' })
  ]
)(ListContainer);
