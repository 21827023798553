import {graphql} from "react-apollo";
import DOMAIN_DELETE from "../graphql/domainDelete.graphql";

/**
 *
 * @param Component
 */
export const withDomainDeleting = (Component: any) =>
  graphql(DOMAIN_DELETE, {
    props: ({ mutate }) => ({
      domainDelete: async (id: number) => mutate({ variables: { id } })
    })
  })(Component);

export default withDomainDeleting;