import { apiUrl } from "@gqlapp/core-common/net";
import createApolloClient from './createApolloClient';
let apolloClient
import requestIp from 'request-ip';

export function initializeApollo({ initialState, clientModules, serverModules }: any, ctx: any = {}) {

	if(typeof ctx.ctx !== 'undefined'){
		ctx = ctx.ctx;
	}
	delete ctx.apolloClient;
	let headers = ctx?.req?.headers || ctx?.headers || {};
	let remoteip = null;
	if(headers["x-real-ip"]){
		remoteip = headers["x-real-ip"];
	}else if(ctx?.req){
		remoteip = requestIp.getClientIp(ctx.req);
	}

	const _apolloClient = apolloClient ?? createApolloClient({
		headers, remoteip, apiUrl, initialState,
		createLink: clientModules?.createLink,
		createNetLink: clientModules?.createNetLink,
		clientResolvers: clientModules?.resolvers,
		connectionParams: null
	})

	// For SSG and SSR always create a new Apollo Client
	if (typeof window === 'undefined') return _apolloClient
	// Create the Apollo Client once in the client
	if (!apolloClient) apolloClient = _apolloClient
	return _apolloClient
}

export default initializeApollo;
