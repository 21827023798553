import React from 'react';
import ClientModule from '@gqlapp/module-client-react';


// @ts-ignore
const ref: { modules: ClientModule } = { modules: null };

// @ts-ignore
import Switch from "@xem/router/classes/Switch";

const MainRoute = () =>{
  return <Switch>{ref.modules.routes}</Switch>
}
export default new ClientModule({
  // @ts-ignore
  router: MainRoute,
  // @ts-ignore
  onAppCreate: [async (modules: ClientModule) => (ref.modules = modules)],
});
