import { graphql } from 'react-apollo';
import GENS_DELETE from "../graphql/GensDelete.graphql";
import loading from "@gqlapp/base/utils/loading";
import map from "@gqlapp/base/utils/map";

/**
 *
 */
export const withGensDeleting = graphql(GENS_DELETE, {
  props: ({ mutate, ownProps: { loadbar } }: any) => ({
    gensDelete: async (variables: any) =>
      loading(loadbar, async () =>
        map(
          map(await mutate({ variables }), ({ data }: any) => data),
          ({ res }: any) => res
        ))
  })
});

export default withGensDeleting;
