import { getCookies, getCookie, deleteCookie, setCookie as setcookie } from "cookies-next";

export const MAX_AGE = 60 * 60 * 8 // 8 hours

export const setCookie = async (key, value, options = {}) =>{
  options = mergeObject(options, {
    maxAge: MAX_AGE,
    expires: new Date(Date.now() + MAX_AGE * 1000),
    // httpOnly: true,
    secure: process.env.NODE_ENV === 'production',
    path: '/'
    // sameSite: 'lax',
  });

  if(key && value) {
    key = `${key}`.trim();
    value = `${value}`.trim();
    await setcookie(key, value, options);
  }
}


export { getCookies, deleteCookie, getCookie }
