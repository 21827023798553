export default {
  enabled: true,
  langPickerRender: false,
  langList: ['en-EN'],
  cookie: 'lang',
  init:{
    lng: 'en-EN',
    // nsSeparator: false,
    fallbackLng: 'en-EN',
    defaultNS: 'common'
  }
};
