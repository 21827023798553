import React from 'react';
import Button from "reactstrap/lib/Button";
import Row from '@gqlapp/base/Row';
import Col from '@gqlapp/base/Col';

const FooterContainer = (() => (
  <>
    <section className={'youtube-flash align-items-center p-0'} style={{ borderTop: '2px solid #212121'}}>
      <>
        <Button
          block
          data-app
          data-fixprotocol
          {...{
            color:'default',
            className: 'open-youtube py-0 px-2',
            style:{ maxWidth: '100%', width: '414px', padding: 0, margin: '0 auto' }
          }}
        >
          <Row small>
            <Col xs={'auto'} className={'d-flex align-items-center'}>
              <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 0H20C22.6522 0 25.1957 1.05357 27.0711 2.92893C28.9464 4.8043 30 7.34784 30 10V20C30 22.6522 28.9464 25.1957 27.0711 27.0711C25.1957 28.9464 22.6522 30 20 30H10C7.34784 30 4.8043 28.9464 2.92893 27.0711C1.05357 25.1957 0 22.6522 0 20L0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0Z" fill="#CC0000"/>
                <path d="M12.509 11.391L18.884 15.014L12.509 18.637V11.391ZM30 3.214V26.786C29.9997 27.6383 29.661 28.4557 29.0583 29.0583C28.4557 29.661 27.6383 29.9997 26.786 30H3.214C2.36168 29.9997 1.54434 29.661 0.941652 29.0583C0.338967 28.4557 0.000265068 27.6383 0 26.786L0 3.214C0.000265068 2.36168 0.338967 1.54434 0.941652 0.941652C1.54434 0.338967 2.36168 0.000265068 3.214 0H26.786C27.6383 0.000265068 28.4557 0.338967 29.0583 0.941652C29.661 1.54434 29.9997 2.36168 30 3.214ZM27.188 15.02C27.2004 13.0399 27.03 11.0628 26.679 9.114C26.5419 8.5948 26.2704 8.12088 25.8919 7.73994C25.5135 7.35899 25.0413 7.08447 24.523 6.944C22.627 6.429 15 6.429 15 6.429C15 6.429 7.373 6.429 5.478 6.945C4.9597 7.08547 4.48755 7.35999 4.10906 7.74094C3.73057 8.12188 3.45911 8.5958 3.322 9.115C2.97074 11.0638 2.80035 13.0409 2.813 15.021C2.80058 17.0011 2.97097 18.9782 3.322 20.927C3.46023 21.442 3.73264 21.911 4.11142 22.2863C4.4902 22.6615 4.96177 22.9296 5.478 23.063C7.378 23.572 15 23.572 15 23.572C15 23.572 22.627 23.572 24.522 23.056C25.0382 22.9226 25.5098 22.6545 25.8886 22.2793C26.2674 21.904 26.5398 21.435 26.678 20.92C27.0292 18.9732 27.2006 16.9982 27.188 15.02Z" fill="white"/>
              </svg>
            </Col>
            <Col className={'d-flex align-items-center'} style={{ textAlign: 'left', color: '#fff', lineHeight: '18px'}}>
              <p className={'pb-0 mb-0'}>{'Youtube'.t()}
                <br />
                <small style={{ fontSize: '12px', lineHeight: '12px', color: '#ABABAB'}}>{'youtube.open'.t()}</small>
              </p>
            </Col>

            <Col xs={'auto'} className={'d-flex align-items-center'}>
              <div  style={{ '--btn-primary': '#0071E3'}} className={'btn btn-sm btn-primary rounded-4 px-3'}>{'Open'.t()}</div>
            </Col>
          </Row>
        </Button>
      </>
    </section>
  </>
));

export default FooterContainer;

