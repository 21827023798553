import React from "react";
import moment from "moment-timezone";
import { getItem, setItem } from "@gqlapp/core-common/clientStorage";

export default function RootComponent({ children, session, ...props}) {
  let {key, value} = session || {}
  let guess = moment.tz.guess();

  setCookie('tz', guess);

  /** preload cookie*/
  React.useEffect(() => {
    if (key && value && !hasCookie(key)) {
      let allCookies = getCookies();
      let keys = Object.keys(allCookies) || [];
      keys.filter((k) =>
        (new RegExp(/(SESS|SSESS)(.*)/s)).test(k))
        .map(function (value) {
          deleteCookie(value);
        });

      setCookie(key, value);
    }
  }, [key, value]);

  try {
    if (window) {
      getItem('data').then(data=>{
        if (!data && props?.localKey){
          setItem('data', props?.localKey)
        }
      })
    }
  } catch (e) {}
  return children;
}
