import { graphql } from 'react-apollo';
import loading from '@gqlapp/base/utils/loading';
import LINKS_DELETE from '../graphql/linksDelete.graphql';

/**
 * withLinksDeleting
 * @param Component
 */
export const withLinksDeleting = (Component: any) =>
  graphql(LINKS_DELETE, {
    props: ({ mutate, ownProps: { loadbar } }: any) => ({
      linksDelete: async (variables: any) => loading(loadbar, async () => mutate({ variables }))
    })
  })(Component);

export default withLinksDeleting;
