import { compose, withProps } from 'recompose';
import withLoading from '@xem/utils/withLoading';

import {getFilterByKey} from "./Filter";
import Entities from '@xem/taxonomy-common/classes/Taxonomies';
import withTaxonomies from "@xem/taxonomy-common/classes/withTaxonomies"
import withTaxonomyDelete from "@xem/taxonomy-common/classes/withTaxonomyDelete"
import TAXONOMIES_QUERY from '@xem/taxonomy-common/graphql/TaxonomiesQuery.graphql';

import onDelete from "./utils/onDelete";
import MediaTools from "./MediaTools";


/**
 *
 * @param input
 * @param withPropsInput
 * @param withPropsBefore
 */
export const ListCompose = (input?: any, withPropsInput?: any[], withPropsBefore?: any[]) => {
	return (Route: any) => {

		const getEntities = function getPath({ query, cookie }: any) {
			let page = query?.page || 0, id = parseInt(query?.id);

			let entities = Entities.instanse({ pageSize: 16, orderBy: 'CREATED', protected: true});

			let orderBy, direction;
			let filter: any = {};

			(cookie) && ({orderBy, direction } = getFilterByKey(cookie));

			(!!orderBy) && (entities.orderBy = orderBy);
			(!!direction) && (entities.direction = direction);

			entities.filter = filter;
			entities.page = parseInt(`${page}`);

			return entities;
		}


		/**
		 * WithRouteLink
		 */
		const WithRouteLink = compose(
			MediaTools,
			withProps(input),
			withTaxonomyDelete,
			withProps(({ taxonomyDelete, query, cookie, ...props}) => ({
				onDelete: onDelete(taxonomyDelete, props),
				entities: getEntities({ query, cookie: cookie?.filter?.key})
			})),
			...(withPropsBefore || []),
			withTaxonomies(({ entities }) => ({ taxonomies: entities, entitiesReset: true })),
			...(withPropsInput || []),
			withLoading
		)(Route);


		// @ts-ignore
		WithRouteLink.getInitialProps = async ({req, res, path, query, apolloClient}) => {
			let ctx = {}, variables = {};
			let cookie = getCookie('taxonomy_list_sort', { req, res });

			if(cookie){
				Object.assign(ctx, { cookie:{ filter: { key: cookie } } })
			}

			let entities = getEntities({ path, query, cookie })
			variables = entities.variables;

			/** Preload taxonomy **/
			let result = await apolloClient.query({ query: TAXONOMIES_QUERY, fetchPolicy: 'no-cache', variables });
			/** set cache taxonomy **/
			await apolloClient.writeQuery({ query: TAXONOMIES_QUERY, data: result?.data, variables });

			return ({...ctx})
		}

		return WithRouteLink;
	};
};

export default ListCompose;
