import React from 'react';
import renderCallback from "@xem/entity/classes/renderCallbackR";
import renderFormPopup from "@xem/entity/classes/renderFormPopup";
import FormFilter from './Filter';

const FilterData = ({ isOpen, append, ...props })=>{
  return renderFormPopup(FormFilter, append, props)
}

const DataForm = ((props) => (
  <>{!__SERVER__ && (!!props?.isOpen) && <><FilterData {...props} /></>}</>));

let ref = React.createRef();
export default renderCallback(ref, 'gens.filter', DataForm)
