import compose from 'recompose/compose';
import withProps from 'recompose/withProps';
import Domains from '@xem/domain-common/classes/Domains';
import Entity from '@xem/link-common/classes/Link';
import withLink from '@xem/link-common/classes/withLink';
import withLoading from '@xem/utils/withLoading';
import Taxonomies from '@xem/taxonomy-common/classes/Taxonomies';
import { entityProps } from '../helpers/utils';
import { map } from '../helpers/config';
import LINK_QUERY from '@xem/link-common/graphql/linkQuery.graphql'
import onSave from "@xem/entity/classes/onSave";

/**
 *
 * @param input
 * @param withPropsInput
 * @param withPropsBefore
 */
export const withRouteLink = (input?: any, withPropsInput?: any[], withPropsBefore?: any[]) => (Route: any) => {

  const getPath = function getPath(path: string, id: number ){
    ((typeof path === 'undefined') && (typeof id !== 'undefined')) && (path = map({ path: 'edit', id }));
    ((typeof path === 'undefined') || (path === null)) && (path = '/');
    return path;
  }
  /**
   * WithRouteLink
   */
  const WithRouteLink = compose(
    withProps(input),
    withProps(({ path, query }: any) => {
        let id = parseInt(query?.id);
        return ({
          id, entity_id: id,
          domains: Domains.instanse(),
          entity: Entity.instanse(),
          categories: Taxonomies.instanse(),
          path: getPath(path, id),
        })
    }),
    entityProps,
    ...(withPropsBefore || []),
    withLink(({ entity }: any) => ({ link: entity })),
    withProps(({ entity, linkCreate, linkUpdate })=>({
      onSave	: onSave(linkCreate, linkUpdate, { entity }),
      refetch: func(entity?.refetch)
    })),
    ...(withPropsInput || []),
    withLoading
  )(Route);


  // @ts-ignore
  WithRouteLink.getInitialProps = async ({ path, query, apolloClient }) =>{
    let ctx = {}, variables = {};

    path = getPath(path, query?.id);
    (!!path) && (Object.assign(variables, { path }));

    /** Preload taxonomy **/
    let data = await apolloClient.query({ query: LINK_QUERY, fetchPolicy: 'cache-first', variables });

    /** set cache taxonomy **/
    await apolloClient.writeQuery({ query: LINK_QUERY, data, variables });

    return ({ ...ctx })
  }

  return WithRouteLink;
};

export default withRouteLink;
