import * as React from 'react';
import clsx from 'clsx';
import withStyles from '@mui/styles/withStyles';

const styles = {
  /* Styles applied to the root element. */
  root: {},
};

const Page = React.forwardRef(function Page({ children, id, tag, classes, className, ...props }, ref) {
  tag = tag || 'div'
  return React.createElement(tag, {
    ...props ,
    id,
    ref,
    className: clsx(classes.root, 'page', className),
  }, children);
});

export default withStyles(styles, { name: 'MuiPage' })(Page);
