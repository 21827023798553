import React from 'react';
import * as PropTypes from 'prop-types';
import { withFormik } from 'formik';
import { FieldAdapter as Field } from "@gqlapp/forms-client-react";
import compose from "recompose/compose";
import RenderField from '@gqlapp/base/RenderField';
import Form from 'reactstrap/lib/Form';
import Button from 'reactstrap/lib/Button';
import Row from '@gqlapp/base/Row';
import Col from '@gqlapp/base/Col';
import * as Yup from "yup";

const CreateForm = ({ className, isSubmitting:submitting, handleSubmit, values: { link }}) =>{
	return (<>
		<Form onSubmit={handleSubmit}>
			<Row>
				<Col xs={12}>
					<Field
						classes={{ root: className }}
						placeholder={'enter_link'.t()}
						name={'link'}
						autoComplete={'off'}
						component={RenderField}
						value={link}
						// mask={['(https?:\/\/)?([\w\-]+\.{1}([a-zA-Z]{2,63})([\/\w-]*)*\/?\??([^\n\r]*)?([^\n\r]*)']}
						suffix={
							<>
								<Button
									block
									{...{
										type: 'submit',
										color : 'primary',
										style: { width: '200px', height: '50px'},
										className: 'shadow d-none d-md-block text-hv rounded-2',
										disabled:(submitting)
									}}
								>{'link:shortlink'.t()}
								</Button>
							</>
						}
					/>
				</Col>
				<Col xs={12} className={'d-md-none pt-3'}>
					<Button
						block
						outline
						{...{
							type: 'submit',
							color : 'white',
							style: { height: '50px'},
							className: 'border-3 bg-primary shadow text-hs font-weight-bolder rounded-1',
							disabled:(submitting),
						}}
					>{'link:shortlink'.t()}
					</Button>
				</Col>
			</Row>
		</Form>
	</>)
}

CreateForm.propTypes = {
	handleSubmit: PropTypes.func
};

/**
 * Validation form
 * @type {ComponentDecorator<any, *>}
 */
const CreateFormWithFormik = compose(
	withFormik({
		validationSchema: ({ min = 10 }) =>
			Yup.object().shape({
				link: Yup
					.string()
					.required('link:url.required'.t())

					// Kiểm tra độ dài của link
					.test('link', 'link:url.minimum_url_length'.t(), function (link){
						link = link || '';
						return !(link.length < min);
					})

					// Kiểm tra domain có hợp lệ không ?
					.test('link', 'link:url.invalid'.t(), function (link){
						let domain = (link || '').getDomain();
						return !!domain.isDomain();
					})
			}),
		mapPropsToValues: ({}) => ({ link: '' }),
		handleSubmit: ({ link }, { resetForm, props: { onSubmit, refetch }}) =>
			onSubmit({ link })
				.then(({ entity:{}}) => {
					resetForm();
					refetch && refetch()
				})
				.catch((e)=>{
					resetForm();
					msg({ type: 'danger', message: e.toString() });
				}),
		enableReinitialize: true,
		displayName: 'CreateForm' // helps with React DevTools
	})
)(CreateForm);


export default CreateFormWithFormik;
