import loadable from 'next/dynamic';
import GensCompose from "../helpers/gens/GensCompose";
import withProps from "recompose/withProps";
import { map } from '../helpers/config';
import withOnFilter from "../helpers/gens/withOnFilter";
import FilterWrapper from "../helpers/gens/FilterWrapper";
import withRenderProcess from '@xem/entity/classes/withRenderProcess';

const ListContainer = loadable(
  ()=>import('../components/Gens/View').then(
    (c) => c.default)
);

export default GensCompose(
	{},
	[
		withProps(({ query }) => ({
			pageProps: { path: map({ path: 'list' }), other: map({ path: 'listpage', page: ':page' }), query },
		})),
		withProps(({ entities })=> ({ refetch: func(entities.refetch) })),
		FilterWrapper
	],[
		withOnFilter,
		withRenderProcess(),
	]
)(ListContainer);
