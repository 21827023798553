import compose from "recompose/compose";
import withProps from "recompose/withProps";
import withLoading from '@xem/utils/withLoading';
import LINKS_QUERY from '@xem/link-common/graphql/linksQuery.graphql'
import Entities from '@xem/link-common/classes/Links';
import withLinks from '@xem/link-common/classes/withLinks';
import withLinksDeleting from '@xem/link-common/classes/withLinksDeleting';
import withLinksUpdate from '@xem/link-common/classes/withLinksUpdate';
import onDeletes from "../utils/onDeletes";
import onUpdates from "../utils/onUpdates";
import { getFilterByKey } from "./Utils";


/**
 *
 * @param input
 * @param withPropsInput
 * @param withPropsBefore
 */
export const LinksCompose = (input?: any, withPropsInput?: any[], withPropsBefore?: any[]) => {
	return (Route: any) => {

		const getEntities = function getPath({ query, cookie, status = 'active'}: any) {
			let page = query?.page || 0, id = parseInt(query?.id);

			let entities = Entities.instanse({ pageSize: 16, orderBy: 'CREATED', protected: true});

			let orderBy: string, direction: string;
			let filter: any = {};

			(cookie) && ({ orderBy, direction, status} = getFilterByKey(cookie));

			(!!id) && (Object.assign(filter, { tids: [id] }));
			(!!status) && (Object.assign(filter, { status }));
			(!!orderBy) && (entities.orderBy = orderBy);
			(!!direction) && (entities.direction = direction);

			entities.filter = filter;
			entities.page = parseInt(`${page}`);
			// entities.notOwnDomain = true;
			// entities.ownDomain = true;

			return entities;
		}


		/**
		 * WithRouteLink
		 */
		const WithRouteLink = compose(
			withProps(input),
			withLinksDeleting,
			withLinksUpdate,
			withProps(({linksDelete, linksUpdate, query, cookie, ...props}) => ({
				onDeletes: onDeletes(linksDelete, props),
				onUpdates: onUpdates(linksUpdate, props),
				entities: getEntities({ query, cookie: cookie?.filter?.key})
			})),
			...(withPropsBefore || []),
			withLinks(({ entities }) => ({ links: entities, entitiesReset: true })),
			...(withPropsInput || []),
			withLoading
		)(Route);


		// @ts-ignore
		WithRouteLink.getInitialProps = async ({ req, res, path, query, apolloClient }) => {
			let ctx = {}, variables = {};
			let cookie = getCookie('link_list_sort', { req, res });

			if(cookie){
				Object.assign(ctx, { cookie:{ filter: { key: cookie } } })
			}

			let entities = getEntities({ path, query, cookie })
			variables = entities.variables;

			/** Preload taxonomy **/
			let result = await apolloClient.query({ query: LINKS_QUERY, variables, fetchPolicy: 'no-cache' });

			/** set cache taxonomy **/
			await apolloClient.writeQuery({ query: LINKS_QUERY, variables, data: result?.data });
			return ({...ctx})
		}

		return WithRouteLink;
	};
};

export default LinksCompose;
