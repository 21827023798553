import { graphql } from 'react-apollo';
import TAXONOMIES_QUERY from '../graphql/TaxonomiesQuery.graphql';
import withProps from 'recompose/withProps';
import compose from 'recompose/compose';
import mapInput from '@gqlapp/base/utils/mapInput';

/**
 * withTaxonomies
 * @param input
 */
const withTaxonomies = (input: any) =>
  compose(
    withProps((props: any) => mapInput(input, props)),
    graphql(TAXONOMIES_QUERY, {
      options: ({ taxonomies, fetchPolicy, notifyOnNetworkStatusChange }: any) => {
        let { protected: p, page, pageSize, orderBy, direction, filter } = taxonomies?.variables;
        return ({
          fetchPolicy: fetchPolicy || null,
          notifyOnNetworkStatusChange: !!notifyOnNetworkStatusChange,
          variables: { protected: p, page, pageSize, orderBy, direction, filter }
        })
      },
      props({
              data: { loading, res, refetch, error, updateQuery, fetchMore, subscribeToMore },
              ownProps: { taxonomies, entitiesReset }
            }: any) {

        taxonomies.refetch = refetch;
        taxonomies.fetchMore = fetchMore;
        taxonomies.subscribeToMore = subscribeToMore;
        taxonomies.updateQuery = updateQuery;
        if (!loading && res) {
          taxonomies.update(res, !!entitiesReset);
        }

        return { loading, errors: error ? error.graphQLErrors : null }
      }
    })
  );

export default withTaxonomies;
