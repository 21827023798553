import compose from "recompose/compose";
import withOnFilter from "./withOnFilter";
import withWebriskCreate from "./withWebriskCreate";
import withTools from "@xem/look/Renders/Tools/withTools";
import withRenderProcess from '@xem/entity/classes/withRenderProcess';

export default compose(
	withTools,
	withOnFilter,
	withWebriskCreate,
	withRenderProcess()
)
