import React from 'react';
import menu from './helpers/menu';
import resources from './locales';
import ClientModule from "@gqlapp/module-client-react";
import { WebriskRoute, map } from "./helpers/config";
import Route from "@xem/router/classes/Route";
import List from './containers/ListContainer';

// @ts-ignore
let ListInitialProps = List.getInitialProps;


export default new ClientModule(menu, {
  route: {
    WebriskList : <Route {...WebriskRoute} component={List} getInitialProps={ListInitialProps} path={map({ path: 'list' })} {...{ webType: 'cpanel' }} />
  },
  localization: [{ ns: 'webrisk', resources, more: ['common'] }]
});
