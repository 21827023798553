import React from 'react';
import loadable from 'next/dynamic';

const ResetPasswordContainer = loadable(
  ()=>import('./ResetPassword').then(
    (c) => c.default)
);

export default ResetPasswordContainer;

