export const config = {
	barSize: 18,
	crossSize: 18,
	inputSize: 40,
	delay: 150,
	defaultColor: 'rgba(175, 51, 242, 1)',
	defaultGradient:
		'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%)',
}

export const defaultLocales = {
	CONTROLS: {
		SOLID: 'Solid',
		GRADIENT: 'Gradient',
	},
}

export const fakePresets = [
	'rgba(0,0,0,1)',
	'rgba(128,128,128, 1)',
	'rgba(192,192,192, 1)',
	'rgba(255,255,255, 1)',
	'rgba(0,0,128,1)',
	'rgba(0,0,255,1)',
	'rgba(0,255,255, 1)',
	'rgba(0,128,0,1)',
	'rgba(128,128,0, 1)',
	'rgba(0,128,128,1)',
	'rgba(0,255,0, 1)',
	'rgba(128,0,0, 1)',
	'rgba(128,0,128, 1)',
	'rgba(175, 51, 242, 1)',
	'rgba(255,0,255, 1)',
	'rgba(255,0,0, 1)',
	'rgba(240, 103, 46, 1)',
	'rgba(255,255,0, 1)',
]
