import extractHostname from '../extractHostname';
export const extractRootDomain = (url) => {
  try {
    // tslint:disable-next-line:one-variable-per-declaration
    // @ts-ignore
    let domain = extractHostname(url),
      // tslint:disable-next-line:prefer-const
      splitArr = `${domain}`.split('.'),
      // tslint:disable-next-line:prefer-const
      arrLen = splitArr.length;

    // extracting the root domain here
    // if there is a subdomain
    if (arrLen > 2) {
      domain = splitArr[arrLen - 2] + '.' + splitArr[arrLen - 1];
      // check to see if it's using a Country Code Top Level Domain (ccTLD) (i.e. ".me.uk")
      if (splitArr[arrLen - 2].length === 2 && splitArr[arrLen - 1].length === 2) {
        // this is using a ccTLD
        domain = splitArr[arrLen - 3] + '.' + domain;
      }
    }
    return domain;
  } catch (e) {
    return url;
  }
};

export default extractHostname;