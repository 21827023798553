import Action from '@xem/entity/classes/Action';
import settings from '@xem/config';


const taxonomy = {
  list: '/campaigns.html',
  listpage: '/campaign/:page/page.html',
  taxonomyView: '/campaign/:id/links.html'
}

export const map = ({ id, page, path }: any) => `${taxonomy[path]}`.replace(/:id/, id).replace(/:page/, page);

const perms = ['isMember'];

const styleConfigs = { style: { '--button-bar-height': '50px' } };


export const defaultRoute = {
  ...settings.route,
  action: Action.instanse(),
  runtimejs: true
};


export const TaxonomyRoute = {
  ...settings.route,
  ...styleConfigs,
  perms,
  exact: true,
  webType: 'taxonomy',
  mobile: { show: true },
  classNameOnPage: 'cpanel theme-mobile',
  action: Action.instanse()
}

export default TaxonomyRoute;
