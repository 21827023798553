import React from "react";

/**
 *
 * @param children
 * @param props
 * @returns {Element}
 * @constructor
 */
function FormWrapper({ children, ...props}){
  const BaseForm  = (({ onActions }) =>{
    React.useEffect(()=>{ onActions['gen.filter'] && onActions['gen.filter'](props); }, [])
    return <>
      {typeof children === 'function' && children(props)}
      {typeof children !== 'function' && children}
    </>
  })

  return <BaseForm {...props} />
}

export default function (Component){
  return function (props){
    return <FormWrapper {...props}>{Component}</FormWrapper>
  }
}