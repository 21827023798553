import { graphql } from 'react-apollo';
import mapInput from '@gqlapp/base/utils/mapInput';
import compose from "recompose/compose";
import withProps from "recompose/withProps";

import USERS_QUERY from '@xem/user-common/graphql/UsersQuery.graphql'

const withUsers = (input: any) => (Component: any) =>
  compose(
    withProps((props: any) => mapInput(input, props)),
    withProps(({ users:{ variables } }: { users: any} )=>({ ...variables })),
    graphql(USERS_QUERY, {
      options: ({ page, pageSize, direction, filter, fetchPolicy }: any) => ({
          fetchPolicy: fetchPolicy || null,
        variables: { page, pageSize, direction, filter, fetchPolicy }
      }),
      props({ data: { loading, res, refetch, error, updateQuery, fetchMore, subscribeToMore },
              ownProps: { users, entitiesReset }
            }: any) {

        users.refetch = refetch;
        users.fetchMore = fetchMore;
        users.subscribeToMore = subscribeToMore;
        users.updateQuery = updateQuery;

        if (!loading && res) {
          users.update(res || {}, !!entitiesReset);
        }
        return {
          loading,
          users,
          errors: error ? error.graphQLErrors : null
        };
      }
    })
  )(Component);

export default withUsers;
