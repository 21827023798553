import withProps from 'recompose/withProps';
import compose from 'recompose/compose';
import withLoading from '@xem/utils/withLoading';
import { entityProps } from "@xem/link-client-react/helpers/utils";

import LINKS_QUERY from '@xem/link-common/graphql/linksQuery.graphql'
import Entities from '@xem/link-common/classes/Links';
import withLinksDeleting from '@xem/link-common/classes/withLinksDeleting';
import withLinksUpdate from '@xem/link-common/classes/withLinksUpdate';
import onDeletes from "@xem/link-client-react/helpers/utils/onDeletes";
import onUpdates from "@xem/link-client-react/helpers/utils/onUpdates";
import setCache from '@xem/entity/classes/setCache';

import Stats from '@xem/stats-common/classes/Stats/Stats';
import STATS_QUERY from "@xem/stats-common/graphql/Stats.graphql";
import STATS_CHANNEL_QUERY from "@xem/stats-common/graphql/StatsChannel.graphql";
import STATS_LOCATION_QUERY  from "@xem/stats-common/graphql/StatsLocation.graphql";


/**
 *
 * @param input
 * @param withPropsInput
 * @param withPropsBefore
 */
export const HomeCompose = (input?: any, withPropsInput?: any[], withPropsBefore?: any[]) => {

	/**
	let defaultDateType = 'ALL';
	/*/
	let defaultDateType = 'WEEK';
	/***/



	return (Route: any) => {

		/**
		 * getEntities
		 * @param notOwnDomain
		 * @param ownDomain
		 * @param dateType
		 * @param status
		 */
		const getEntities = function getPath({ notOwnDomain, ownDomain, dateType, status = 'active' }: any) {
			let filter: any = {};
			dateType = dateType || defaultDateType;
			let entities = Entities.instanse({ pageSize: 5, orderBy: 'VIEWS' });

			(!!status) && (Object.assign(filter, { status }));
			(!!dateType) && (Object.assign(filter, { dateType }));
			(!!notOwnDomain) && (entities.notOwnDomain = true);
			(!!ownDomain) && (entities.ownDomain = true);

			entities.filter = filter;

			return entities;
		}


		/**
		 * WithRouteLink
		 */
		const WithRoute = compose(
			entityProps,
			withLinksDeleting,
			withLinksUpdate,
			withProps(input),
			withProps(({ action, dateType, linksDelete, linksUpdate, ...props  }) => {

				dateType = dateType || defaultDateType;

				return ({
					dateType,
					stats: Stats.instanse(),
					linkRs: getEntities({ dateType, notOwnDomain: true }),
					linksByDomain: getEntities({ dateType, ownDomain: true }),

					onDeletes: onDeletes(linksDelete, props),
					onUpdates: onUpdates(linksUpdate, props),
				});
			}),
			...(withPropsBefore || []),
			...(withPropsInput || []),
			withLoading
		)(Route);


		const bindCache = async (apolloClient: any, obj: any)=> {
			let entities = getEntities(obj)
			let variables = entities.variables;

			/** set link cache*/
			await setCache({ query: LINKS_QUERY, variables, apolloClient })
		}


		// @ts-ignore
		WithRoute.getInitialProps = async ({ apolloClient }) => {
			let ctx = {}, dateType;

			dateType = dateType || defaultDateType;

			await bindCache(apolloClient, { dateType, notOwnDomain: true })
			await bindCache(apolloClient, { dateType, ownDomain: true })

			// /** set link cache*/
			await setCache({ query: STATS_QUERY, variables: { id: null, dateType }, apolloClient })
			await setCache({ query: STATS_CHANNEL_QUERY, variables: { id: null, dateType }, apolloClient })
			if(!__SERVER__){
				await setCache({ query: STATS_LOCATION_QUERY, variables: { id: null, dateType }, apolloClient })
			}

			return ({...ctx})
		}

		return WithRoute;
	};
};

export default HomeCompose;
