import saveStatus from '@gqlapp/base/utils/saveStatus';

/**
 * Delete multiple elements
 * @param Delete
 * @param loadbar
 */
export const onDelete  = (Delete: any, { loadbar }: any = {}) => async (input: any) => {
  let values = await Delete(input);
  // @ts-ignore
  return saveStatus({ values, errorMsg: 'taxonomy:delete.errorMsg'.t()});
};

export default onDelete;
