import React from 'react';
import ClientModule from "@gqlapp/module-client-react";
import { onAppCreate } from "./components/PageLayout";
export { onAppCreate } from './components/PageLayout';
import resources from './locales';
import block from "./block";
import menu from "./helpers/menu";
import popup from './helpers/popup';

import RootComponent from "./containers/RootComponent";

export default new ClientModule(popup, menu, block,{
  rootComponentFactory: [ req => <RootComponent req={req} /> ],
  onAppCreate: [onAppCreate],
  localization: [{ ns: 'common', resources }]
});
