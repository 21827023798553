import withProps from "recompose/withProps";

export default withProps({
  sx: {
    '&':{
      zIndex: 1000,
      position: 'absolute',
      top: '0px',
      left: 0,
      paddingTop: '0',
      backgroundColor: '#ffffff',

      // position: 'absolute', top: 0, left: 0, minWidth: '375px', maxWidth: '100%',
      // backgroundColor: '#fff',
      maxWidth: '100%',
      minWidth: '375px',
      minHeight: '40px',
      boxShadow: '0px 1px 1px rgba(176, 176, 176, 0.21)',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '10px'
    }
  }
})