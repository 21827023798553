
/**
 * Merge 2 object
 * @param obj1
 * @param obj2
 */
export default function mergeRecursive(obj1, obj2) {
	const _OBJECT1 = { ...obj1 };
	const _OBJECT2 = { ...obj2 };
	// tslint:disable-next-line:forin
	for (const p in _OBJECT2) {
		try {
			// Property in destination object set; update its value.
			// tslint:disable-next-line:prefer-conditional-expression
			if (_OBJECT2[p].constructor === Object && _OBJECT1[p].constructor === Object) {
				_OBJECT1[p] = mergeRecursive(_OBJECT1[p], _OBJECT2[p]);
			} else {
				_OBJECT1[p] = _OBJECT2[p];
			}
		} catch (e) {
			// Property in destination object not set; create it and set its value.
			_OBJECT1[p] = _OBJECT2[p];
		}
	}
	return _OBJECT1;
}