
export default class Product {
  public id: number;
  public title: string;

  public body: string;
  public summary: string;
  public attributes: any;
  public attrObj: any[] = [];

  /**
   * instanse
   */
  public static instanse() {
    return (new this()).save({});
  }
  
  /**
   * save
   */
  public save({ id, title, body, summary, attributes, attrObj }: any) {
    this.id         = id;
    this.title      = title;
    this.body       = body;
    this.summary    = summary;
    this.attributes = attributes;
    this.attrObj    = attrObj || [];
    return this;
  }
}
