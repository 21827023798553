import React from 'react';
import loadable from 'next/dynamic';
import HomeCompose from "../helpers/HomeCompose";

const DashboardContainer = loadable(
	()=>import('./Dashboard').then(
		(c) => c.default)
);

export default HomeCompose()(DashboardContainer);

